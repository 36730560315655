import { FC } from 'react'

import { Activity } from 'models/Activity'
import { Currencies } from 'models/Currency'
import { PerCurrencyTable, Table as TableType } from 'models/Table'
import { convertFromBigNumber } from 'utils/form'

import { Collapsible, Table } from 'components'

interface ActivitiesProps {
  activities: Activity
  nftCollectionName: string
}

export const Activities: FC<ActivitiesProps> = ({ activities, nftCollectionName }) => {
  if (!activities) return null

  const boldTableBodyCols = [0]

  const table: TableType<PerCurrencyTable> = {
    headers: [
      {
        key: 'name',
        header: '',
      },
      {
        key: Currencies.WETH,
        header: 'WETH',
      },
      {
        key: Currencies.USDT,
        header: 'USDT',
      },
      {
        key: Currencies.USDC,
        header: 'USDC',
      },
    ],
    data: [
      {
        name: 'Average borrowed',
        [Currencies.WETH]: Number(
          (
            convertFromBigNumber(activities.WETH.avgBorrowed, Currencies.WETH, false) as number
          ).toFixed(4)
        ),
        [Currencies.USDC]: Number(
          (
            convertFromBigNumber(activities.USDC.avgBorrowed, Currencies.USDC, false) as number
          ).toFixed(4)
        ),
        [Currencies.USDT]: Number(
          (
            convertFromBigNumber(activities.USDT.avgBorrowed, Currencies.USDT, false) as number
          ).toFixed(4)
        ),
      },
      {
        name: 'Average APR (%)',
        [Currencies.WETH]: Number(activities.WETH.avgApr.toFixed(2)),
        [Currencies.USDC]: Number(activities.USDC.avgApr.toFixed(2)),
        [Currencies.USDT]: Number(activities.USDT.avgApr.toFixed(2)),
      },
      {
        name: 'Average duration (days)',
        [Currencies.WETH]: Number(activities.WETH.avgDuration.toFixed(0)),
        [Currencies.USDC]: Number(activities.USDC.avgDuration.toFixed(0)),
        [Currencies.USDT]: Number(activities.USDT.avgDuration.toFixed(0)),
      },
    ],
  }

  return (
    <div>
      <Collapsible title={`NFT Activity ${nftCollectionName}`} className="rounded-t-none">
        <div className="font-body-4 text-third-50">
          Recent 60 days Average of the NFT collection
        </div>
        <Table columns={table.headers} data={table.data} bodyBoldColsIndexes={boldTableBodyCols} />
      </Collapsible>
    </div>
  )
}
