import { useEffect, useState } from 'react'

import { BigNumber } from 'ethers'
import { useNavigate, useParams } from 'react-router-dom'
import { Address } from 'wagmi'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { getStorageItem, setStorageItem } from 'hooks/useStorage'
import { StoreModalType } from 'models/Comm'
import { Currencies } from 'models/Currency'
import { pushModal } from 'store/slices/comm/comm.actions'
import { getLoanDetails, setPayBackStatus } from 'store/slices/loans/loans.actions'
import { loansStateSelector } from 'store/slices/loans/loans.slice'
import { setLoadingIdle, setLoadingPending } from 'store/slices/profile/profile.actions'
import { erc20Approve, getRepaymentAmount, repayLoan } from 'utils/api/blockchain'
import { ADDRESSES } from 'utils/constants'
import { convertFromBigNumber, convertToBigNumber } from 'utils/form'

import { ActivityIndicator } from 'components'

import { LoanDetailView } from './LoanDetailView'

export const LoanDetailScreen = () => {
  const { id } = useParams()
  const isMakeOfferInfoRead = getStorageItem('isMakeOfferLoanInfoRead')
  const isRequestTermsUpdateInfoRead = getStorageItem('isRequestTermsUpdateInfoRead')
  const [isPayBackModalOpen, setIsPayBackModalOpen] = useState(false)
  const [isPayBackConfirmationModalOpen, setIsPayBackConfirmationModalOpen] = useState(false)
  const [isRequestTermsUpdateInfoModalOpen, setIsRequestTermsUpdateInfoModalOpen] = useState(
    !isRequestTermsUpdateInfoRead
  )
  const [isMakeOfferInfoModalOpen, setIsMakeOfferInfoModalOpen] = useState(!isMakeOfferInfoRead)

  const { loanDetails } = useAppSelector(loansStateSelector)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) return
    dispatch(getLoanDetails({ id }))
  }, [id])

  const handlePayBack = async (): Promise<void> => {
    dispatch(setLoadingPending())
    if (!loanDetails || !ADDRESSES.ZEXUS_BORROWER) return
    const { apr, collateralId, currency, duration, principal } = loanDetails

    const principalNumber = convertFromBigNumber(principal, currency) as string
    const principalBN: BigNumber = convertToBigNumber(principalNumber, currency, false) as BigNumber

    const repaymentAmountBN = await getRepaymentAmount(apr, duration, principalBN)

    const approveResult = await erc20Approve(
      repaymentAmountBN,
      ADDRESSES[currency as Exclude<Currencies, Currencies.ALL>] as Address,
      ADDRESSES.ZEXUS_BORROWER
    )
    const approveTransactionReceipt = await approveResult.wait()
    if (approveTransactionReceipt.status !== 1) {
      dispatch(setLoadingIdle())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
      return
    }

    const repayLoanResult = await repayLoan(collateralId)
    const repayLoanTransactionReceipt = await repayLoanResult.wait()
    if (repayLoanTransactionReceipt.status !== 1) {
      dispatch(setLoadingIdle())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
      return
    }

    dispatch(setPayBackStatus({ id: Number(id) }))
    setIsPayBackModalOpen(false)
    dispatch(setLoadingIdle())
    setIsPayBackConfirmationModalOpen(true)
  }

  const handleGoToProfile = () => {
    navigate('/profile/loans')
  }

  const handlePayBackConfirmationModalOpenChange = (open: boolean) => {
    dispatch(setLoadingIdle())
    if (open) {
      setIsPayBackModalOpen(false)
    }
    setIsPayBackConfirmationModalOpen(open)
  }

  const storeInfoModalRead = (modalType: 'makeOfferLoan' | 'termsUpdate') => {
    if (modalType === 'makeOfferLoan') {
      setStorageItem('isMakeOfferLoanInfoRead', 'true')
      return
    }
    setStorageItem('isRequestTermsUpdateInfoRead', 'true')
  }

  const handleOfferButtonClick = () => {
    navigate('offer')
  }

  const handleMakeOfferInfoModalOpenChange = (open: boolean) => {
    setIsMakeOfferInfoModalOpen(open)
    if (!open) {
      storeInfoModalRead('makeOfferLoan')
    }
  }

  const handleMakeOfferInfoModalButtonClick = () => {
    storeInfoModalRead('makeOfferLoan')
    setIsMakeOfferInfoModalOpen(false)
  }

  const handleRequestTermsUpdateInfoModalOpenChange = (open: boolean) => {
    setIsRequestTermsUpdateInfoModalOpen(open)
    if (!open) {
      storeInfoModalRead('termsUpdate')
    }
  }

  const handleRequestTermsUpdateInfoModalButtonClick = () => {
    storeInfoModalRead('termsUpdate')
    setIsRequestTermsUpdateInfoModalOpen(false)
  }

  if (!loanDetails) return <ActivityIndicator fullScreen />

  return (
    <LoanDetailView
      isMakeOfferInfoModalOpen={isMakeOfferInfoModalOpen}
      isPayBackConfirmationModalOpen={isPayBackConfirmationModalOpen}
      isPayBackModalOpen={isPayBackModalOpen}
      isRequestTermsUpdateInfoModalOpen={isRequestTermsUpdateInfoModalOpen}
      loanDetails={loanDetails}
      onGoToProfile={handleGoToProfile}
      onMakeOfferInfoModalButtonClick={handleMakeOfferInfoModalButtonClick}
      onMakeOfferInfoModalOpenChange={handleMakeOfferInfoModalOpenChange}
      onOfferButtonClick={handleOfferButtonClick}
      onPayBack={() => handlePayBack().catch(() => dispatch(setLoadingIdle()))}
      onPayBackConfirmationModalChange={handlePayBackConfirmationModalOpenChange}
      onRequestTermsUpdateInfoModalButtonClick={handleRequestTermsUpdateInfoModalButtonClick}
      onRequestTermsUpdateInfoModalOpenChange={handleRequestTermsUpdateInfoModalOpenChange}
      setIsPayBackModalOpen={setIsPayBackModalOpen}
    />
  )
}
