import {
  BenefitsSection,
  CollateralSection,
  HeroSection,
  HowSection,
  LoanSection,
} from './components'

export const HomeView = () => (
  <>
    <HeroSection />
    <CollateralSection />
    <LoanSection />
    <BenefitsSection />
    <HowSection />
  </>
)
