import React from 'react'

import './index.css'

import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'

import { GOOGLE_MEASUREMENT_ID, IS_PROD } from './utils/constants'
import App from './App'

Sentry.init({
  dsn: 'https://bb3a496e1876d87e995f3fda0f22fdd7@o4506222669856768.ingest.sentry.io/4506228995457024',
  environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracePropagationTargets: ['localhost', /^https?:\/\/(?:(?:dev|beta)\.)*zexus\.finance/],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: IS_PROD ? 0.1 : 1.0,
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ['Please, switch to', 'User rejected request'],
})

if (GOOGLE_MEASUREMENT_ID) {
  ReactGA.initialize(GOOGLE_MEASUREMENT_ID)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
