import { useEffect } from 'react'

import { useAppDispatch } from 'hooks/store.hooks'
import { getHomeLists } from 'store/slices/home/home.actions'

import { HomeView } from './HomeView'

export const HomeScreen = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getHomeLists())
  }, [dispatch])

  return <HomeView />
}
