import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Collateral, CollateralDetails, NFT } from 'models/Collateral'
import { Loan, LoanDetails } from 'models/Loan'
import { UserOffer } from 'models/Offer'
import { RequestStatus, RootState } from 'store/helpers'
import { logout } from 'store/slices/auth/auth.actions'

import {
  getOffers,
  getOwnedNfts,
  getProfileCollaterals,
  getProfileLoans,
  getSingleOffer,
  resetOffers,
  resetSingleOffer,
  setLoadingDeleteIdle,
  setLoadingDeletePending,
  setLoadingIdle,
  setLoadingPending,
  setSelectedOffer,
} from './profile.actions'

export type ProfileState = {
  loans: Loan[]
  collaterals: Collateral[]
  offers: UserOffer[]
  ownedNfts: NFT[]
  selectedOffer?: UserOffer
  singleOffer?: LoanDetails | CollateralDetails
  getOwnedNftsStatus: RequestStatus
  getProfileLoansStatus: RequestStatus
  getProfileCollateralsStatus: RequestStatus
  getProfileOffersStatus: RequestStatus
  getSingleOfferStatus: RequestStatus
  loadingState: RequestStatus
  loadingDeleteState: RequestStatus
}

const initialState: ProfileState = {
  loans: [],
  collaterals: [],
  offers: [],
  ownedNfts: [],
  getOwnedNftsStatus: RequestStatus.IDLE,
  getProfileLoansStatus: RequestStatus.IDLE,
  getProfileCollateralsStatus: RequestStatus.IDLE,
  getProfileOffersStatus: RequestStatus.IDLE,
  getSingleOfferStatus: RequestStatus.IDLE,
  loadingState: RequestStatus.IDLE,
  loadingDeleteState: RequestStatus.IDLE,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout.pending, state => {
        state.loans = initialState.loans
        state.collaterals = initialState.collaterals
        state.offers = initialState.offers
        state.ownedNfts = initialState.ownedNfts
        state.getOwnedNftsStatus = RequestStatus.IDLE
        state.getProfileLoansStatus = RequestStatus.IDLE
        state.getProfileCollateralsStatus = RequestStatus.IDLE
        state.getProfileOffersStatus = RequestStatus.IDLE
        state.getSingleOfferStatus = RequestStatus.IDLE
      })
      .addCase(getProfileLoans.pending, state => {
        state.getProfileLoansStatus = RequestStatus.PENDING
      })
      .addCase(getProfileLoans.fulfilled, (state, action) => {
        state.loans = action.payload
        state.getProfileLoansStatus = RequestStatus.FULFILLED
      })
      .addCase(getProfileLoans.rejected, state => {
        state.getProfileLoansStatus = RequestStatus.REJECTED
      })
      .addCase(getProfileCollaterals.pending, state => {
        state.getProfileCollateralsStatus = RequestStatus.PENDING
      })
      .addCase(getProfileCollaterals.fulfilled, (state, action) => {
        state.collaterals = action.payload
        state.getProfileCollateralsStatus = RequestStatus.FULFILLED
      })
      .addCase(getProfileCollaterals.rejected, state => {
        state.getProfileCollateralsStatus = RequestStatus.REJECTED
      })
      .addCase(getOwnedNfts.rejected, state => {
        state.getOwnedNftsStatus = RequestStatus.REJECTED
      })
      .addCase(getOwnedNfts.pending, state => {
        state.getOwnedNftsStatus = RequestStatus.PENDING
      })
      .addCase(getOwnedNfts.fulfilled, (state, action) => {
        state.ownedNfts = action.payload
        state.getOwnedNftsStatus = RequestStatus.FULFILLED
      })
      .addCase(getOffers.rejected, state => {
        state.getProfileOffersStatus = RequestStatus.REJECTED
      })
      .addCase(getOffers.pending, state => {
        state.getProfileOffersStatus = RequestStatus.PENDING
      })
      .addCase(getOffers.fulfilled, (state, action) => {
        state.offers = action.payload
        state.getProfileOffersStatus = RequestStatus.FULFILLED
      })
      .addCase(getSingleOffer.rejected, state => {
        state.getSingleOfferStatus = RequestStatus.REJECTED
      })
      .addCase(getSingleOffer.pending, state => {
        state.getSingleOfferStatus = RequestStatus.PENDING
      })
      .addCase(getSingleOffer.fulfilled, (state, action) => {
        state.singleOffer = action.payload
        state.getSingleOfferStatus = RequestStatus.FULFILLED
      })
      .addCase(setSelectedOffer.type, (state, action: PayloadAction<UserOffer>) => {
        state.selectedOffer = action.payload
      })
      .addCase(resetSingleOffer.type, state => {
        state.singleOffer = undefined
        state.selectedOffer = undefined
      })
      .addCase(resetOffers.type, state => {
        state.offers = []
      })
      .addCase(setLoadingPending.type, state => {
        state.loadingState = RequestStatus.PENDING
      })
      .addCase(setLoadingIdle.type, state => {
        state.loadingState = RequestStatus.IDLE
      })
      .addCase(setLoadingDeletePending.type, state => {
        state.loadingState = RequestStatus.PENDING
      })
      .addCase(setLoadingDeleteIdle.type, state => {
        state.loadingState = RequestStatus.IDLE
      })
  },
})

export const profileSelector = (state: RootState) => state.profile

export default profileSlice.reducer
