import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useWallet } from 'hooks/useWallet'
import { StoreModalType } from 'models/Comm'
import { Currencies } from 'models/Currency'
import { NFT } from 'models/NFT'
import { SortItem } from 'models/Sort'
import { Terms } from 'models/Terms'
import {
  createCollateral,
  resetCollateralId,
  resetCreateCollateralStatus,
  setCollateralStatusReady,
} from 'store/slices/collaterals/collaterals.actions'
import { collateralStateSelector } from 'store/slices/collaterals/collaterals.slice'
import { pushModal } from 'store/slices/comm/comm.actions'
import {
  getOwnedNfts,
  setLoadingIdle,
  setLoadingPending,
} from 'store/slices/profile/profile.actions'
import { profileSelector } from 'store/slices/profile/profile.slice'
import {
  approveNfts,
  watchCollateralAddedEvent,
  writeContractAddNFTCollateral,
} from 'utils/api/blockchain'
import { calculateRepayment } from 'utils/form'
import { getNFTImage } from 'utils/nft'

import { DEFAULT_SORT_METHOD } from './components/NftSelector'
import { AddCollateralView } from './AddCollateralView'

const DEFAULT_TERMS: Terms = {
  allowDealNow: false,
  apr: undefined,
  currency: Currencies.WETH,
  duration: undefined,
  principal: undefined,
  repayment: undefined,
}

export const AddCollateralScreen = () => {
  const [collateralName, setCollateralName] = useState('Collateral')
  const [stage, setStage] = useState(1)
  const [selectedNfts, setSelectedNfts] = useState<NFT[]>([])
  const [terms, setTerms] = useState<Terms>(DEFAULT_TERMS)
  const [sortMethod, setSortMethod] = useState<SortItem>(DEFAULT_SORT_METHOD)
  const [isAddCollateralConfirmationModalOpen, setIsAddCollateralConfirmationModalOpen] =
    useState(false)

  const { address } = useWallet()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { collateralId } = useAppSelector(collateralStateSelector)
  const { ownedNfts } = useAppSelector(profileSelector)

  let unwatchEvent: () => void

  useEffect(() => {
    dispatch(getOwnedNfts({ sort: sortMethod }))
  }, [sortMethod])

  const handleItemDelete = (item: NFT) => {
    setSelectedNfts(
      selectedNfts.filter(el => !(el.address === item.address && el.tokenId === item.tokenId))
    )
  }

  const handleSortMethodChange = (method: SortItem) => {
    setSortMethod(method)
  }

  const isButtonDisabled = () => {
    const { apr, duration, principal } = terms
    if (stage === 1) return !selectedNfts.length
    if (stage === 2) return !(apr && duration && principal && selectedNfts.length)
    return false
  }

  const addNftCollateral = async (): Promise<void> => {
    await writeContractAddNFTCollateral(selectedNfts, terms).catch(() => {
      unwatchEvent?.()
      dispatch(setLoadingIdle())
      dispatch(resetCollateralId())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
    })
  }

  const handleSubmit = async () => {
    dispatch(setLoadingPending())
    const approveNftsResponse = await approveNfts(selectedNfts).catch(() => {
      dispatch(setLoadingIdle())
      dispatch(resetCollateralId())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
    })

    if (
      approveNftsResponse?.status !== 'success' ||
      !terms.apr ||
      !terms.duration ||
      !terms.principal ||
      !terms.repayment
    )
      return

    dispatch(
      createCollateral({
        allowDealNow: terms.allowDealNow,
        apr: terms.apr,
        currency: terms.currency,
        duration: terms.duration,
        name: collateralName,
        principal: terms.principal,
        repayment: terms.repayment,
        nfts: selectedNfts.map(nft => ({
          ...nft,
          imageUrl: getNFTImage(nft),
        })),
      })
    )
  }

  useEffect(() => {
    if (!collateralId) return

    unwatchEvent = watchCollateralAddedEvent((creator, collateralIdBC) => {
      if (creator === address) {
        dispatch(
          setCollateralStatusReady({ id: collateralId, collateralIdBC: collateralIdBC as string })
        )
        setIsAddCollateralConfirmationModalOpen(true)
        dispatch(setLoadingIdle())
        dispatch(resetCollateralId())
        unwatchEvent()
      }
    })

    addNftCollateral()
  }, [collateralId])

  const handleCancel = () => {
    navigate('/profile/collaterals')
  }

  useEffect(() => {
    if (!terms.apr || !terms.duration || !terms.principal) {
      setTerms({ ...terms, repayment: undefined })
      return
    }
    const repaymentValue = calculateRepayment(
      terms.apr,
      terms.duration,
      terms.principal,
      terms.currency
    )
    setTerms({ ...terms, repayment: repaymentValue })
  }, [terms.apr, terms.duration, terms.principal, terms.currency])

  const handleTermsChange = (newTerms: Partial<Terms>) => {
    setTerms((prevState: Terms) => ({ ...prevState, ...newTerms }))
  }

  const handleGoToMarketplace = () => {
    dispatch(resetCreateCollateralStatus())
    navigate(`/collaterals`)
  }

  const handleGoToProfile = () => {
    dispatch(resetCreateCollateralStatus())
    navigate('/profile/collaterals')
  }

  const handleAddCollateralModalOpenChange = (open: boolean) => {
    setIsAddCollateralConfirmationModalOpen(open)
    if (!open) {
      handleGoToProfile()
    }
  }

  return (
    <AddCollateralView
      isAddCollateralConfirmationModalOpen={isAddCollateralConfirmationModalOpen}
      isButtonDisabled={isButtonDisabled()}
      nfts={ownedNfts}
      onAddCollateralModalOpenChange={handleAddCollateralModalOpenChange}
      onCancel={handleCancel}
      onGoToMarketplace={handleGoToMarketplace}
      onGoToProfile={handleGoToProfile}
      onItemDelete={handleItemDelete}
      onNameChange={(name: string) => setCollateralName(name)}
      onSortMethodChange={handleSortMethodChange}
      onSubmit={handleSubmit}
      selectedNfts={selectedNfts}
      setSelectedNfts={setSelectedNfts}
      setStage={setStage}
      onTermsChange={handleTermsChange}
      stage={stage}
      terms={terms}
    />
  )
}
