import { FC, ReactNode, useEffect } from 'react'

import dayjs from 'dayjs'

import { useAppSelector } from 'hooks/store.hooks'
import { NFT } from 'models/Collateral'
import { Currencies } from 'models/Currency'
import { Table as TableModel } from 'models/Table'
import { RequestStatus } from 'store/helpers'
import { profileSelector } from 'store/slices/profile/profile.slice'
import { convertFromBigNumber } from 'utils/form'
import { roundUnknownTypeDigits } from 'utils/number'

import { ActivityIndicator, Button, Modal, Table } from 'components'
import { TileImages } from 'components/tile/components/TileImages'

type PayBackTable = {
  dueDate: string
  principal: ReactNode
  apr: number
  duration: string
  repayment: ReactNode
}

type PayBackModalProps = {
  apr: number
  currency: string
  dueDate: string
  duration: number
  id: number
  isOpen: boolean
  nfts: NFT[]
  onPayBack: () => void
  onOpenChange: (open: boolean) => void
  principal: string
  repayment: string
}

export const PayBackModal: FC<PayBackModalProps> = ({
  apr,
  currency,
  dueDate,
  duration,
  id,
  isOpen,
  nfts,
  onPayBack,
  onOpenChange,
  principal,
  repayment,
}) => {
  const parsedPrincipal = convertFromBigNumber(principal, currency as Currencies) as string
  const parsedRepayment = convertFromBigNumber(repayment, currency as Currencies) as string

  const tableData: TableModel<PayBackTable> = {
    headers: [
      {
        key: 'dueDate',
        header: 'Due date',
        className: 'pl-4',
      },
      {
        key: 'principal',
        header: 'Principal',
      },
      {
        key: 'apr',
        header: 'APR (%)',
      },
      {
        key: 'duration',
        header: 'Duration',
      },
      {
        key: 'repayment',
        header: 'Repayment',
        className: 'bg-secondary-90 text-center text-primary-40',
      },
    ],
    data: [
      {
        dueDate: dayjs(dueDate).format('DD MMM'),
        principal: (
          <div
            className="flex gap-1 pr-1"
            title={`${convertFromBigNumber(parsedPrincipal, currency as Currencies)} ${currency}`}
          >
            <div className="truncate">
              {roundUnknownTypeDigits(
                convertFromBigNumber(parsedPrincipal, currency as Currencies),
                4
              )}
            </div>
            <div>{currency}</div>
          </div>
        ),
        apr,
        duration: `${duration} ${duration < 2 ? 'day' : 'days'}`,
        repayment: (
          <div
            className="flex gap-1 pr-1"
            title={`${convertFromBigNumber(parsedRepayment, currency as Currencies)} ${currency}`}
          >
            <div className="truncate">
              {roundUnknownTypeDigits(
                convertFromBigNumber(parsedRepayment, currency as Currencies),
                4
              )}
            </div>
            <div>{currency}</div>
          </div>
        ),
      },
    ],
  }

  const { loadingState } = useAppSelector(profileSelector)

  useEffect(() => {}, [loadingState])

  const renderBottomBar = () => (
    <div className="mt-8 flex items-center justify-end gap-6">
      <Button onClick={() => onOpenChange(false)} variant="secondary">
        Cancel
      </Button>
      <Button onClick={onPayBack} variant="primary">
        {loadingState === RequestStatus.PENDING ? (
          <ActivityIndicator className="w-[70px]" whiteColor />
        ) : (
          'Pay back'
        )}
      </Button>
    </div>
  )

  return (
    <Modal
      title="Pay back"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    >
      <div className="font-header-h6 mb-6">Are you sure you want to pay back Loan #{id}?</div>
      <div className="flex gap-6">
        <div>
          <div className="w-[200px]">
            <TileImages nfts={nfts} />
          </div>
        </div>
        <div className="min-w-[502px]">
          <Table
            columns={tableData.headers}
            data={tableData.data}
            headerBoldColsIndexes={[0, 1, 2, 3, 4]}
          />

          <div className="font-body-4 mt-6">
            If you proceed with “pay back”, we will activate the transfer action on MetaMask.
          </div>
        </div>
      </div>
    </Modal>
  )
}
