import { FC } from 'react'

import { getCurrencyIcon } from 'utils/getCurrencyIcon'

import { Currencies } from '../../../models/Currency'
import { convertFromBigNumber } from '../../../utils/form'

interface TileFooterProps {
  principal?: string
  currency?: string
  apr?: number
  projectFloor?: number
  valuation?: number
}

export const TileFooter: FC<TileFooterProps> = props => {
  const { apr, currency, principal, projectFloor, valuation } = props
  const parsedPrincipal = convertFromBigNumber(principal, currency as Currencies) as string
  return (
    <div className="flex justify-between px-0.5">
      {principal && currency && (
        <div className="flex min-w-0 flex-col gap-1.5">
          <div className="font-overline text-third-50">Principal</div>
          <div className="font-body-1 flex items-center gap-1.5 truncate font-semibold text-third-20">
            {getCurrencyIcon(currency)}
            <div className="truncate" title={parsedPrincipal}>
              {parsedPrincipal}
            </div>
          </div>
        </div>
      )}
      {apr && (
        <div className="ml-auto flex flex-col items-end gap-1.5">
          <div className="font-overline text-third-50">APR</div>
          <div className="font-body-1 font-semibold text-third-20">{apr}%</div>
        </div>
      )}
      {projectFloor && currency && (
        <div className="flex min-w-0 flex-col gap-1.5">
          <div className="font-overline text-third-50">Project floor</div>
          <div className="font-body-1 flex items-center gap-1.5 truncate font-semibold text-third-20">
            {getCurrencyIcon(currency)}
            <div className="truncate" title={projectFloor.toString(10)}>
              {projectFloor}
            </div>
          </div>
        </div>
      )}
      {valuation && currency && (
        <div className="ml-auto flex flex-col items-end gap-1.5">
          <div className="font-overline text-third-50">Valuation</div>
          <div className="font-body-1 flex items-center gap-1.5 font-semibold text-third-20">
            {getCurrencyIcon(currency)}
            <div className="truncate" title={valuation.toString(10)}>
              {valuation}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
