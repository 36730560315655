import { FC, ReactElement, useEffect, useState } from 'react'

import { Outlet } from 'react-router-dom'

import { useAppDispatch } from 'hooks/store.hooks'
import { useStorage } from 'hooks/useStorage'
import { showSideNav } from 'store/slices/nav/nav.actions'

import { ActivityIndicator, Button } from 'components'

type ProtectedElementProps = {
  children?: ReactElement | null
  onConnectClick?: () => void
  showLoader?: boolean
}

export const ProtectedElement: FC<ProtectedElementProps> = props => {
  const { children, onConnectClick, showLoader = true } = props

  const [token, setToken] = useState<string | null>()

  const dispatch = useAppDispatch()

  const { retrieveValue } = useStorage('token', setToken)

  useEffect(() => {
    const initialToken = retrieveValue()
    setToken(initialToken)
    if (!initialToken) {
      dispatch(showSideNav())
    }
  }, [dispatch])

  const handleClick = () => {
    dispatch(showSideNav())
    onConnectClick?.()
  }

  if (!token) {
    if (!showLoader) return null
    return (
      <div className="mx-auto my-8 flex flex-col items-center gap-8">
        <ActivityIndicator className="h-12 w-12" />
        <Button variant="primary" onClick={handleClick}>
          Connect wallet
        </Button>
      </div>
    )
  }

  return children || <Outlet />
}
