import { useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { StoreAlert } from 'models/Comm'
import { shiftAlert } from 'store/slices/comm/comm.actions'
import { commStateSelector } from 'store/slices/comm/comm.slice'

import { Alert } from './Alert'

export const AlertContainer = () => {
  const [storeAlert, setStoreAlert] = useState<StoreAlert | null>(null)

  const { alertStack } = useAppSelector(commStateSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setStoreAlert(alertStack.length > 0 ? alertStack[0] : null)
  }, [alertStack])

  const handleOpenChange = (open: boolean) => {
    if (!open) dispatch(shiftAlert())
  }

  return (
    <Alert isOpen={!!storeAlert} message={storeAlert?.message} onOpenChange={handleOpenChange} />
  )
}
