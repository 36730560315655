import * as yup from 'yup'

import { AccordionElement } from 'models/Accordion'
import { NFT } from 'models/NFT'
import { ColumnDefinition } from 'models/Table'

export const accordionElements: AccordionElement[] = [
  {
    value: 'nft',
    header: 'What is NFT?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet commodo nulla facilisi nullam. Velit egestas dui id ornare arcu odio ut sem nulla. Blandit libero volutpat sed cras ornare arcu. ',
  },
  {
    value: 'pricing',
    header: 'What is the price?',
    content:
      'Augue eget arcu dictum varius. Malesuada bibendum arcu vitae elementum curabitur vitae nunc sed velit. Lectus sit amet est placerat in egestas erat. Faucibus a pellentesque sit amet porttitor eget dolor morbi. Pellentesque habitant morbi tristique senectus et netus.',
  },
  {
    value: 'test',
    header: 'What is that?',
    content:
      'Aliquet bibendum enim facilisis gravida neque convallis a. Ipsum dolor sit amet consectetur adipiscing. Vulputate enim nulla aliquet porttitor lacus luctus. Ullamcorper malesuada proin libero nunc consequat interdum varius. Ipsum a arcu cursus vitae congue mauris rhoncus aenean vel.',
  },
]

type TestData = {
  name: string
  color: string
  age: number
  gender: string
  activityLevel: string
  favoriteFood: string
}

export const columns: ColumnDefinition<TestData>[] = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'age',
    header: 'Age in years',
  },
  {
    key: 'color',
    header: 'Color',
  },
]

export const data: TestData[] = [
  {
    name: 'Mittens',
    color: 'black',
    age: 2,
    gender: 'female',
    activityLevel: 'hight',
    favoriteFood: 'milk',
  },
  {
    name: 'Mons',
    color: 'grey',
    age: 2,
    gender: 'male',
    favoriteFood: 'old socks',
    activityLevel: 'medium',
  },
  {
    name: 'Luna',
    color: 'black',
    age: 2,
    gender: 'female',
    activityLevel: 'medium',
    favoriteFood: 'fish',
  },
  {
    name: 'Bella',
    color: 'grey',
    age: 1,
    gender: 'female',
    activityLevel: 'high',
    favoriteFood: 'mice',
  },
  {
    name: 'Oliver',
    color: 'orange',
    age: 1,
    gender: 'male',
    activityLevel: 'low',
    favoriteFood: 'fish',
  },
]

export const nfts: NFT[] = [
  {
    tokenId: '123',
    collectionName: 'Collection 123',
    isUsed: false,
    isWhitelisted: false,
    name: 'Name 123',
    imageUrl:
      'https://i.seadn.io/gae/za0EcO_3Eo45QXclsEXyg8txfzmBprL5Py929pMZTUulApUL1r0HazO49r3xD2iyuU8XJp5Zm0bBVPxXsaOU3dKC28eUU2RKHRG3?auto=format&w=1000',
    address: '0xeB7C917821796eb627C0719A23a139ce51226CD1',
    currency: 'WETH',
    projectFloor: 1.67,
    valuation: 1.02,
  },
  {
    tokenId: '456',
    name: 'Name 456',
    collectionName: 'Collection 123',
    isUsed: false,
    isWhitelisted: true,
    imageUrl:
      'https://i.seadn.io/gae/KTzP1ECinX7kHPGLp5k-D1I9dH9-0FpjnNp4dRAg7WwdTU8C_vCNmsxBUuEsCD_U3gc9NxL0R56Y2Pz_DItLhx1chgFjJKqnDaZC2g?auto=format&w=1000',
    address: '0xeB7C917821796eb627C0719A23a139ce51226CD2',
    currency: 'WETH',
    projectFloor: 1.67,
    valuation: 1.02,
  },
  {
    tokenId: '789',
    collectionName: 'Collection 123',
    isUsed: false,
    isWhitelisted: true,
    name: 'Name 789',
    imageUrl:
      'https://i.seadn.io/gae/JQ6wNvr1bR_O4g6d4xmsSZuScU_5BDge_EsEPR4AMSmEXwTKbB-yorGG56DjSAnzrHxIyabh-dPNsYeaN0FfvLNE4RxYllcbBPtemw?auto=format&w=1000',
    address: '0xeB7C917821796eb627C0719A23a139ce51226CD3',
    currency: 'WETH',
    projectFloor: 1.67,
    valuation: 1.02,
  },
]

export const validationSchema = yup.object().shape({
  exampleInput: yup.string().required().min(3).max(8),
  numericInput: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .nullable()
    .min(0)
    .max(100),
})
