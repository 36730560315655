import { FC, PropsWithChildren } from 'react'

type HowItemProps = PropsWithChildren & {
  className?: string
  label?: string
  number: string
  title: string
}

export const HowItem: FC<HowItemProps> = props => {
  const { children, className, label, number, title } = props
  return (
    <div className={`rounded-lg bg-third-80 p-8 ${className}`}>
      <div className="font-headline-large mb-4 flex items-center justify-between text-primary-20">
        {number}
        {label && <div className="font-overline uppercase text-third-10">{label}</div>}
      </div>
      <h5 className="mb-4 text-third-10">{title}</h5>
      <div className="font-body-4 text-third-20">{children}</div>
    </div>
  )
}
