import { HowItem } from './HowItem'

export const HowSection = () => (
  <div>
    <div className="container pt-14 pb-14 lg:pt-24 lg:pb-32">
      <h3 className="mb-9 text-center">How it works?</h3>
      <div className="mx-auto flex flex-col items-center justify-center gap-6 lg:flex-row lg:gap-0">
        <HowItem number="1." title="Connect your wallet" className="mb-10 w-[295px] lg:mb-0">
          Sign in to your MetaMask wallet in order to “borrow” and “lend” ERC-20 tokens with NFT
          collateral.
        </HowItem>
        <div className="flex flex-col items-stretch justify-center">
          <div className="flex flex-col items-stretch justify-center gap-6 lg:flex-row lg:px-10">
            <HowItem
              number="2."
              title="Select collateral and set loan terms"
              label="Borrower"
              className="w-[300px]"
            >
              Select your collateral (single/multiple), then set your desired loan terms. After
              setting the loan terms, remember to add collateral to the marketplace.
            </HowItem>
            <HowItem
              number="3."
              title="Waiting for direct deals or offers"
              label="Borrower"
              className="w-[300px]"
            >
              The lender can directly accept your loan terms or send offers (proposed loan terms) to
              you.
            </HowItem>
          </div>
          <div className="my-6 h-0 w-full border-b-[3px] border-dashed border-b-third-10" />
          <div className="flex flex-col items-stretch justify-center gap-6 lg:flex-row lg:px-10">
            <HowItem
              number="2."
              label="Lender"
              title="Browse the marketplace"
              className="w-[300px]"
            >
              Check out the collateral marketplace and active loans to find “loan terms with
              collateral” that you are willing to lend out ERC-20 tokens.
            </HowItem>
            <HowItem
              number="3."
              label="Lender"
              title="Direct deal or send offers"
              className="w-[300px]"
            >
              You may directly accept the “loan terms” or “send an offer” (propose the loan terms
              you are willing to deal with) to the borrower.
            </HowItem>
          </div>
        </div>
        <HowItem number="4." title="Match!" className="mt-10 w-[290px] lg:mt-0">
          Borrower: You will receive the loan amount. Remember to pay back the agreed amount
          (principal + interest) before the due date to regain ownership of the collateral.
          <br />
          <br />
          Lender: The loan amount will be deducted from your wallet, and the Borrower shall repay on
          time. If not, you will become the sole owner of the collateral.
        </HowItem>
      </div>
    </div>
    <div className="mx-1 h-[2px] bg-third-20" />
  </div>
)
