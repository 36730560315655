import { FC, useEffect } from 'react'

import { useAppSelector } from 'hooks/store.hooks'
import { NFT } from 'models/NFT'
import { Terms } from 'models/Terms'
import { RequestStatus } from 'store/helpers'
import { profileSelector } from 'store/slices/profile/profile.slice'

import { ActivityIndicator, Button } from 'components'
import { DescriptionInfo } from 'components/description-info'
import { TileImages } from 'components/tile/components/TileImages'

interface PreviewProps {
  nfts: NFT[]
  onCancel: () => void
  onNameChange: (name: string) => void
  onSubmit: () => void
  terms: Terms
}

export const Preview: FC<PreviewProps> = ({ nfts, onCancel, onNameChange, onSubmit, terms }) => {
  const { apr, duration, principal, repayment, currency } = terms
  const { loadingState } = useAppSelector(profileSelector)

  useEffect(() => {}, [loadingState])

  return (
    <div className="grid gap-x-4 gap-y-8 lg:grid-cols-detailsContainer">
      <div>
        <TileImages nfts={nfts} size="large" />
      </div>
      <DescriptionInfo
        apr={apr ?? 0}
        duration={duration ?? 0}
        isOfferPreview
        onNameChange={onNameChange}
        principal={principal ?? '0'}
        repayment={repayment ?? '0'}
        currency={currency}
        type="collateral"
      >
        <div className="flex flex-col gap-4">
          <Button onClick={onSubmit} className="w-full">
            {loadingState === RequestStatus.PENDING ? (
              <ActivityIndicator whiteColor />
            ) : (
              'Add collateral to the marketplace'
            )}
          </Button>
          <Button onClick={onCancel} className="w-full" variant="secondary">
            Cancel
          </Button>
        </div>
      </DescriptionInfo>
    </div>
  )
}
