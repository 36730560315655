import { FC, useEffect, useState } from 'react'

import { OfferTypes, ProfileOffersFilter } from 'models/Filter'
import { SortItem } from 'models/Sort'

import { FilterOfferType, Sort } from 'components'

interface ProfileOffersFiltersProps {
  onChange: (value: ProfileOffersFilter) => void
}

const DEFAULT_SORT_METHOD = 'expiry_date'
const DEFAULT_OFFER_TYPE = 'all'
const sortItems: Array<SortItem> = [
  'expiry_date',
  '-expiry_date',
  '-principal',
  'principal',
  '-created_at',
  'created_at',
]

export const ProfileOffersFilters: FC<ProfileOffersFiltersProps> = ({ onChange }) => {
  const [offerType, setOfferType] = useState<OfferTypes>(DEFAULT_OFFER_TYPE)
  const [sortMethod, setSortMethod] = useState<SortItem>(DEFAULT_SORT_METHOD)

  useEffect(() => {
    onChange({ type: offerType, sort: sortMethod })
  }, [offerType, sortMethod])

  return (
    <div className="flex justify-between">
      <div className="w-[144px]">
        <FilterOfferType onChange={setOfferType} defaultValue={DEFAULT_OFFER_TYPE} />
      </div>
      <div className="w-[158px]">
        <Sort items={sortItems} onChange={setSortMethod} defaultValue={DEFAULT_SORT_METHOD} />
      </div>
    </div>
  )
}
