import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { hideSideNav, showSideNav } from 'store/slices/nav/nav.actions'
import { navStateSelector } from 'store/slices/nav/nav.slice'

import { Link } from 'components'

import { SideNav } from './components/navigation-profile/SideNav'
import { NavigationNotification, NavigationProfile } from './components'

import { ReactComponent as Logo } from 'assets/logo.svg'

export const Navigation = () => {
  const { isOpen: sideVisible } = useAppSelector(navStateSelector)
  const dispatch = useAppDispatch()

  const getClassName = ({ isActive }: { isActive: boolean }) => {
    const baseClass = 'font-body-2 my-1 pb-2 shadow-nav !text-third-10'
    return isActive
      ? `${baseClass} mx-4 px-1 shadow-nav2`
      : `${baseClass} mx-5 hover:shadow-navHover`
  }

  const showNav = () => {
    dispatch(showSideNav())
  }
  const hideNav = () => {
    dispatch(hideSideNav())
  }

  return (
    <header className="sticky top-0 z-10 border-b border-b-third-50 bg-third-95">
      <div className="container flex items-center py-2 sm:py-6">
        <Logo className="h-[18px] w-[113px] sm:w-[129px]" />
        <div className="ml-auto flex items-center justify-end sm:hidden">
          <NavigationNotification className="mx-5 mt-2 mr-6" />
          <button onClick={showNav} type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              className="h-8 w-8 stroke-third-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>

        <nav className="fixed left-0 top-0 z-0 ml-10 flex h-0 w-full flex-col gap-2 overflow-y-hidden bg-third-95 transition-all sm:static sm:h-auto sm:flex-row sm:items-center sm:pt-0 sm:pb-0">
          <Link to="/" internal className={getClassName}>
            Home
          </Link>
          <Link to="collaterals" internal className={getClassName}>
            Collaterals
          </Link>
          <Link to="loans" internal className={getClassName}>
            Loans
          </Link>

          <NavigationNotification className="mx-5 mt-2 sm:mr-6 sm:mt-0 sm:ml-auto" />
          <NavigationProfile onClick={showNav} />
        </nav>

        <SideNav isVisible={sideVisible} onHide={hideNav} />
      </div>
    </header>
  )
}
