import { FC, useState } from 'react'

import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'

import { OfferTypes } from 'models/Filter'
import { capitalizeFirst } from 'utils/string'

import { Dropdown } from 'components/form/dropdown'

import { ReactComponent as Check } from 'assets/icons/check.svg'

type FilterOfferTypeProps = {
  onChange: (value: OfferTypes) => void
  defaultValue: OfferTypes
}

export const FilterOfferType: FC<FilterOfferTypeProps> = ({ onChange, defaultValue }) => {
  const [filterValue, setFilterValue] = useState(defaultValue)

  const onSelect = (type: FilterOfferTypeProps['defaultValue']) => {
    onChange(type)
    setFilterValue(type)
  }

  return (
    <Dropdown title={capitalizeFirst(filterValue)} label="Type">
      <div className="flex flex-col">
        <DropdownMenuItem onClick={() => onSelect('all')}>
          <div className="group flex cursor-pointer items-center justify-between">
            <div className="font-body-3 py-2.5 pr-5">All</div>
            <Check className={`${filterValue !== 'all' && 'invisible'} group-hover:visible`} />
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onSelect('collateral')}>
          <div className="group flex cursor-pointer items-center justify-between">
            <div className="font-body-3 py-2.5 pr-5">Collateral</div>
            <Check
              className={`${filterValue !== 'collateral' && 'invisible'} group-hover:visible`}
            />
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onSelect('loan')}>
          <div className="group flex cursor-pointer items-center justify-between">
            <div className="font-body-3 py-2.5 pr-5">Loan</div>
            <Check className={`${filterValue !== 'loan' && 'invisible'} group-hover:visible`} />
          </div>
        </DropdownMenuItem>
      </div>
    </Dropdown>
  )
}
