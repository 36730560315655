import { FC, PropsWithChildren } from 'react'

import { Link, To } from 'react-router-dom'

type NotificationItemWrapperProps = PropsWithChildren & {
  to?: To
  className?: string
  onHover: () => void
}

export const NotificationItemWrapper: FC<NotificationItemWrapperProps> = props => {
  const { to, className, children, onHover } = props
  if (to)
    return (
      <Link to={to} className={className} onMouseOver={onHover} onFocus={onHover}>
        {children}
      </Link>
    )
  return (
    <div className={`cursor-default ${className}`} onMouseOver={onHover} onFocus={onHover}>
      {children}
    </div>
  )
}
