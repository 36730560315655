import { FC, PropsWithChildren } from 'react'

import { NFT } from 'models/Collateral'

import { getNFTImage } from '../../../utils/nft'

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'

interface SummaryProps extends PropsWithChildren {
  nfts?: NFT[]
  onItemDelete: (item: NFT) => void
}

export const Summary: FC<SummaryProps> = ({ nfts, onItemDelete, children }) => (
  <div className="h-min rounded-lg bg-third-80 py-8 px-7 text-third-10">
    <h4 className="mb-[6px]">Summary</h4>
    <div className="font-body-4 font-semibold text-third-40">NFT you chose</div>
    {!!nfts?.length && (
      <div className="mt-4 flex flex-col gap-2">
        {nfts.map(nft => (
          <div className="flex items-center gap-2 border-b border-third-50 py-1" key={nft.tokenId}>
            <img
              src={getNFTImage(nft)}
              alt="desc"
              className="h-[36px] w-[36px] rounded object-cover"
            />
            <div className="font-caption font-semibold leading-4 tracking-[.055em]">
              {nft.name || nft.address}
            </div>
            <button type="button" className="ml-auto h-2 w-2" onClick={() => onItemDelete(nft)}>
              <CloseIcon className="h-full w-full" />
            </button>
          </div>
        ))}
      </div>
    )}
    {children && <div className="mt-6">{children}</div>}
  </div>
)
