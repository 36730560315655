import { Collateral } from 'models/Collateral'
import { Loan } from 'models/Loan'
import { API_URLS } from 'utils/constants'

import API from './api'

export type GetHomeListsData = {
  offers: Collateral[]
  loans: Loan[]
}

export const getHomeLists = async (): Promise<GetHomeListsData> => {
  const { data } = await API.get<GetHomeListsData>(`${API_URLS.ZEXUS_API}/homepage/`)
  return data
}
