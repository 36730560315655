import { useEffect, useMemo } from 'react'

import {
  configureChains as configureChainsCore,
  connect as connectCore,
  createClient as createClientCore,
  disconnect as disconnectCore,
  getAccount,
} from '@wagmi/core'
import { MetaMaskConnector as MetaMaskConnectorCore } from '@wagmi/core/connectors/metaMask'
import { alchemyProvider } from '@wagmi/core/providers/alchemy'
import { useNavigate } from 'react-router-dom'
import {
  Address,
  configureChains,
  createClient,
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { publicProvider } from 'wagmi/providers/public'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useSignMessage } from 'hooks/useSignMessage'
import { StoreModalType } from 'models/Comm'
import { MetaMaskErrorCodes } from 'models/Error'
import { getAuthMessage, getAuthToken, logout, resetAuth } from 'store/slices/auth/auth.actions'
import { authStateSelector } from 'store/slices/auth/auth.slice'
import { pushModal } from 'store/slices/comm/comm.actions'
import { API_KEYS, supportedChains } from 'utils/constants'

import { RequestStatus } from '../store/helpers'
import { useStorage } from './useStorage'

// in case of some issues on production - please remove priorities and change the order of providers
const { chains, provider } = configureChains(
  [...supportedChains],
  [
    publicProvider({ priority: 0 }),
    ...(API_KEYS.ALCHEMY ? [alchemyProvider({ apiKey: API_KEYS.ALCHEMY, priority: 1 })] : []),
  ]
)

export const client = createClient({
  autoConnect: true,
  provider,
  connectors: [
    new MetaMaskConnector({
      chains,
      options: { shimChainChangedDisconnect: true, shimDisconnect: true },
    }),
  ],
})

const { provider: providerCore } = configureChainsCore(
  [...supportedChains],
  [
    publicProvider({ priority: 0 }),
    ...(API_KEYS.ALCHEMY ? [alchemyProvider({ apiKey: API_KEYS.ALCHEMY, priority: 1 })] : []),
  ]
)
// Needs to be created even if it is not used to prevent some Wagmi errors.
// It should be possible to remove when upgraded to the latest version of Wagmi lib
export const clientCore = createClientCore({
  autoConnect: true,
  provider: providerCore,
  connectors: [new MetaMaskConnectorCore({ chains })],
})

export const useWallet = () => {
  const navigate = useNavigate()
  const { message, getAuthTokenStatus, getAuthMessageStatus } = useAppSelector(authStateSelector)
  const dispatch = useAppDispatch()
  const { retrieveValue: retrieveToken } = useStorage('token')
  const { retrieveValue: retrieveTokenAddress } = useStorage('token.address')
  const { retrieveValue: retrieveTokenMessage, storeValue: storeTokenMessage } =
    useStorage('token.message')

  const onConnect = async () => {
    const account = getAccount()
    if (!account.isConnected || !account.connector) {
      const metamaskConnectorCore = new MetaMaskConnectorCore({
        chains: [...supportedChains],
      })
      await connectCore({
        connector: metamaskConnectorCore,
      })
    }
  }

  const onDisconnect = async () => {
    await disconnectCore()
  }

  const { connect, connectors } = useConnect()
  const { disconnect } = useDisconnect()
  const { address, status, connector } = useAccount({ onConnect, onDisconnect })
  const { chain } = useNetwork()
  const {
    error: switchNetworkError,
    switchNetwork,
    pendingChainId,
    status: switchNetworkStatus,
    reset: resetSwitchNetwork,
  } = useSwitchNetwork()

  const {
    error: messageError,
    isVerified: isMessageVerified,
    reset: resetSignMessage,
    signMessage,
    signature: messageSignature,
    status: messageStatus,
  } = useSignMessage(address)

  const hasToken = useMemo(() => !!retrieveToken(), [status, getAuthTokenStatus])

  const connectWallet = () => {
    if (status !== 'disconnected') return
    resetSwitchNetwork()
    const metaMaskConnector = connectors.find(item => item.id === 'metaMask')
    if (!metaMaskConnector?.ready) {
      dispatch(
        pushModal({
          message:
            'Connector is not ready. Please check if you have MetaMask installed and try again in few seconds.',
          title: 'Connector error',
          type: StoreModalType.ERROR_MESSAGE,
        })
      )
      return
    }

    connect({ connector: metaMaskConnector })
  }

  const disconnectWallet = () => {
    disconnect()
    dispatch(logout())
    navigate('/')
  }

  useEffect(() => {
    if (chain?.unsupported && !switchNetworkError && switchNetworkStatus === 'idle') {
      if (switchNetwork) {
        switchNetwork(chains[0].id)
      } else {
        // automatic switching is not supported so disconnect
        disconnectWallet()
      }
    }
  }, [chain, disconnectWallet, switchNetwork, switchNetworkError, switchNetworkStatus])

  useEffect(() => {
    if (!chain?.unsupported && switchNetworkStatus === 'success') {
      resetSwitchNetwork()
    }
  }, [chain, switchNetworkStatus])

  useEffect(() => {
    if (
      switchNetworkError &&
      status !== 'disconnected' &&
      (switchNetworkError.name === 'UserRejectedRequestError' ||
        (!switchNetworkError.message.toLowerCase().includes('pending') &&
          chain?.id !== pendingChainId &&
          pendingChainId !== chains[0]?.id))
    ) {
      resetSwitchNetwork()
      disconnectWallet()
    }
  }, [switchNetworkError, disconnectWallet, chain?.id, chains, pendingChainId, status])

  useEffect(() => {
    if (address) {
      const tokenAddress = retrieveTokenAddress()
      if (tokenAddress !== address) {
        // Account changed (wallet address) so we need to get a new token from BE
        // Get message to be signed after account change
        dispatch(getAuthMessage(address))
      }
    }
  }, [address])

  useEffect(() => {
    const tokenMessage = retrieveTokenMessage()
    if (message && message !== tokenMessage) {
      // Generate new message signature after account change
      storeTokenMessage(message)
      signMessage({ message })
    }
  }, [message])

  useEffect(() => {
    if (
      messageError?.code === MetaMaskErrorCodes.ACTION_REJECTED ||
      getAuthMessageStatus === RequestStatus.REJECTED ||
      getAuthTokenStatus === RequestStatus.REJECTED
    ) {
      resetSignMessage()
      disconnectWallet()
    }
  }, [messageError, getAuthMessageStatus, getAuthTokenStatus])

  useEffect(() => {
    if (address && messageSignature && isMessageVerified) {
      // Request BE for token after account change
      dispatch(
        getAuthToken({
          address,
          signature: messageSignature,
        })
      )
    }
  }, [address, messageSignature, isMessageVerified])

  useEffect(() => {
    // reset auth to be ready for new account change
    if (getAuthTokenStatus === RequestStatus.FULFILLED) {
      // Reset message to prevent repeating dispatches on rerender, etc.
      resetSignMessage()
      dispatch(resetAuth())
    }
  }, [getAuthTokenStatus])

  return {
    address: address as Address,
    chain,
    connectWallet,
    connector,
    disconnectWallet,
    isLoading:
      status === 'connecting' ||
      status === 'reconnecting' ||
      messageStatus === 'loading' ||
      getAuthMessageStatus === RequestStatus.PENDING ||
      getAuthTokenStatus === RequestStatus.PENDING,
    isConnected: status === 'connected' && hasToken,
    status,
  }
}
