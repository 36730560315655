import { FC } from 'react'

import { Button, SuccessModal } from 'components'

type AddCollateralConfirmationModalProps = {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  onPrimaryButtonClick: () => void
  onSecondaryButtonClick: () => void
}

export const AddCollateralConfirmationModal: FC<AddCollateralConfirmationModalProps> = ({
  isOpen,
  onOpenChange,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) => {
  const renderBottomBar = () => (
    <div className="mt-8 flex items-center justify-end gap-6">
      <Button onClick={onSecondaryButtonClick} variant="secondary">
        Back to My Profile
      </Button>
      <Button onClick={onPrimaryButtonClick} variant="primary">
        View marketplace
      </Button>
    </div>
  )

  const successModalMessage = (
    <div className="font-body-3">
      Your collateral offer has been successfully added to the marketplace.
    </div>
  )

  return (
    <SuccessModal
      title="Confirmation"
      isOpen={isOpen}
      message={successModalMessage}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    />
  )
}
