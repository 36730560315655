import { FC } from 'react'

import { Collateral } from 'models/Collateral'

import { Link, Tile } from 'components'

type ProfileCollateralsViewProps = {
  collaterals: Collateral[]
}

export const ProfileCollateralsView: FC<ProfileCollateralsViewProps> = props => {
  const { collaterals } = props
  return (
    <div className="container rounded-b bg-third-90">
      <div className="mx-2 flex flex-wrap justify-center gap-8 lg:justify-start">
        {collaterals?.map(({ name, id, nfts, apr, currency, principal }, index) => {
          const key = `${id}_${index}`
          return (
            <Link to={`/collaterals/${id}`} key={key}>
              <Tile
                apr={apr}
                currency={currency}
                nfts={nfts}
                principal={principal}
                title={name}
                type="collateral"
              />
            </Link>
          )
        })}
      </div>
    </div>
  )
}
