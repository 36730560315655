import { BigNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'

import { Currencies } from 'models/Currency'
import { isBigNumberish } from 'utils/bignumber'

export const convertToBigNumber = (
  value: number | string | undefined | null,
  currency: Currencies | undefined,
  toString: boolean = true
): BigNumber | string | undefined => {
  if (value === undefined || value === null || !currency) return undefined

  let result: BigNumber
  // TODO: Probably can be simplified if there will be no further changes in currency decimals
  switch (currency) {
    case Currencies.ALL:
    case Currencies.WETH:
      result = parseUnits(String(value), 18)
      break
    case Currencies.USDC:
    case Currencies.USDT:
      result = parseUnits(String(value), 6)
      break
    default:
      result = parseUnits(String(value), 18)
  }

  return toString ? result.toString() : result
}

export const convertFromBigNumber = (
  value: BigNumber | string | number | undefined | null,
  currency: Currencies | undefined,
  toString: boolean = true
): string | number | undefined => {
  if (value === undefined || value === null || !currency) return undefined
  if (!isBigNumberish(value)) return toString ? String(value) : Number(value)

  let result: string

  const bnValue = BigNumber.from(String(value))
  // TODO: Probably can be simplified if there will be no further changes in currency decimals
  switch (currency) {
    case Currencies.ALL:
    case Currencies.WETH:
      result = formatUnits(bnValue, 18)
      break
    case Currencies.USDC:
    case Currencies.USDT:
      result = formatUnits(bnValue, 6)
      break
    default:
      result = bnValue.toString()
  }

  return toString ? result : Number(result)
}

export const calculateRepayment = (
  apr: number,
  duration: number,
  principal: number | string,
  currency: Currencies
): string => {
  const principalBN = convertToBigNumber(principal, currency, false) as BigNumber

  const multiplyBN = principalBN
    .mul(BigNumber.from(duration))
    .mul(BigNumber.from(apr))
    .div(BigNumber.from(365))
    .div(BigNumber.from(100))
  const repaymentValueBN = multiplyBN.add(principalBN)

  return convertFromBigNumber(repaymentValueBN, currency) as string
}
