import { FC } from 'react'

import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'

import { Currencies } from 'models/Currency'
import { FormInput } from 'models/Form'
import { Terms } from 'models/Terms'
import { formatOnlyFloat, formatOnlyInteger } from 'utils/format'
import {
  aprValidationSchema,
  durationValidationSchema,
  principalValidationSchema,
} from 'utils/validation'

import { Checkbox, FilterCurrency } from 'components'
import { Dropdown } from 'components/form/dropdown'
import { TextInput } from 'components/form/text-input/TextInput'

import { Hint } from '../../../components/form/hint/Hint'

import { ReactComponent as Check } from 'assets/icons/check.svg'

interface TermsProps {
  isMakeCollateralOffer?: boolean
  isMakeOffer?: boolean
  isTermsUpdate?: boolean
  onAprValidation?: (isValid: boolean) => void
  onDurationValidation?: (isValid: boolean) => void
  onPrincipalValidation?: (isValid: boolean) => void
  onTermsChange: (terms: Partial<Terms>) => void
  originalTerms?: Terms
  terms: Terms
}

const expirationDateOptions = [3, 7, 10, 14]

export const TermsForm: FC<TermsProps> = ({
  isMakeCollateralOffer,
  isMakeOffer,
  isTermsUpdate,
  onAprValidation,
  onDurationValidation,
  onPrincipalValidation,
  onTermsChange,
  originalTerms,
  terms,
}) => {
  if (
    (isMakeOffer && isTermsUpdate) ||
    (isMakeCollateralOffer && isTermsUpdate) ||
    (isMakeOffer && isMakeCollateralOffer)
  ) {
    throw new Error(
      'TermsForm: cannot select more than one from isMakeOffer, isMakeCollateralOffer and isTermsUpdate.'
    )
  }

  if (isTermsUpdate && !originalTerms) {
    throw new Error('Provide original terms.')
  }

  const { apr, currency, duration, principal, repayment } = terms

  const onExpirationDateSelect = (newDate: number) => {
    onTermsChange({ expirationDate: newDate })
  }

  const handleDurationChange = (input: FormInput) => {
    onDurationValidation?.(input.isValid)
    onTermsChange({ duration: input.value ? Number(input.value) : undefined })
  }

  const handleAprChange = (input: FormInput) => {
    onAprValidation?.(input.isValid)
    onTermsChange({ apr: input.value ? Number(input.value) : undefined })
  }

  const handlePrincipalChange = (input: FormInput) => {
    onPrincipalValidation?.(input.isValid)
    onTermsChange({ principal: input.value })
  }

  const handleDealNowChange = (isChecked: boolean) => {
    onTermsChange({ allowDealNow: isChecked })
  }

  return (
    <div className="rounded border border-third-70 bg-box-background px-[48px] py-[27px]">
      {(isMakeOffer || isMakeCollateralOffer) && (
        <h4 className="mb-6">Set loan terms for chosen collateral</h4>
      )}
      {isTermsUpdate && <h4 className="mb-6">Terms update</h4>}
      <div className="flex flex-col gap-8">
        <div className="flex flex-col items-start gap-8 md:flex-row">
          <TextInput
            className="w-full md:w-[238px]"
            value={principal ?? undefined}
            onValueChange={handlePrincipalChange}
            label="Principal"
            hint="Type how much money you want to borrow."
            format={value => formatOnlyFloat(value, terms.currency === Currencies.WETH ? 18 : 6)}
            validationSchema={principalValidationSchema()}
          />
          <div className="w-full md:w-[180px]">
            <FilterCurrency
              defaultValue={currency}
              onChange={newValue =>
                onTermsChange({ currency: newValue as Exclude<Currencies, Currencies.ALL> })
              }
              isAllVisible={false}
              hint={!isTermsUpdate ? 'Choose in which currency you want to take loan.' : ''}
              disabled={isTermsUpdate || isMakeOffer}
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-8 md:flex-row">
          <TextInput
            className="-mb-5 w-full md:w-[238px]"
            value={apr ?? ''}
            onValueChange={handleAprChange}
            label="Interest APR"
            icon="%"
            format={formatOnlyInteger}
            validationSchema={
              isTermsUpdate && originalTerms?.apr
                ? aprValidationSchema(originalTerms.apr)
                : undefined
            }
          />
          {!isMakeOffer && !isMakeCollateralOffer && !isTermsUpdate && (
            <div className="w-full md:w-[180px]">
              <div className="flex items-center gap-[14px] p-[2px]">
                <Checkbox checked={terms.allowDealNow} onChange={handleDealNowChange} />
                <div className="font-body-3">Allow Deal Now</div>
              </div>
              <Hint text="Select if you want to allow for loan creation without your additional approval." />
            </div>
          )}
        </div>
        <TextInput
          className="w-full md:w-[238px]"
          value={repayment ?? ''}
          onValueChange={newValue => onTermsChange({ repayment: newValue.value })}
          label="Repayment"
          hint="Fills in automatically when you specify Principal, APR% and Duration."
          disabled
        />
        <TextInput
          className="w-full md:w-[238px]"
          value={duration ?? ''}
          onValueChange={handleDurationChange}
          label="Duration"
          hint="Type how long you want your loan to last."
          icon="days"
          format={formatOnlyInteger}
          validationSchema={isMakeOffer || isTermsUpdate ? durationValidationSchema : undefined}
        />
        {(isMakeOffer || isMakeCollateralOffer || isTermsUpdate) && (
          <div className="w-full md:w-[238px]">
            <Dropdown title={`${terms.expirationDate ?? 3} days`} label="Offer expiration date">
              <div className="flex flex-col">
                {expirationDateOptions.map(option => (
                  <DropdownMenuItem key={option} onClick={() => onExpirationDateSelect(option)}>
                    <div className="group flex cursor-pointer items-center justify-between">
                      <div className="font-body-3 py-2.5 pr-5">{option} days</div>
                      <Check
                        className={`${
                          terms.expirationDate !== option && 'invisible'
                        } group-hover:visible`}
                      />
                    </div>
                  </DropdownMenuItem>
                ))}
              </div>
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  )
}
