import { createAction } from '@reduxjs/toolkit'

import { createAppAsyncThunk } from 'store/helpers'
import * as nftApi from 'utils/api/nft'

export const whitelistNft = createAppAsyncThunk<void, string>('nft/whitelistNft', async address => {
  await nftApi.whitelistNft(address)
})

export const resetWhiteListNft = createAction('nft/resetWhiteListNft')
