import { useEffect, useRef } from 'react'

export const useClickOutside = (callback: () => void) => {
  const elementRef = useRef<HTMLDivElement>(null)

  const callbackFunction = (target: EventTarget | null): void => {
    if (elementRef.current && !elementRef.current?.contains(target as Node)) callback()
  }

  useEffect(() => {
    document.body.addEventListener('click', ({ target }) => callbackFunction(target))

    return () =>
      document.body.removeEventListener('click', ({ target }) => callbackFunction(target))
  })

  return { elementRef }
}
