import { FC, PropsWithChildren } from 'react'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { Hint } from 'components/form/hint/Hint'
import { Label } from 'components/form/label/Label'

import { ReactComponent as ChevronDown } from 'assets/icons/chevronDown.svg'

interface DropdownProps extends PropsWithChildren {
  disabled?: boolean
  hint?: string
  label?: string
  title?: string
}

export const Dropdown: FC<DropdownProps> = ({ disabled, title, label, hint = ' ', children }) => (
  <DropdownMenu.Root modal={false}>
    <div className="flex flex-col">
      {label && <Label text={label} classNames="text-third-10	font-body-3" />}
      <DropdownMenu.Trigger
        className={`group font-body-3 grid min-h-[36px] w-full grid-cols-filterTrigger items-center overflow-hidden rounded-lg border border-third-70 py-2 px-4 text-third-10 data-[state=open]:border-primary-50 sm:min-h-[46px] ${
          disabled && 'border-third-80 bg-third-80 text-third-50'
        }`}
        disabled={disabled}
      >
        <p className="truncate text-left">{title}</p>
        <ChevronDown className="group-data-[state=open]:rotate-180" />
      </DropdownMenu.Trigger>
      <Hint text={hint} />
    </div>
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        className="max-h-72 min-w-[163px] max-w-[100vw] overflow-y-auto rounded-lg border border-third-70 bg-box-background p-4 text-third-10 sm:p-6"
        align="start"
        sideOffset={6}
        collisionPadding={10}
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
)
