import { FC } from 'react'

import { Address } from 'wagmi'

import { ReactComponent as MetaMaskIcon } from 'assets/icons/metaMask.svg'
import { ReactComponent as PowerIcon } from 'assets/icons/power.svg'

type NavigationWalletInfoProps = {
  address: string | Address
  className?: string
  network?: string
  onDisconnect: () => void
}

export const NavigationWalletInfo: FC<NavigationWalletInfoProps> = props => {
  const { address, className, network, onDisconnect } = props

  return (
    <div className={`flex items-center rounded-2xl border border-third-80 py-4 px-5 ${className}`}>
      <MetaMaskIcon />
      <div className="ml-3.5 flex flex-col">
        <div className="font-overline text-third-60">{network}</div>
        <div className="font-body-4 text-third-10">{address}</div>
      </div>
      <button type="button" onClick={onDisconnect} className="ml-auto">
        <PowerIcon className="h-[18px] w-[18px] stroke-third-10" />
      </button>
    </div>
  )
}
