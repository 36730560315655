import { Notification } from 'models/Notification'
import { API_URLS } from 'utils/constants'

import API from './api'

type GetNotificationsResult = {
  count: number
  next: string | null
  previous: string | null
  results: Notification[]
}

export const getNotifications = async (): Promise<Notification[]> => {
  const { data } = await API.get<GetNotificationsResult>(`${API_URLS.ZEXUS_API}/notifications/`)
  return data.results
}

export type MarkNotificationsVars = {
  list: number[]
}

export const markNotifications = async (variables: MarkNotificationsVars): Promise<void> => {
  await API.patch(`${API_URLS.ZEXUS_API}/notifications/`, {
    mark_read: variables.list,
  })
}
