import React, { FC, ReactNode, useCallback } from 'react'

import { NavLink, NavLinkProps } from 'react-router-dom'

type LinkProps = NavLinkProps & {
  internal?: boolean
}

const baseClassName = `transition-all duration-300 text-third-10 hover:text-primary-70`

export const Link: FC<LinkProps> = props => {
  const { internal, children, className, to, ...restProps } = props

  const getClassName = useCallback(
    (args: { isActive: boolean; isPending: boolean }) => {
      if (!className) return baseClassName

      const { isActive } = args
      const propClassName =
        typeof className === 'function' ? className(args) : (className as string)
      return isActive
        ? `${baseClassName} ${propClassName} text-primary-70`
        : `${baseClassName} ${propClassName}`
    },
    [baseClassName]
  )

  if (!internal)
    return (
      <a
        {...(restProps as React.RefAttributes<HTMLAnchorElement>)}
        href={to as string}
        className={`${baseClassName} ${className}`}
      >
        {children as ReactNode}
      </a>
    )

  return (
    <NavLink {...restProps} to={to} className={getClassName}>
      {children}
    </NavLink>
  )
}
