import { createAction } from '@reduxjs/toolkit'
import { Address } from 'wagmi'

import { Collateral, CollateralDetails, NFT } from 'models/Collateral'
import { Loan, LoanDetails } from 'models/Loan'
import { UserOffer } from 'models/Offer'
import { createAppAsyncThunk } from 'store/helpers'
import { getCollateralDetails } from 'utils/api/collaterals'
import { getLoanDetails } from 'utils/api/loans'
import * as nftApi from 'utils/api/nft'
import { GetPriceValuationVarsItem } from 'utils/api/nft'
import * as profileApi from 'utils/api/profile'
import {
  GetOffersVars,
  GetOwnedNftsVars,
  GetProfileLoansVars,
  GetSingleOfferVars,
} from 'utils/api/profile'
import { mergeNFTWithPrices } from 'utils/nft'

export const getProfileLoans = createAppAsyncThunk<Loan[], GetProfileLoansVars>(
  'profile/getProfileLoans',
  async variables => await profileApi.getProfileLoans(variables)
)

export const getProfileCollaterals = createAppAsyncThunk<Collateral[], Address>(
  'profile/getProfileCollaterals',
  async userAddress => await profileApi.getProfileCollaterals(userAddress)
)

export const getOwnedNfts = createAppAsyncThunk<NFT[], GetOwnedNftsVars>(
  'profile/getOwnedNNfts',
  async variables => {
    const nfts = await profileApi.getOwnedNfts(variables)

    const pricesVariables: GetPriceValuationVarsItem[] = nfts.map(item => ({
      address: item.address,
      token_id: item.tokenId,
    }))
    const nftPriceValuation = await nftApi.getPriceValuation(pricesVariables)

    return mergeNFTWithPrices({
      ...nftPriceValuation,
      nfts,
    })
  }
)

export const getOffers = createAppAsyncThunk<UserOffer[], GetOffersVars>(
  'profile/getOffers',
  async variables => await profileApi.getOffers(variables)
)

export const resetOffers = createAction('profile/resetOffers')

export const getSingleOffer = createAppAsyncThunk<
  LoanDetails | CollateralDetails | undefined,
  GetSingleOfferVars
>('profile/getSingleOffer', async variables => {
  switch (variables.type) {
    case 'loan':
      return await getLoanDetails({ id: variables.id })
    case 'collateral':
      return await getCollateralDetails({ id: variables.id })
    default:
      return undefined
  }
})

export const setSelectedOffer = createAction<UserOffer | undefined>('profile/setSelectedOffer')

export const resetSingleOffer = createAction('profile/resetSingleOffer')

export const setLoadingPending = createAction('profile/setLoadingPending')

export const setLoadingIdle = createAction('profile/setLoadingIdle')

export const setLoadingDeletePending = createAction('profile/setLoadingDeletePending')

export const setLoadingDeleteIdle = createAction('profile/setLoadingDeleteIdle')
