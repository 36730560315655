export const formatOnlyInteger = (str: string): string => str.replace(/[^0-9]/g, '')

export const formatOnlyFloat = (str: string, numberOfDigits?: number): string => {
  const strDot = str.replaceAll(',', '.')
  const split = strDot.replace(/[^0-9.]/g, '').split('.')

  const beforeDot = split[0] !== '' ? parseInt(split[0], 10).toString(10) : ''
  const afterDot = split.length > 1 ? split.splice(1).join('') : ''

  if (!afterDot) return `${beforeDot}${strDot.indexOf('.') > -1 ? '.' : ''}`
  return `${beforeDot}.${afterDot.substring(0, numberOfDigits)}`
}

export const formatMinMaxInteger = (str: string, min: number, max: number): string => {
  let newString = formatOnlyInteger(str)
  if (parseInt(newString, 10) < min) {
    newString = min.toString(10)
  } else if (parseInt(newString, 10) > max) {
    newString = max.toString(10)
  }
  return newString
}
