import { useNavigate } from 'react-router-dom'

import { Button } from 'components/buttons'

interface BannerProps {
  className?: string
}

export const BannerHomeTop = ({ className }: BannerProps) => {
  const navigate = useNavigate()
  const onPrimaryButtonClick = (): void => {
    // TODO: handle add new collaterals click
  }

  const onSecondaryButtonClick = (): void => navigate('/loans')

  return (
    <div
      className={`bg-primary-100 flex w-full flex-col items-center justify-between gap-[69px] bg-[url(/src/assets/image-temp.png)] bg-cover bg-center p-4 md:flex-row md:pl-[156px] md:pt-[55px] md:pb-[60px] md:pr-[339px] ${className}`}
    >
      <div className="flex w-full flex-col gap-4 md:w-[429px]">
        <div className="md:font-headline-h2 font-header-h6">Need some money for investment?</div>
        <div className="font-header-h5 max-w-[396px]">
          If you still haven’t found what are you looking for reach us on XYZ.
        </div>
      </div>
      <div className="flex w-full flex-col gap-4 md:w-[213px]">
        <Button size="small" onClick={onPrimaryButtonClick}>
          Add new collaterals
        </Button>
        <Button variant="secondary" size="small" onClick={onSecondaryButtonClick}>
          Go to loan marketplace
        </Button>
      </div>
    </div>
  )
}
