import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import { useAppSelector } from 'hooks/store.hooks'
import { homeStateSelector } from 'store/slices/home/home.slice'

import { Button, Link, Tile } from 'components'

import { sliderSettings } from '../helpers'

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrowRight.svg'

export const LoanSection = () => {
  const { loans } = useAppSelector(homeStateSelector)
  const navigate = useNavigate()

  const settings = sliderSettings(loans?.length)
  const maxSliderWidth = loans?.length < 3 ? 289 * loans.length : 886

  return (
    <div>
      <div className="container flex flex-col items-center gap-3 pb-14 pt-[120px] sm:flex-row sm:items-center sm:justify-between">
        <div className="flex w-[320px] flex-col items-center pr-1 text-center sm:items-start sm:text-left">
          <h4 className="mb-2">Active loans</h4>
          <p className="font-body-3">
            As a lender, you may also “make an offer” to active loans to earn APR%.
          </p>
          <Button onClick={() => navigate('loans')} icon={<ArrowRightIcon />} className="mt-8">
            View all loans
          </Button>
        </div>
        <div
          className={`mx-6 mt-4 max-[919px]:w-[274px] sm:mt-0 min-[920px]:w-[580px] min-[1200px]:w-[${maxSliderWidth}px]`}
        >
          {loans?.length > 1 && (
            <Slider {...settings}>
              {loans.map((item, index) => {
                const { name, id, nfts, apr, currency, principal, dueDate } = item
                const key = `${id}-${index}`
                const date = dayjs(dueDate).format('[DUE] DD MMM')
                return (
                  <Link to={`/loans/${id}`} internal key={key}>
                    <Tile
                      apr={apr}
                      className="mx-auto"
                      currency={currency}
                      id={id}
                      nfts={nfts}
                      principal={principal}
                      subtitle={date}
                      title={name}
                      type="loan"
                      homepage={loans.length}
                    />
                  </Link>
                )
              })}
            </Slider>
          )}
          {loans?.length === 1 && (
            <Link to={`loans/${loans[0].id}`}>
              <Tile
                apr={loans[0].apr}
                className="mx-auto"
                currency={loans[0].currency}
                id={loans[0].id}
                nfts={loans[0].nfts}
                principal={loans[0].principal}
                subtitle={dayjs(loans[0].dueDate).format('[DUE] DD MMM')}
                title={loans[0].name}
                type="loan"
              />
            </Link>
          )}
        </div>
      </div>
      <div className="mx-1 h-[2px] bg-third-20" />
    </div>
  )
}
