import { BigNumber, BigNumberish, Bytes } from 'ethers'

function isInteger(value: number) {
  // eslint-disable-next-line no-self-compare,eqeqeq
  return typeof value === 'number' && value == value && value % 1 === 0
}

function isBytes(value: any): value is Bytes {
  if (value == null) {
    return false
  }

  if (value.constructor === Uint8Array) {
    return true
  }
  if (typeof value === 'string') {
    return false
  }
  if (!isInteger(value.length) || value.length < 0) {
    return false
  }

  for (let i = 0; i < value.length; i++) {
    const v = value[i]
    if (!isInteger(v) || v < 0 || v >= 256) {
      return false
    }
  }
  return true
}

function isHexString(value: any, length?: number): boolean {
  if (typeof value !== 'string' || !value.match(/^0x[0-9A-Fa-f]*$/)) {
    return false
  }
  return !(length && value.length !== 2 + 2 * length)
}

export function isBigNumberish(value: any): value is BigNumberish {
  return (
    value != null &&
    (BigNumber.isBigNumber(value) ||
      (typeof value === 'number' && value % 1 === 0) ||
      (typeof value === 'string' && !!value.match(/^-?[0-9]+$/)) ||
      isHexString(value) ||
      typeof value === 'bigint' ||
      isBytes(value))
  )
}
