import { Address } from '@wagmi/core'

export const blockchainLoanType = {
  Loan: [
    { name: 'value', type: 'uint256' },
    { name: 'currency', type: 'address' },
    { name: 'interestRate', type: 'uint256' },
    { name: 'duration', type: 'uint256' },
    { name: 'creator', type: 'address' },
    { name: 'collateralId', type: 'bytes32' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' },
  ],
}

export const blockchainCollateralOfferType = {
  CollateralOffer: [
    { name: "idCollateral", type: "bytes32"},
    { name: 'value', type: 'uint256' },
    { name: 'interestRate', type: 'uint256' },
    { name: 'duration', type: 'uint256' },
    { name: 'currency', type: 'address' },
    { name: 'loaner', type: 'address' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' },
  ],
}

export type Domain = {
  name: string
  version: string
  chainId: number
  verifyingContract: Address
}
