import { Outlet } from 'react-router-dom'

import { ProfileTabs } from './components/ProfileTabs'

export const ProfileView = () => (
  <div className="container">
    <h2 className="mb-8 mt-12">My profile</h2>
    <ProfileTabs />
    <Outlet />
  </div>
)
