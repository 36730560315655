export const roundDigits = (num: number, digits: number): number =>
  Math.ceil(num * 10 ** digits) / 10 ** digits

export const roundUnknownTypeDigits = (
  unknownTypeNum: number | string | undefined,
  digits: number
): number | string | undefined => {
  if (unknownTypeNum === undefined) return unknownTypeNum
  if (typeof unknownTypeNum === 'number') return roundDigits(unknownTypeNum, digits)
  const num: number = Number(unknownTypeNum)
  if (Number.isNaN(num)) return unknownTypeNum
  return String(roundDigits(num, digits))
}
