import { configureStore } from '@reduxjs/toolkit'

import authSliceReducer from './slices/auth/auth.slice'
import collateralSliceReducer from './slices/collaterals/collaterals.slice'
import commSliceReducer from './slices/comm/comm.slice'
import homeSliceReducer from './slices/home/home.slice'
import loansSliceReducer from './slices/loans/loans.slice'
import navSliceReducer from './slices/nav/nav.slice'
import nftSliceReducer from './slices/nft/nft.slice'
import notificationsSliceReducer from './slices/notifications/notifications.slice'
import profileSliceReducer from './slices/profile/profile.slice'

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    collaterals: collateralSliceReducer,
    comm: commSliceReducer,
    home: homeSliceReducer,
    loans: loansSliceReducer,
    nav: navSliceReducer,
    nft: nftSliceReducer,
    notifications: notificationsSliceReducer,
    profile: profileSliceReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.icon'],
        ignoredPaths: ['comm.modalStack'],
      },
    }),
})
