import { FC } from 'react'

import dayjs from 'dayjs'
import { Address } from 'wagmi'

import { ProfileLoansFilter } from 'models/Filter'
import { Loan } from 'models/Loan'

import { Link, Tile } from 'components'

import { ProfileLoansFilters } from './components/ProfileLoansFilters'

type ProfileLoansViewProps = {
  loans: Loan[]
  onFilterChange: (filter: ProfileLoansFilter) => void
  userAddress: Address
}

export const ProfileLoansView: FC<ProfileLoansViewProps> = props => {
  const { loans, onFilterChange, userAddress } = props

  return (
    <div className="container rounded-b bg-third-90">
      <ProfileLoansFilters onChange={onFilterChange} className="mx-2" />
      <div className="mx-2 flex flex-wrap justify-center gap-8 lg:justify-start">
        {loans.map((item, index) => {
          const { id, name, nfts, apr, principal, currency, status, dueDate, borrower } = item
          const key = `loan-${id}-${index}`
          const djDueDate = dayjs(dueDate)
          const date = djDueDate.format('[DUE] DD MMM')
          const alert = status === 'OVERDUE' || dayjs().isAfter(djDueDate)
          if (status === 'ACTIVE' || status === 'OVERDUE') {
            return (
              <Link to={`/loans/${id}`} key={key}>
                <Tile
                  alert={alert}
                  apr={apr}
                  currency={currency}
                  id={id}
                  nfts={nfts}
                  principal={principal}
                  status={status}
                  subtitle={date}
                  title={name}
                  type="loan"
                  userType={borrower === userAddress ? 'borrower' : 'lender'}
                />
              </Link>
            )
          }
          return (
            <Tile
              alert={alert}
              apr={apr}
              currency={currency}
              id={id}
              key={key}
              nfts={nfts}
              principal={principal}
              status={status}
              subtitle={date}
              title={name}
              type="loan"
              userType={borrower === userAddress ? 'borrower' : 'lender'}
            />
          )
        })}
      </div>
    </div>
  )
}
