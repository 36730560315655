import { FC, forwardRef, PropsWithChildren, Ref } from 'react'

import * as RadixAccordion from '@radix-ui/react-accordion'

import { AccordionElement } from 'models/Accordion'

import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'

const AccordionTrigger = forwardRef(
  ({ children }: PropsWithChildren, forwardedRef: Ref<HTMLButtonElement>) => (
    <RadixAccordion.Trigger
      ref={forwardedRef}
      className="group font-body-1 flex h-8 w-full items-center justify-between text-third-95"
    >
      {children}
      <div className="h-6 w-6">
        <PlusIcon className="stroke-third-95 group-data-[state=open]:hidden" />
        <MinusIcon className="hidden stroke-third-95 group-data-[state=open]:block" />
      </div>
    </RadixAccordion.Trigger>
  )
)

const AccordionContent = forwardRef(
  ({ children }: PropsWithChildren, forwardedRef: Ref<HTMLDivElement>) => (
    <RadixAccordion.Content ref={forwardedRef} className="font-body-3 break-words py-2">
      {children}
    </RadixAccordion.Content>
  )
)

interface AccordionProps {
  className?: string
  elements: AccordionElement[]
}

export const Accordion: FC<AccordionProps> = ({ className, elements }) => (
  <RadixAccordion.Root type="single" collapsible className={`w-full ${className}`}>
    {elements.map(({ value, header, content }, index) => {
      const key = `${index}${value}`
      return (
        <RadixAccordion.Item
          key={key}
          value={value}
          className="mt-10 w-full border-b border-third-95 first-of-type:mt-0"
        >
          <AccordionTrigger>{header}</AccordionTrigger>
          <AccordionContent>{content}</AccordionContent>
        </RadixAccordion.Item>
      )
    })}
  </RadixAccordion.Root>
)
