import { useState } from 'react'

import * as yup from 'yup'

import { Currencies } from 'models/Currency'
import { FormInput } from 'models/Form'

import { Accordion, BannerHomeTop, Button, Collapsible, Modal, Table, Tile } from 'components'
import { TextInput } from 'components/form/text-input/TextInput'

import { useAppDispatch } from '../../hooks/store.hooks'
import { StoreModalType } from '../../models/Comm'
import { pushAlert, pushModal, pushToast } from '../../store/slices/comm/comm.actions'
import { accordionElements, columns, data, nfts, validationSchema } from './helper'

import { ReactComponent as CheckBadgeIcon } from 'assets/icons/checkBadge.svg'
import { ReactComponent as Funnel } from 'assets/icons/funnel.svg'

export const DemoView = () => {
  const [firstInputValue, setFirstInputValue] = useState('')
  const [secondInputValue, setSecondInputValue] = useState('')
  const [thirdInputValue, setThirdInputValue] = useState(0)
  const [showModal, setShowModal] = useState(false)

  const dispatch = useAppDispatch()

  return (
    <div className="container flex flex-col items-center justify-center gap-8 py-8 px-4">
      <section>
        <h2 className="mb-4 text-center">Banner</h2>
        <div>
          <BannerHomeTop />
        </div>
      </section>

      <section>
        <h2 className="mb-4 text-center">Tiles</h2>
        <div className="flex flex-col items-start gap-8 md:flex-row">
          <Tile
            apr={10}
            currency={Currencies.WETH}
            nfts={nfts}
            principal="123"
            title="Collateral #1"
            type="collateral"
          />
          <Tile
            apr={10}
            currency={Currencies.WETH}
            nfts={nfts}
            principal="123"
            status="ACTIVE"
            subtitle="This is optional subtitle"
            title="Loan #1"
            type="loan"
            userType="borrower"
          />
        </div>
      </section>

      <section className="w-full">
        <h2 className="mb-4 text-center">Collapsible</h2>
        <div className="mx-auto w-[420px] max-w-full">
          <Collapsible title="This is collapsible">
            <div className="flex flex-col items-center justify-center">
              <Table columns={columns} data={data} />
            </div>
          </Collapsible>
        </div>
      </section>

      <section>
        <h2 className="text-center">Form</h2>
        <div className="flex flex-wrap items-start gap-8">
          <TextInput
            value={firstInputValue}
            onValueChange={(newValue: FormInput) => setFirstInputValue(newValue.value || '')}
            label="This is text input"
            hint="No validation here"
          />
          <TextInput
            value={secondInputValue}
            onValueChange={(newValue: FormInput) => setSecondInputValue(newValue.value || '')}
            label="This is text input with validation"
            validationSchema={yup.reach(validationSchema, 'exampleInput')}
          />
          <TextInput
            value={thirdInputValue ?? 0}
            onValueChange={(newValue: FormInput) =>
              setThirdInputValue(Number(newValue.value || ''))
            }
            label="Numeric input with %"
            hint="Value between 0-100"
            icon="%"
            validationSchema={yup.reach(validationSchema, 'numericInput')}
          />
        </div>
      </section>

      <section>
        <h2 className="mb-4 text-center">Buttons</h2>
        <div className="flex flex-col items-center gap-4">
          <div className="flex flex-wrap items-center justify-center gap-8">
            <h5>Size:</h5>
            <Button onClick={() => {}} size="large">
              Large
            </Button>
            <Button onClick={() => {}}>Default</Button>
            <Button onClick={() => {}} size="small">
              Small
            </Button>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-8">
            <h5>Variant:</h5>
            <Button onClick={() => {}} variant="primary">
              Primary
            </Button>
            <Button onClick={() => {}} variant="secondary">
              Secondary
            </Button>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-8">
            <h5>Theme:</h5>
            <Button onClick={() => {}} variant="primary">
              Default
            </Button>
            <Button onClick={() => {}} variant="primary" theme="light">
              Light
            </Button>
            <Button onClick={() => {}} variant="primary" theme="dark">
              Dark
            </Button>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-8">
            <h5>Icon:</h5>
            <Button onClick={() => {}} size="large" icon={<Funnel />} />
            <Button onClick={() => {}} icon={<Funnel />} />
            <Button onClick={() => {}} size="small" icon={<Funnel />} />
          </div>
        </div>
      </section>

      <section>
        <h2 className="mb-4 text-center">Communication with user</h2>
        <div className="flex flex-wrap justify-center gap-8">
          <Button onClick={() => dispatch(pushToast({ message: 'Example toast...' }))}>
            Show Toast
          </Button>
          <Button onClick={() => dispatch(pushAlert({ message: 'Example alert...' }))}>
            Show Alert
          </Button>
          <Button onClick={() => setShowModal(true)}>Show local modal</Button>
          <Button
            onClick={() =>
              dispatch(
                pushModal({
                  message: 'Lorem ipsum...',
                  title: 'Global simple modal',
                  type: StoreModalType.SIMPLE_MESSAGE,
                })
              )
            }
          >
            Global simple modal
          </Button>
          <Button
            onClick={() =>
              dispatch(
                pushModal({
                  message: 'Lorem ipsum...',
                  title: 'Global error modal',
                  type: StoreModalType.ERROR_MESSAGE,
                })
              )
            }
          >
            Global error modal
          </Button>
          <Button
            onClick={() =>
              dispatch(
                pushModal({
                  message: 'Lorem ipsum...',
                  title: 'Global info modal',
                  type: StoreModalType.INFO_MESSAGE,
                })
              )
            }
          >
            Global info modal
          </Button>
          <Button
            onClick={() =>
              dispatch(
                pushModal({
                  message: 'Lorem ipsum...',
                  title: 'Global success modal',
                  type: StoreModalType.SUCCESS_MESSAGE,
                })
              )
            }
          >
            Global success modal
          </Button>
          <Button
            onClick={() =>
              dispatch(
                pushModal({
                  message: 'Lorem ipsum...',
                  title: 'Global success modal',
                  type: StoreModalType.WARNING_MESSAGE,
                })
              )
            }
          >
            Global warning modal
          </Button>
          <Button
            onClick={() =>
              dispatch(
                pushModal({
                  type: StoreModalType.GENERAL_ERROR,
                })
              )
            }
          >
            Global general error modal
          </Button>
          <Button
            onClick={() =>
              dispatch(
                pushModal({
                  type: StoreModalType.NETWORK_ERROR,
                })
              )
            }
          >
            Global network error modal
          </Button>
          <Button
            onClick={() =>
              dispatch(
                pushModal({
                  type: StoreModalType.NOT_FOUND,
                })
              )
            }
          >
            Global not found modal
          </Button>
        </div>
        <Modal
          isOpen={showModal}
          onOpenChange={open => setShowModal(open)}
          title="Local modal"
          icon={<CheckBadgeIcon className="stroke-success-50" />}
          onCancel={() => {}}
          onConfirm={() => {}}
        >
          Lorem ipsum..
        </Modal>
      </section>

      <section className="w-full">
        <h2 className="mb-4 text-center">Table</h2>
        <div className="mx-auto w-[640px] max-w-full">
          <Table columns={columns} data={data} />
        </div>
      </section>

      <section className="w-full">
        <h2 className="mb-4 text-center">Accordion</h2>
        <div className="w-full bg-secondary-10 p-8">
          <div className="mx-auto w-full max-w-[420px] text-black">
            <Accordion elements={accordionElements} />
          </div>
        </div>
      </section>
    </div>
  )
}
