import { useEffect, useState } from 'react'

import { BigNumber } from 'ethers'
import { useNavigate, useParams } from 'react-router-dom'
import { Address } from 'wagmi'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { getStorageItem, setStorageItem } from 'hooks/useStorage'
import { StoreModalType } from 'models/Comm'
import { Currencies } from 'models/Currency'
import { Terms } from 'models/Terms'
import { RequestStatus } from 'store/helpers'
import {
  deleteCollateral,
  getCollateralDetails,
  resetDeleteCollateralStatus,
  setDealNowStatus,
} from 'store/slices/collaterals/collaterals.actions'
import { collateralStateSelector } from 'store/slices/collaterals/collaterals.slice'
import { pushModal } from 'store/slices/comm/comm.actions'
import {
  setLoadingDeleteIdle,
  setLoadingIdle,
  setLoadingPending,
} from 'store/slices/profile/profile.actions'
import { acceptLoanAsLender, cancelCollateral, erc20Approve } from 'utils/api/blockchain'
import { ADDRESSES } from 'utils/constants'
import { convertFromBigNumber, convertToBigNumber } from 'utils/form'

import { ActivityIndicator } from 'components'

import { CollateralDetailView } from './CollateralDetailView'

export const CollateralDetailScreen = () => {
  const dispatch = useAppDispatch()
  const { collateralDetails, deleteCollateralStatus } = useAppSelector(collateralStateSelector)
  const { id } = useParams()
  const isMakeOfferInfoRead = getStorageItem('isMakeOfferCollateralInfoRead')
  const [isDealNowModalOpen, setIsDealNowModalOpen] = useState(false)
  const [isDealNowConfirmationModalOpen, setIsDealNowConfirmationModalOpen] = useState(false)
  const [isMakeOfferInfoModalOpen, setIsMakeOfferInfoModalOpen] = useState(!isMakeOfferInfoRead)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) return
    dispatch(getCollateralDetails({ id }))
  }, [id])

  const handleDealNow = async () => {
    if (!ADDRESSES.ZEXUS_LENDER || !collateralDetails?.id) return
    if (!collateralDetails.allowDealNow) {
      dispatch(
        pushModal({
          message: 'Deal now is not allowed by borrower.',
          title: 'Error!',
          type: StoreModalType.ERROR_MESSAGE,
        })
      )
    }
    dispatch(setLoadingPending())
    const terms: Terms = {
      allowDealNow: collateralDetails?.allowDealNow as boolean,
      apr: collateralDetails?.apr as number,
      currency: collateralDetails?.currency as Exclude<Currencies, Currencies.ALL>,
      duration: collateralDetails?.duration as number,
      principal: convertFromBigNumber(
        collateralDetails.principal,
        collateralDetails.currency
      ) as string,
      repayment: convertFromBigNumber(
        collateralDetails.repayment,
        collateralDetails.currency
      ) as string,
    }

    const principalBN: BigNumber = convertToBigNumber(
      terms?.principal,
      terms?.currency,
      false
    ) as BigNumber

    const approveResult = await erc20Approve(
      principalBN,
      ADDRESSES[terms.currency] as Address,
      ADDRESSES.ZEXUS_LENDER
    )
    const approveTransactionReceipt = await approveResult.wait()
    if (approveTransactionReceipt.status !== 1) {
      dispatch(setLoadingIdle())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
      return
    }

    const acceptLoanAsLenderResult = await acceptLoanAsLender(collateralDetails.collateralId)
    const acceptLoanTransactionReceipt = await acceptLoanAsLenderResult.wait()
    if (acceptLoanTransactionReceipt.status !== 1) {
      dispatch(setLoadingIdle())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
      return
    }

    dispatch(setDealNowStatus({ id: collateralDetails.id }))
    setIsDealNowModalOpen(false)
    setIsDealNowConfirmationModalOpen(true)
    dispatch(setLoadingIdle())
  }

  const handleGoToLoan = () => {
    navigate(`/loans/${collateralDetails!.id}`)
  }

  const handleGoToProfile = () => {
    navigate('/profile/loans')
  }

  const handleDealNowConfirmationModalOpenChange = (open: boolean) => {
    if (open) {
      setIsDealNowModalOpen(false)
    } else {
      handleGoToLoan()
    }
  }

  const storeInfoModalRead = () => {
    setStorageItem('isMakeOfferCollateralInfoRead', 'true')
  }

  const handleMakeOfferInfoModalOpenChange = (open: boolean) => {
    setIsMakeOfferInfoModalOpen(open)
    if (!open) {
      storeInfoModalRead()
    }
  }

  const handleMakeOfferInfoModalButtonClick = () => {
    storeInfoModalRead()
    setIsMakeOfferInfoModalOpen(false)
  }

  const handleMakeOffer = () => {
    navigate('offer')
  }

  const handleDelete = async () => {
    if (collateralDetails?.id) {
      let isSuccess = false

      try {
        const result = await cancelCollateral(collateralDetails.collateralId)
        const transactionReceipt = await result?.wait()

        if (transactionReceipt?.status === 1) {
          dispatch(deleteCollateral(collateralDetails.id))
          isSuccess = true
        }
      } catch {
        /* we already have isSuccess set to false by default */
      }

      if (!isSuccess) {
        dispatch(setLoadingIdle())
        dispatch(
          pushModal({
            type: StoreModalType.GENERAL_ERROR,
          })
        )
      }
    }
  }

  useEffect(() => {
    if (deleteCollateralStatus === RequestStatus.FULFILLED) {
      setIsDeleteConfirmationModalOpen(true)
    }
  }, [deleteCollateralStatus])

  const handleDeleteConfirmationModalOpenChange = (open: boolean) => {
    dispatch(setLoadingDeleteIdle())
    setIsDeleteConfirmationModalOpen(open)
    if (!open) {
      dispatch(resetDeleteCollateralStatus())
      navigate('/collaterals')
    }
  }

  if (!collateralDetails) return <ActivityIndicator fullScreen />

  return (
    <CollateralDetailView
      collateralDetails={collateralDetails}
      isDealNowConfirmationModalOpen={isDealNowConfirmationModalOpen}
      isDealNowModalOpen={isDealNowModalOpen}
      isMakeOfferInfoModalOpen={isMakeOfferInfoModalOpen}
      onDealNow={() => handleDealNow().catch(() => dispatch(setLoadingIdle()))}
      onDealNowConfirmationModalOpenChange={handleDealNowConfirmationModalOpenChange}
      onDelete={handleDelete}
      onGoToLoan={handleGoToLoan}
      onGoToProfile={handleGoToProfile}
      onMakeOffer={handleMakeOffer}
      onMakeOfferInfoModalOpenChange={handleMakeOfferInfoModalOpenChange}
      onMakeOfferInfoModalButtonClick={handleMakeOfferInfoModalButtonClick}
      setIsDealNowModalOpen={setIsDealNowModalOpen}
      isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
      onDeleteConfirmationModalOpenChange={handleDeleteConfirmationModalOpenChange}
    />
  )
}
