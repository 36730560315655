import { ReactNode } from 'react'

import { Address } from 'wagmi'

import { Currencies } from 'models/Currency'
import { ColumnDefinition } from 'models/Table'
import { shortAddress } from 'utils/address'
import { countTimeLeftFromDate } from 'utils/date'
import { convertFromBigNumber } from 'utils/form'
import { roundDigits } from 'utils/number'

export type TableRowsProps<T> = {
  data: Array<T>
  columns: Array<ColumnDefinition<T>>
  boldColsIndexes?: number[]
  renderCell?: (column: ColumnDefinition<T>, item: T) => ReactNode
}

export const renderTableCell = <T extends Record<string, unknown>>(
  column: ColumnDefinition<T>,
  item: T
): ReactNode => {
  let attr: string | ReactNode

  const keys = String(column.key).split('/')
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (item[key] !== undefined && item[key] !== null) {
      attr = item[key] as ReactNode
      break
    }
  }

  switch (column.type) {
    case 'text':
      return attr as string
    case 'id':
      return `#${attr}`
    case 'WETH' || 'USDT' || 'USDC': {
      if (attr?.toString().includes('e')) {
        attr = attr.toLocaleString('fullwide', { useGrouping: false })
      }
      const val = convertFromBigNumber(
        attr as string,
        Currencies[item.currency as Currencies],
        false
      ) as number
      return (
        <div className="flex gap-1 pr-1" title={`${val} ${item.currency}`}>
          <div className="truncate">{roundDigits(val, 4)}</div>
          <div>{String(item.currency)}</div>
        </div>
      )
    }
    case 'percent':
      return `${attr}%`
    case 'days':
      return `${attr} days`
    case 'address':
      return shortAddress(attr as Address)
    case 'countdown':
      return countTimeLeftFromDate(attr as string)
    case 'offerType':
      return attr === 'OFFER' ? 'Collateral' : 'Loan'
    default:
      return attr
  }
}

// <T extends {}> -> https://stackoverflow.com/questions/47848828/how-to-create-stateless-functional-components-that-accept-generics/51585233#51585233
export const TableRows = <T extends {}>({
  data,
  columns,
  boldColsIndexes,
  renderCell,
}: TableRowsProps<T>): JSX.Element => {
  const rows = data.map((row, index) => {
    const rowKey = `row-${index}`

    return (
      <tr key={rowKey} className="border-b border-third-80 text-third-10 last:border-0">
        {columns.map((column, colIndex) => {
          const cellKey = `cell-${colIndex}`

          return (
            <td
              key={cellKey}
              className={`${column.className ?? ''} font-body-4 py-3 !text-third-10 ${
                boldColsIndexes?.includes(colIndex) ? 'font-semibold' : 'font-normal'
              }`}
            >
              {renderCell ? renderCell(column, row) : renderTableCell(column, row)}
            </td>
          )
        })}
      </tr>
    )
  })

  return <tbody>{rows}</tbody>
}
