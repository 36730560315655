import { FC } from 'react'

import { URLS } from 'utils/constants'

import { WarningModal } from 'components'

import { SimpleModalProps } from './SimpleModal'

export const NotFoundModal: FC<SimpleModalProps> = props => {
  const { isOpen, onOpenChange } = props
  return (
    <WarningModal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title="Not found"
      message={
        <>
          We didn&apos;t find what you&apos;re looking for.
          <br />
          Please repeat or contact with{' '}
          <a href={URLS.SUPPORT} target="_blank" rel="noreferrer">
            support
          </a>
          .
        </>
      }
    />
  )
}
