import { FC, PropsWithChildren, useEffect } from 'react'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import dayjs from 'dayjs'
import { Address } from 'wagmi'

import { useAppSelector } from 'hooks/store.hooks'
import { useWallet } from 'hooks/useWallet'
import { Status, TileType } from 'models/Style'
import { RequestStatus } from 'store/helpers'
import { profileSelector } from 'store/slices/profile/profile.slice'
import { shortAddress } from 'utils/address'

import { ActivityIndicator, Chip } from 'components'

import { EditableName } from './components/EditableName'
import { LoanTerms } from './components/LoanTerms'

import { ReactComponent as BellAlertIcon } from 'assets/icons/bellAlert.svg'
import { ReactComponent as EllipsisVertical } from 'assets/icons/ellipsisVertical.svg'

interface DescriptionInfoProps extends PropsWithChildren {
  alert?: boolean
  apr: number
  currency: string
  dueDate?: string
  duration: number
  isOfferPreview?: boolean
  lenderAddress?: Address
  name?: string
  onDelete?: () => void
  onNameChange?: (name: string) => void
  ownerAddress?: Address
  principal: string
  repayment: string
  status?: Status
  type: TileType
}

export const DescriptionInfo: FC<DescriptionInfoProps> = ({
  alert,
  apr,
  children,
  currency,
  dueDate,
  duration,
  isOfferPreview,
  lenderAddress,
  name,
  onDelete,
  onNameChange,
  ownerAddress,
  principal,
  repayment,
  status,
  type,
}) => {
  if (isOfferPreview && !onNameChange) {
    throw new Error(
      'DescriptionInfo error: onNameChange prop declaration is required when isOfferPreview prop is declared.'
    )
  }

  const { address } = useWallet()

  const isLoggedUserOwner = ownerAddress === address
  const isLoggedUserLender = lenderAddress === address
  const { loadingDeleteState } = useAppSelector(profileSelector)

  useEffect(() => {}, [loadingDeleteState])

  return (
    <div className="order-last mb-8 h-min rounded-lg bg-third-80 py-8 px-7 text-secondary-10 lg:order-none lg:mb-0">
      <div className={`${type === 'collateral' || type === 'loan' ? 'mb-6' : 'mb-0'}`}>
        {status && (
          <div className="mb-2 flex">
            <Chip type={status} />
          </div>
        )}
        {isOfferPreview && type === 'collateral' && (
          <div className="font-body-4 mb-6 font-semibold text-third-40">
            This is preview of your collateral offer.
          </div>
        )}
        <div className="flex items-center justify-between">
          {isOfferPreview && onNameChange && <EditableName onNameChange={onNameChange} />}
          {!isOfferPreview && <h4 className="my-[6px]">{name}</h4>}
          {!isOfferPreview && type === 'collateral' && isLoggedUserOwner && (
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className="outline-none">
                <EllipsisVertical />
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <DropdownMenu.Content
                  align="start"
                  className="rounded-lg border border-third-70 bg-box-background px-5 pt-5 pb-4 shadow-dropdown"
                >
                  <DropdownMenu.Item
                    onClick={onDelete}
                    className="font-body-3 !cursor-pointer px-5 py-[10px] text-error-60 transition-colors hover:text-error-70"
                  >
                    Delete collateral
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          )}
        </div>

        {type === 'collateral' && ownerAddress && !isLoggedUserOwner && (
          <div className="font-body-5 mt-2 text-third-40">
            Created by {shortAddress(ownerAddress)}
          </div>
        )}
        {type === 'collateral' && isLoggedUserOwner && (
          <div className="font-body-5 mt-2 text-third-40">This is your collateral offer.</div>
        )}

        {type === 'loan' && ownerAddress && isLoggedUserLender && (
          <div className="font-body-5 mt-2 text-primary-40">You are current lender</div>
        )}
        {type === 'loan' && ownerAddress && !isLoggedUserOwner && (
          <div className="font-body-5 mt-2 text-third-40">
            Borrower: {shortAddress(ownerAddress)}
          </div>
        )}
        {type === 'loan' && lenderAddress && !isLoggedUserLender && (
          <div className="font-body-5 mt-2 text-third-40">
            Current lender: {shortAddress(lenderAddress)}
          </div>
        )}
        {type === 'loan' && (isLoggedUserOwner || isLoggedUserLender) && dueDate && (
          <div className="font-overline mt-2 flex items-center text-secondary-20">
            {alert && <BellAlertIcon className="mr-1 h-[14px] w-[14px] fill-error-70" />}
            {dayjs(dueDate).format('[DUE] DD MMM YYYY, hh:mmA')}
          </div>
        )}
      </div>
      {loadingDeleteState === RequestStatus.PENDING && <ActivityIndicator whiteColor />}
      <LoanTerms
        principal={principal}
        repayment={repayment}
        apr={apr}
        duration={duration}
        currency={currency}
      />
      {children && <div className="mt-8">{children}</div>}
    </div>
  )
}
