import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const countTimeLeftFromDate = (date: string | undefined, withoutSuffix = true): string => {
  if (!date) return ''
  if (dayjs().isBefore(dayjs(date))) return dayjs(date).fromNow(withoutSuffix)
  return 'Expired'
}

export const getDeadlineInSeconds = (numOfDays: number): number =>
  Math.round(Date.now() / 1000 + numOfDays * 86400)
