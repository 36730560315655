import { Currencies } from 'models/Currency'
import { LoansFilter } from 'models/Filter'
import { Loan, LoanDetails } from 'models/Loan'
import {
  MakeOfferData,
  MakeOfferVars,
  RequestTermsUpdateData,
  RequestTermsUpdateVars,
} from 'models/Offer'
import { mapLoanDetailsResult, mapLoanResult } from 'utils/api/helpers'
import { API_URLS } from 'utils/constants'
import { convertToBigNumber } from 'utils/form'

import API from './api'

export type GetLoansListResponse = {
  count: number
  results: Loan[]
}

export type GetLoansListVars = {
  limit: number
  offset: number
  filters?: LoansFilter
  signal?: AbortSignal
}

export type AcceptLoanOfferData = {
  draftId: number
}

export type ConfirmLoanOfferData = {
  loanId: number
}

export const getLoansList = async (variables: GetLoansListVars): Promise<GetLoansListResponse> => {
  const { filters, limit, offset, signal } = variables
  const { data } = await API.get<GetLoansListResponse>(`${API_URLS.ZEXUS_API}/loans/`, {
    params: {
      limit,
      offset,
      currency: filters?.currency !== Currencies.ALL ? filters?.currency : undefined,
      apr_from: filters?.apr.min,
      apr_to: filters?.apr.max,
      duration_from: filters?.duration.min,
      duration_to: filters?.duration.max,
      principal_from: convertToBigNumber(filters?.principal.min, filters?.currency),
      principal_to: convertToBigNumber(filters?.principal.max, filters?.currency),
      name: filters?.search,
      sort_by: filters?.sort,
    },
    signal,
  })

  const { results, count } = data

  return { results: results.map(mapLoanResult), count }
}

export type GetLoanDetailsVars = {
  id: string
}

export type SetPayBackStatusVars = { id: number }

export type SetDefaultedVars = {
  id: number
}

export type ConfirmLoanTermsOfferVars = { draftId: number; offerId: number }

export const getLoanDetails = async (variables: GetLoanDetailsVars): Promise<LoanDetails> => {
  const { id } = variables
  const { data } = await API.get<LoanDetails>(`${API_URLS.ZEXUS_API}/loans/${id}/`)
  return mapLoanDetailsResult(data)
}

export const makeLoanOffer = async (variables: MakeOfferVars): Promise<MakeOfferData> => {
  const { id, signature, nonce, deadline, terms } = variables
  const offerTermsForBackend = {
    principal: convertToBigNumber(terms.principal, terms.currency),
    repayment: convertToBigNumber(terms.repayment, terms.currency),
    currency: terms.currency,
    apr: terms.apr,
    duration: terms.duration,
    expirationDays: terms.expirationDate,
    signature,
    nonce,
    deadline,
  }

  const { data } = await API.post<MakeOfferData>(`${API_URLS.ZEXUS_API}/terms-offers/loan/${id}/`, {
    ...offerTermsForBackend,
  })
  return data
}

export const requestTermsUpdate = async (
  variables: RequestTermsUpdateVars
): Promise<RequestTermsUpdateData> => {
  const { id, signature, nonce, deadline, terms } = variables
  const offerTermsForBackend = {
    principal: convertToBigNumber(terms.principal, terms.currency),
    repayment: convertToBigNumber(terms.repayment, terms.currency),
    currency: terms.currency,
    apr: terms.apr,
    duration: terms.duration,
    expirationDays: terms.expirationDate,
    signature,
    nonce,
    deadline,
  }

  const { data } = await API.post<RequestTermsUpdateData>(
    `${API_URLS.ZEXUS_API}/terms-offers/extension/${id}/`,
    {
      ...offerTermsForBackend,
    }
  )
  return data
}

export const setPayBackStatus = async (variables: SetPayBackStatusVars): Promise<void> => {
  const { id } = variables

  await API.post<void>(`${API_URLS.ZEXUS_API}/loans/${id}/repay/`)
}

export const setDefaulted = async (variables: SetDefaultedVars): Promise<void> => {
  await API.post<void>(`${API_URLS.ZEXUS_API}/loans/${variables.id}/set-defaulted/`)
}

export const acceptLoanTermsOffer = async (offerId: number): Promise<AcceptLoanOfferData> => {
  const { data } = await API.post<AcceptLoanOfferData>(
    `${API_URLS.ZEXUS_API}/terms-offers/loan/${offerId}/accept/`
  )
  return data
}

export const confirmLoanTermsOffer = async (
  draftId: number,
  offerId: number
): Promise<ConfirmLoanOfferData> => {
  const { data } = await API.post<ConfirmLoanOfferData>(
    `${API_URLS.ZEXUS_API}/terms-offers/loan/${offerId}/accept/`,
    {
      draft_id: draftId,
    }
  )
  return data
}

export const rejectLoanTermsOffer = async (offerId: number, message: string): Promise<void> => {
  await API.post(`${API_URLS.ZEXUS_API}/terms-offers/loan/${offerId}/reject/`, { message })
}

export const cancelLoanTermsOffer = async (offerId: number): Promise<void> => {
  await API.post(`${API_URLS.ZEXUS_API}/terms-offers/loan/${offerId}/cancel/`)
}

export const acceptLoanExtension = async (offerId: number): Promise<void> => {
  await API.post(`${API_URLS.ZEXUS_API}/terms-offers/extension/${offerId}/accept/`)
}

export const rejectLoanExtension = async (offerId: number, message: string): Promise<void> => {
  await API.post(`${API_URLS.ZEXUS_API}/terms-offers/extension/${offerId}/reject/`, { message })
}

export const cancelLoanExtension = async (offerId: number): Promise<void> => {
  await API.post(`${API_URLS.ZEXUS_API}/terms-offers/extension/${offerId}/cancel/`)
}
