import { FC } from 'react'

import { Modal } from 'components'

import { SimpleModalProps } from './SimpleModal'

import { ReactComponent as InformationCircleIcon } from 'assets/icons/informationCircle.svg'

export const InfoModal: FC<SimpleModalProps> = props => {
  const { isOpen, message, onOpenChange, title } = props
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      icon={<InformationCircleIcon className="stroke-secondary-50" />}
      title={title || 'Info'}
    >
      {message}
    </Modal>
  )
}
