import { FC } from 'react'

import { URLS } from 'utils/constants'

import { ErrorModal } from 'components'

import { SimpleModalProps } from './SimpleModal'

export const NetworkErrorModal: FC<SimpleModalProps> = props => {
  const { isOpen, onOpenChange } = props
  return (
    <ErrorModal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title="Network error"
      message={
        <>
          There was a problem with network.
          <br />
          Please repeat or contact with{' '}
          <a href={URLS.SUPPORT} target="_blank" rel="noreferrer">
            support
          </a>
          .
        </>
      }
    />
  )
}
