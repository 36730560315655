import { FC, useRef, useState } from 'react'

import { useAppSelector } from 'hooks/store.hooks'
import { NFT } from 'models/Collateral'
import { Currencies } from 'models/Currency'
import { Table as TableModel } from 'models/Table'
import { RequestStatus } from 'store/helpers'
import { profileSelector } from 'store/slices/profile/profile.slice'
import { convertFromBigNumber } from 'utils/form'

import { ActivityIndicator, Button, Modal, ProtectedElement, Table } from 'components'
import { TileImages } from 'components/tile/components/TileImages'

import { ReactComponent as ExclamationCircleIcon } from 'assets/icons/exclamationCircle.svg'

type DealNowTable = {
  dueDate: string
  principal: string
  apr: number
  duration: number
  repayment: string
  currency: Currencies
}

type DealNowModalProps = {
  apr: number
  collateralName: string
  currency: string
  dueDate: string
  duration: number
  isOpen: boolean
  nfts: NFT[]
  onDealNow: () => void
  onOpenChange: (open: boolean) => void
  principal: string
  repayment: string
}

const ToggleButton = ({ text, onClick }: { text: string; onClick: () => void }) => (
  <button type="button" onClick={onClick} className="font-button-2 text-secondary-30 underline">
    {text}
  </button>
)

export const DealNowModal: FC<DealNowModalProps> = ({
  apr,
  collateralName,
  currency,
  dueDate,
  duration,
  isOpen,
  nfts,
  onDealNow,
  onOpenChange,
  principal,
  repayment,
}) => {
  const parsedPrincipal = convertFromBigNumber(principal, currency as Currencies) as string

  const tableData: TableModel<DealNowTable> = {
    headers: [
      {
        key: 'dueDate',
        header: 'Due date',
        className: 'pl-4',
      },
      {
        key: 'principal',
        header: 'Principal',
      },
      {
        key: 'apr',
        header: 'APR (%)',
      },
      {
        key: 'duration',
        header: 'Duration',
      },
      {
        key: 'repayment',
        header: 'Repayment',
        className: 'bg-secondary-90 text-center text-primary-40',
        type: 'WETH',
      },
    ],
    data: [
      {
        dueDate,
        principal: parsedPrincipal,
        apr,
        duration,
        repayment,
        currency: currency as Currencies,
      },
    ],
  }
  const termsTextRef = useRef<HTMLDivElement>(null)
  const [isTermsTextExpanded, setIsTermsTextExpanded] = useState(false)

  const toggleTermsText = () => setIsTermsTextExpanded(prevState => !prevState)

  const { loadingState } = useAppSelector(profileSelector)

  const renderBottomBar = () => (
    <ProtectedElement showLoader={false}>
      <div className="mt-8 flex items-center justify-end gap-6">
        <Button onClick={() => onOpenChange(false)} variant="secondary">
          Cancel
        </Button>
        <Button onClick={onDealNow} variant="primary">
          {loadingState === RequestStatus.PENDING ? (
            <ActivityIndicator className="w-[100px]" whiteColor />
          ) : (
            'Accept terms'
          )}
        </Button>
      </div>
    </ProtectedElement>
  )

  return (
    <Modal
      title="Deal now"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    >
      <ProtectedElement onConnectClick={() => onOpenChange(false)}>
        <div className="flex gap-6">
          <div>
            <div className="font-header-h6 mb-4">{collateralName}</div>
            <div className="w-[200px]">
              <TileImages nfts={nfts} />
            </div>
          </div>
          <div className="min-w-[502px]">
            <Table
              columns={tableData.headers}
              data={tableData.data}
              headerBoldColsIndexes={[0, 1, 2, 3, 4]}
            />
            <div className="my-6 flex items-center gap-2" ref={termsTextRef}>
              <div>
                <ExclamationCircleIcon className="h-[21px] w-[21px]" />
              </div>

              {isTermsTextExpanded ? (
                <div className="font-body-5 mr-[29px] flex flex-col items-start text-third-40">
                  <div className="mb-5">
                    Accept terms mean that as a lender - you have agreed to the above loan terms.
                    The principal will be lent out from you, and the borrower shall return the
                    payment to you by the due date; If the borrower fails to repay the repayment
                    amount. You have the right to claim {collateralName} to your wallet.
                  </div>
                  <div>
                    Should you have questions about how we escrow the collateral and to ensure your
                    right, please visit here.
                  </div>
                  <ToggleButton text="Show less" onClick={toggleTermsText} />
                </div>
              ) : (
                <div className="font-body-5 mr-[29px] text-third-40">
                  Accept terms mean that as a lender - you have agreed to the above loan terms. The
                  principal will be lent out from you, and the borrower...
                  <ToggleButton text="Show more" onClick={toggleTermsText} />
                </div>
              )}
            </div>
            <div className="font-body-4">
              If you proceed with “Accept terms”, we will activate the transfer action on MetaMask.
            </div>
          </div>
        </div>
      </ProtectedElement>
    </Modal>
  )
}
