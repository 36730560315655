import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { Link } from 'components'

type MarkStyle = {
  left: number
  width: number
}

export const ProfileTabs = () => {
  const [markStyle, setMarkStyle] = useState<MarkStyle>({ left: 0, width: 0 })
  const location = useLocation()

  useEffect(() => {
    const el = document.getElementById(location.pathname)
    setMarkStyle({
      left: el?.offsetLeft || 0,
      width: el?.offsetWidth || 0,
    })
  }, [location.pathname])

  return (
    <div className="relative mb-2 flex gap-6 border-b border-third-70 pb-2">
      <Link id="/profile/loans" to="/profile/loans" internal className="font-body-2 !text-third-10">
        My loans
      </Link>
      <Link
        id="/profile/collaterals"
        to="/profile/collaterals"
        internal
        className="font-body-2 !text-third-10"
      >
        My collaterals
      </Link>
      <Link
        id="/profile/offers-made"
        to="/profile/offers-made"
        internal
        className="font-body-2 !text-third-10"
      >
        Offers made
      </Link>
      <Link
        id="/profile/offers-received"
        to="/profile/offers-received"
        internal
        className="font-body-2 !text-third-10"
      >
        Offers received
      </Link>
      <div
        id="stripe"
        className="absolute left-0 bottom-0 h-0.5 bg-primary-50 transition-all"
        style={markStyle}
      />
    </div>
  )
}
