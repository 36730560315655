import { FC, useEffect, useState } from 'react'

import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'

import { Currencies } from 'models/Currency'

import { Dropdown } from 'components/form/dropdown'

import { ReactComponent as Check } from 'assets/icons/check.svg'

interface FilterCurrencyProps {
  onChange: (value: Currencies) => void
  defaultValue: Currencies
  disabled?: boolean
  isAllVisible?: boolean
  hint?: string
  value?: Currencies
}

export const FilterCurrency: FC<FilterCurrencyProps> = ({
  onChange,
  defaultValue,
  disabled,
  isAllVisible = true,
  hint,
  value,
}) => {
  const [filterValue, setFilterValue] = useState(value || defaultValue)

  useEffect(() => {
    setFilterValue(value || defaultValue)
  }, [value])

  const onSelect = (currency: Currencies) => {
    onChange(currency)
    setFilterValue(currency)
  }

  let currencies = Object.values(Currencies)

  if (!isAllVisible) currencies = currencies.filter(currency => currency !== Currencies.ALL)

  return (
    <Dropdown title={filterValue} label="Currency" hint={hint} disabled={disabled}>
      <div className="flex flex-col">
        {currencies.map(currency => (
          <DropdownMenuItem key={currency} onClick={() => onSelect(currency)}>
            <div className="group flex cursor-pointer items-center justify-between">
              <div className="font-body-3 py-2.5 pr-5">{currency}</div>
              <Check className={`${filterValue !== currency && 'invisible'} group-hover:visible`} />
            </div>
          </DropdownMenuItem>
        ))}
      </div>
    </Dropdown>
  )
}
