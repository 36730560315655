import { FC } from 'react'

import { Button, Modal } from 'components'

type ProcessInfoModalProps = {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  onPrimaryButtonClick: () => void
  type: 'makeOfferCollateral' | 'makeOfferLoan' | 'termsUpdate'
}

const modalMessages = {
  makeOfferLoan: (
    <div className="font-body-3 max-w-[677px]">
      <div>
        You may send a loan term offer to active loans. Meaning you as a lender propose a loan
        renewal to the borrower. If the borrower agrees, the current loan will be closed, and a new
        loan with the terms you proposed will activate.
      </div>
      <div className="my-6">
        Please note that means:
        <ol>
          <li>
            The collateral of the current loan remains in escrow status, and if the borrower
            defaults, you as the new lender will have the right to claim the collateral from the
            Vault.
          </li>
          <li>You have to pay for the principal of this new loan.</li>
        </ol>
      </div>
      <div>
        Please check the{' '}
        <a
          href="https://app.gitbook.com/o/t2BPUFR3qKdyCgG5SSQA/s/1r4B4h5IQ5CVvClPLxwa/making-offers-accepting-loan-renewal"
          target="_blank"
          rel="noreferrer"
          className="text-secondary-50 underline"
        >
          Make an offer to active loans
        </a>{' '}
        page if you have any questions before proceeding.
      </div>
    </div>
  ),
  makeOfferCollateral: (
    <div className="font-body-3 max-w-[677px]">
      <div>
        You can make multiple offers to collaterals in the marketplace. If the borrower accepts your
        offer, then the loan will become activated.
      </div>
      <div className="my-6">
        Please note that means:
        <ol>
          <li>
            The collateral is in escrow status, and if the borrower defaults (fails to repay the
            loan principal + interest fee to you by the due date). You will have the right to claim
            the collateral from the Vault (My profile -{'>'} My loans -{'>'} The overdue Loan Detail
            -{'>'} Claim NFTs).
          </li>
          <li>The principal amount of the loan will be deducted from your wallet.</li>
        </ol>
      </div>
    </div>
  ),
  termsUpdate: (
    <div className="font-body-3 max-w-[677px]">
      <div>
        You may send a loan term update request to your current lender. Meaning you are proposing a
        loan renewal. If the lender agrees, your current loan will instantly be closed, and a new
        loan will activate.
      </div>
      <div className="my-6">
        Please note that means:
        <ol>
          <li>Your collateral remains in escrow status.</li>
          <li>Loan terms will be updated.</li>
          <li>
            You will have to pay the interest fees of the current loan and the difference amount(
            between the new and current loan).
          </li>
          <li>
            Your wallet needs efficient funds to proceed (current(principal+interest fee)-new
            principal).
          </li>
        </ol>
      </div>
      <div>
        Please check the{' '}
        <a
          href="https://app.gitbook.com/o/t2BPUFR3qKdyCgG5SSQA/s/hkos78OjXaevM2jULE2j/manage-your-loan-loan-repayment-loan-renewal"
          target="_blank"
          rel="noreferrer"
          className="text-secondary-50 underline"
        >
          Request terms update
        </a>{' '}
        page if you have any questions before proceeding.
      </div>
    </div>
  ),
}

export const ProcessInfoModal: FC<ProcessInfoModalProps> = ({
  isOpen,
  onOpenChange,
  onPrimaryButtonClick,
  type,
}) => {
  const renderBottomBar = () => (
    <div className="mt-8 flex items-center justify-end gap-6">
      <Button onClick={onPrimaryButtonClick} variant="primary">
        Got it
      </Button>
    </div>
  )

  return (
    <Modal
      title={type.includes('makeOffer') ? 'Make an offer' : 'Terms update'}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    >
      {modalMessages[type]}
    </Modal>
  )
}
