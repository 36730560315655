import { useWallet } from 'hooks/useWallet'
import { shortAddress } from 'utils/address'
import { getBetaTokens } from 'utils/api/blockchain'

import { Button } from 'components'

import { useAppDispatch } from '../../hooks/store.hooks'
import { StoreModalType } from '../../models/Comm'
import { pushModal } from '../../store/slices/comm/comm.actions'

export const BetaScreen = () => {
  const { address, connectWallet, isLoading, isConnected } = useWallet()
  const dispatch = useAppDispatch()

  const collectMockedTokens = async () => {
    const getBetaTokensResult = await getBetaTokens()
    const getBetaTokensReceipt = await getBetaTokensResult.wait()
    if (getBetaTokensReceipt.status !== 1) {
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
    }
  }

  const addTokensToWallet = async () => {
    const { ethereum } = window
    if (ethereum) {
      // Gold Token
      await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: '0xBFCE40eA0Fe91432197Bdfd663bb4c428Bb74927',
            decimals: 18,
            symbol: 'GLD',
          },
        },
      })
    }
  }

  return (
    <div className="container flex flex-col items-center justify-center py-20">
      <h1 className="mb-4 text-center">Zexus Testing Env</h1>
      <h4 className="mb-4 text-center">Collect mocked tokens for testing purposes.</h4>
      <Button
        className="w-full max-w-[260px]"
        onClick={() => window.open('https://mumbaifaucet.com/', '_blank')}
      >
        Collect Matic From Faucet
      </Button>
      <Button
        className="mt-4 w-full max-w-[260px]"
        disabled={isConnected || isLoading}
        onClick={connectWallet}
      >
        {isConnected ? shortAddress(address) : 'Connect Wallet'}
      </Button>
      <Button
        className="mt-4 w-full max-w-[260px]"
        onClick={collectMockedTokens}
        variant="secondary"
      >
        Collect Mocked Tokens
      </Button>
      <Button className="mt-4 w-full max-w-[260px]" onClick={addTokensToWallet} variant="secondary">
        Add Tokens To Wallet
      </Button>
    </div>
  )
}
