import { useEffect, useRef } from 'react'

import _ from 'lodash'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useIntersectingObserver } from 'hooks/useIntersectingObserver'
import { LoansFilter } from 'models/Filter'
import { getLoansList, resetLoansList } from 'store/slices/loans/loans.actions'
import { loansStateSelector } from 'store/slices/loans/loans.slice'

import { LoansView } from './LoansView'

const PER_PAGE = 20

export const LoansScreen = () => {
  const filters = useRef<LoansFilter>()
  const currentPage = useRef<number>(0)
  const dispatch = useAppDispatch()
  const { loans, count } = useAppSelector(loansStateSelector)
  const abortController = useRef<any>()

  const getLoans = () => {
    abortController.current = dispatch(
      getLoansList({
        limit: PER_PAGE,
        offset: PER_PAGE * currentPage.current,
        filters: filters.current,
      })
    )
  }

  const onScroll = () => {
    if (loans.length < count) {
      currentPage.current += 1
      getLoans()
    }
  }

  const { elementRef: scrollRef } = useIntersectingObserver(onScroll)

  const handleFiltersChange = (value: LoansFilter) => {
    if (_.isEqual(value, filters.current)) return
    filters.current = value
    currentPage.current = 0
    abortController.current?.abort()
    dispatch(resetLoansList())
    getLoans()
  }

  useEffect(
    () => () => {
      filters.current = undefined
      currentPage.current = 0
      abortController.current?.abort()
      dispatch(resetLoansList())
    },
    []
  )

  return <LoansView ref={scrollRef} loans={loans} onFiltersChange={handleFiltersChange} />
}
