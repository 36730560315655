import { FC, ReactNode, useEffect } from 'react'

import { useAppSelector } from 'hooks/store.hooks'
import { Table as TableType } from 'models/Table'
import { Terms } from 'models/Terms'
import { RequestStatus } from 'store/helpers'
import { profileSelector } from 'store/slices/profile/profile.slice'
import { convertFromBigNumber } from 'utils/form'
import { roundUnknownTypeDigits } from 'utils/number'

import { ActivityIndicator, Button, Table } from 'components'

type OfferTable = {
  name?: string | ReactNode
  currentTerms: string | ReactNode
  offerTerms: string | ReactNode
}

interface OfferSummaryProps {
  isFormValid?: boolean
  isTermsUpdate?: boolean
  onSendOffer: () => void
  offerTerms: Terms
  onCancel: () => void
  originalTerms: Terms
}

export const OfferSummary: FC<OfferSummaryProps> = ({
  isFormValid = true,
  isTermsUpdate,
  offerTerms,
  onCancel,
  originalTerms,
  onSendOffer,
}) => {
  const parsedOriginalPrincipal = convertFromBigNumber(
    originalTerms.principal,
    originalTerms.currency
  )
  const parsedOriginalRepayment = convertFromBigNumber(
    originalTerms.repayment,
    originalTerms.currency
  )

  const offerTable: TableType<OfferTable> = {
    headers: [
      {
        key: 'name',
        header: '',
        className: 'pr-4 py-3 border-0 border-r border-third-70 w-fit',
      },
      {
        key: 'currentTerms',
        header: 'Current terms',
        className: `${isTermsUpdate && 'bg-secondary-90'} px-1.5 py-3`,
      },
      {
        key: 'offerTerms',
        header: !isTermsUpdate ? 'Your Offer' : 'Update',
        className: `${!isTermsUpdate && 'bg-secondary-90'} px-1.5 py-3 text-primary-40`,
      },
    ],
    data: [
      {
        name: 'Principal',
        currentTerms: (
          <div
            className="flex min-w-0 gap-1"
            title={`${parsedOriginalPrincipal} ${originalTerms.currency}`}
          >
            <div className="truncate">{roundUnknownTypeDigits(parsedOriginalPrincipal, 4)}</div>
            <div>{originalTerms.currency}</div>
          </div>
        ),
        offerTerms: (
          <div
            className="flex min-w-0 gap-1"
            title={`${offerTerms.principal} ${offerTerms.currency}`}
          >
            <div className="truncate">{roundUnknownTypeDigits(offerTerms.principal, 4)}</div>
            <div>{offerTerms.currency}</div>
          </div>
        ),
      },
      {
        name: 'Repayment',
        currentTerms: (
          <div
            className="flex min-w-0 gap-1"
            title={`${parsedOriginalRepayment} ${originalTerms.currency}`}
          >
            <div className="truncate">{roundUnknownTypeDigits(parsedOriginalRepayment, 4)}</div>
            <div>{originalTerms.currency}</div>
          </div>
        ),
        offerTerms: (
          <div
            className="flex min-w-0 gap-1"
            title={`${offerTerms.repayment} ${offerTerms.currency}`}
          >
            <div className="truncate">{roundUnknownTypeDigits(offerTerms.repayment, 4)}</div>
            <div>{offerTerms.currency}</div>
          </div>
        ),
      },
      {
        name: 'APR',
        currentTerms: `${originalTerms.apr ?? 0}%`,
        offerTerms: `${offerTerms.apr ?? 0}%`,
      },
      {
        name: 'Duration',
        currentTerms: `${originalTerms.duration ?? 0} days`,
        offerTerms: `${offerTerms.duration ?? 0} days`,
      },
    ],
  }

  const isSendOfferButtonDisabled =
    !(offerTerms.principal && offerTerms.apr && offerTerms.duration) || !isFormValid

  const { loadingState } = useAppSelector(profileSelector)

  useEffect(() => {}, [loadingState])

  return (
    <div className="mt-8 h-min rounded-lg bg-third-80 py-8 px-6 text-third-10 lg:mt-0">
      <h4 className="mb-[6px]">Offer Summary</h4>
      <Table columns={offerTable.headers} data={offerTable.data} bodyBoldColsIndexes={[0]} />

      <div className="mt-8 flex flex-col gap-4">
        {loadingState === RequestStatus.PENDING ? (
          <Button onClick={onSendOffer} disabled className="w-full">
            <ActivityIndicator whiteColor />
          </Button>
        ) : (
          <Button onClick={onSendOffer} disabled={isSendOfferButtonDisabled} className="w-full">
            {isTermsUpdate ? 'Send terms update request' : 'Send an offer'}
          </Button>
        )}
        <Button onClick={onCancel} className="w-full" variant="secondary">
          Cancel
        </Button>
      </div>
    </div>
  )
}
