import { FC } from 'react'

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'

type AddCollectionTileProps = {
  onClick: () => void
}

export const AddCollectionTile: FC<AddCollectionTileProps> = props => {
  const { onClick } = props

  return (
    <button
      onClick={onClick}
      type="button"
      className="font-button-2 flex h-full w-full flex-col items-center justify-center gap-6 text-primary-40"
    >
      <PlusIcon className="h-10 w-10 stroke-primary-40" />
      Request to add collection
    </button>
  )
}
