import { FC } from 'react'

import { NFT } from 'models/NFT'
import { SortItem } from 'models/Sort'
import { Terms } from 'models/Terms'

import { Button, ButtonBack } from 'components'

import { AddCollateralConfirmationModal } from './components/AddCollateralConfirmationModal'
import { NftSelector } from './components/NftSelector'
import { Preview } from './components/Preview'
import { Stages } from './components/Stages'
import { Summary } from './components/Summary'
import { TermsForm } from './components/TermsForm'

interface AddCollateralViewProps {
  isAddCollateralConfirmationModalOpen: boolean
  isButtonDisabled: boolean
  nfts: NFT[]
  onAddCollateralModalOpenChange: (open: boolean) => void
  onCancel: () => void
  onGoToMarketplace: () => void
  onGoToProfile: () => void
  onItemDelete: (nft: NFT) => void
  onNameChange: (name: string) => void
  onSortMethodChange: (sortMethod: SortItem) => void
  onSubmit: () => void
  selectedNfts: NFT[]
  setStage: (stage: number) => void
  setSelectedNfts: (nfts: NFT[]) => void
  onTermsChange: (terms: Partial<Terms>) => void
  stage: number
  terms: Terms
}

export const AddCollateralView: FC<AddCollateralViewProps> = ({
  isAddCollateralConfirmationModalOpen,
  isButtonDisabled,
  nfts,
  onAddCollateralModalOpenChange,
  onCancel,
  onGoToMarketplace,
  onGoToProfile,
  onItemDelete,
  onNameChange,
  onSortMethodChange,
  onSubmit,
  setStage,
  selectedNfts,
  setSelectedNfts,
  onTermsChange,
  stage,
  terms,
}) => {
  const getButtonText = (): string => {
    if (stage === 1) return 'Go to loan terms'
    if (stage === 2) return 'Set desired loan terms'
    return ''
  }

  const onPrimaryButtonClick = () => {
    if (stage < 3) setStage(stage + 1)
    else onSubmit()
  }

  return (
    <>
      <div className="container py-10 lg:max-w-[1056px]">
        <div className="mb-7">
          {stage === 1 && <ButtonBack text="Back to My Collaterals" path="/collaterals" />}
          {stage === 2 && (
            <ButtonBack text="Back to Choose NFTs for collateral" callback={() => setStage(1)} />
          )}
          {stage === 3 && <ButtonBack text="Back to loan terms" callback={() => setStage(2)} />}

          <Stages currentStage={stage} />
        </div>
        <div className={`gap-8 ${stage !== 3 && 'grid lg:grid-cols-detailsContainer'}`}>
          {stage === 1 && (
            <NftSelector
              nfts={nfts}
              onSortMethodChange={onSortMethodChange}
              selectedNfts={selectedNfts}
              setSelectedNfts={setSelectedNfts}
            />
          )}
          {stage === 2 && <TermsForm terms={terms} onTermsChange={onTermsChange} />}
          {stage === 3 && (
            <Preview
              nfts={selectedNfts}
              onCancel={onCancel}
              onNameChange={onNameChange}
              onSubmit={onSubmit}
              terms={terms}
            />
          )}
          {stage !== 3 && (
            <Summary nfts={selectedNfts} onItemDelete={onItemDelete}>
              <div className="flex flex-col gap-4">
                <Button
                  onClick={onPrimaryButtonClick}
                  className="w-full"
                  disabled={isButtonDisabled}
                >
                  {getButtonText()}
                </Button>
                <Button onClick={onCancel} className="w-full" variant="secondary">
                  Cancel
                </Button>
              </div>
            </Summary>
          )}
        </div>
      </div>
      <AddCollateralConfirmationModal
        isOpen={isAddCollateralConfirmationModalOpen}
        onOpenChange={onAddCollateralModalOpenChange}
        onPrimaryButtonClick={onGoToMarketplace}
        onSecondaryButtonClick={onGoToProfile}
      />
    </>
  )
}
