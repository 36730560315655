import { FC, useState } from 'react'

import dayjs from 'dayjs'
import Avatar, { jsNumberForAddress } from 'react-jazzicon'
import { To } from 'react-router-dom'

import { Notification, NotificationReferenceType, NotificationType } from 'models/Notification'
import { shortAddress } from 'utils/address'

import { Badge } from 'components/badge'

import { NotificationItemWrapper } from './NotificationItemWrapper'

type NavigationNotificationItemProps = {
  item: Notification
  onSeen: (id: number) => void
}

export const NavigationNotificationItem: FC<NavigationNotificationItemProps> = props => {
  const { item, onSeen } = props

  const [isNew, setIsNew] = useState(true)

  const handleHover = () => {
    if (isNew) {
      onSeen(item.id)
      setIsNew(false)
    }
  }

  const getTo = (): To | undefined => {
    switch (item.notificationType) {
      case NotificationType.OFFER_RECEIVED:
      case NotificationType.LOAN_OFFER_RECEIVED:
      case NotificationType.COLLATERAL_OFFER_RECEIVED:
      case NotificationType.EXTENSION_REQUEST:
        return '/profile/offers-received'
      case NotificationType.DEAL_NOW:
      case NotificationType.OFFER_ACCEPTED:
      case NotificationType.COLLATERAL_OFFER_ACCEPTED:
      case NotificationType.LOAN_OFFER_ACCEPTED:
      case NotificationType.EXTENSION_REJECTED:
        switch (item.reference.referenceType) {
          case NotificationReferenceType.OFFER:
          case NotificationReferenceType.LOAN:
            return `/loans/${item.reference.objectId}`
          default:
            return undefined
        }
      case NotificationType.DEFAULTED:
        return '/profile/loans'
      case NotificationType.DUE_DATE:
      case NotificationType.PAST_DUE_DATE:
      case NotificationType.EXTENSION_ACCEPTED:
        return `/loans/${item.reference.objectId}`
      case NotificationType.COLLATERAL_REJECTED:
        return `/collaterals/${item.reference.objectId}`
      case NotificationType.LOAN_REJECTED:
        return `/loans/${item.reference.objectId}`
      default:
        return undefined
    }
  }

  const getMessage = () => {
    switch (item.notificationType) {
      case NotificationType.REPAIED:
      case NotificationType.REPAID:
        return `${item.reference.objectName} has been successfully paid back.`
      case NotificationType.OFFER_RECEIVED:
      case NotificationType.LOAN_OFFER_RECEIVED:
      case NotificationType.COLLATERAL_OFFER_RECEIVED:
        return `User ${shortAddress(item.reference.referenceUser)} sent you an offer.`
      case NotificationType.OFFER_ACCEPTED:
      case NotificationType.COLLATERAL_OFFER_ACCEPTED:
      case NotificationType.LOAN_OFFER_ACCEPTED:
        return `User ${shortAddress(item.reference.referenceUser)} accepted your offer.`
      case NotificationType.DEAL_NOW:
        return `Your loan ${item.reference.objectName} has been activated.`
      case NotificationType.DEFAULTED:
        return `Due to default status of ${item.reference.objectName}, you no longer have option to repay the loan.`
      case NotificationType.DUE_DATE:
        return `${item.reference.objectName} due date reminder. Please pay your loan to prevent loan default.`
      case NotificationType.PAST_DUE_DATE:
        return `${item.reference.objectName} is closed and due to the borrower (user ${shortAddress(
          item.reference.referenceUser
        )}) hasn't repaid by due, you have the right to claim ${item.reference.objectName}`
      case NotificationType.EXTENSION_ACCEPTED:
        return `Success! Your new loan terms request for ${
          item.reference.objectName
        } has been accepted by lender(user ${shortAddress(item.reference.referenceUser)})`
      case NotificationType.EXTENSION_REQUEST:
        return `The borrower has requested a loan terms update.`
      case NotificationType.OFFER_DELETED:
      case NotificationType.COLLATERAL_REJECTED:
      case NotificationType.COLLATERAL_OFFER_REJECTED:
        return `Rejected! Your new offer request for ${
          item.reference.objectName
        } has been rejected by borrower(user ${shortAddress(item.reference.referenceUser)})`
      case NotificationType.LOAN_REJECTED:
      case NotificationType.LOAN_OFFER_REJECTED:
      case NotificationType.EXTENSION_REJECTED:
        return `Rejected! Your new loan terms request for ${
          item.reference.objectName
        } has been rejected by user ${shortAddress(item.reference.referenceUser)}`
      case NotificationType.COLLATERAL_OFFER_CANCELED:
        return `User ${shortAddress(
          item.reference.referenceUser
        )} has cancelled an offer for your collateral`
      case NotificationType.LOAN_OFFER_CANCELED:
      case NotificationType.EXTENSION_CANCELED:
        return `User ${shortAddress(
          item.reference.referenceUser
        )} has cancelled an offer for your loan`
      default:
        return item.reference.referenceMessage
    }
  }

  const getTime = () => {
    const now = dayjs()
    let dateDiff = now.diff(item.createdAt, 'day')
    let dateDiffDesc = dateDiff < 2 ? 'day' : 'days'
    if (dateDiff === 0) {
      dateDiff = now.diff(item.createdAt, 'hour')
      dateDiffDesc = dateDiff < 2 ? 'hour' : 'hours'
    }
    if (dateDiff === 0) {
      dateDiff = now.diff(item.createdAt, 'minute')
      dateDiffDesc = dateDiff < 2 ? 'minute' : 'minutes'
    }

    return `${dateDiff} ${dateDiffDesc} ago`
  }

  return (
    <NotificationItemWrapper
      to={getTo()}
      className="relative flex items-start gap-4 rounded-lg px-3 pb-1 pt-3 hover:bg-third-80"
      onHover={handleHover}
    >
      <Badge
        position="inline"
        className={`absolute top-7 left-1 transition-opacity duration-500 ${
          isNew ? 'opacity-100' : 'opacity-0'
        }`}
      />
      {item?.reference?.referenceUser && (
        <Avatar
          diameter={36}
          seed={jsNumberForAddress(item.reference.referenceUser as string)}
          paperStyles={{ flex: 'none' }}
        />
      )}
      <div>
        <div className="font-body-4 font-semibold text-third-10">
          {getMessage()}
          {item.reference.referenceMessage && (
            <div className="font-body-5 break-word mt-2 text-third-10">
              &quot;{item.reference.referenceMessage}&quot;
            </div>
          )}
        </div>
        <div className="font-body-4 mt-2 text-third-500">{getTime()}</div>
      </div>
    </NotificationItemWrapper>
  )
}
