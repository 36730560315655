import { FC } from 'react'

import { Status } from 'models/Style'
import { UserType } from 'models/User'

interface ChipProps {
  type: Status | UserType
  label?: string
}

export const Chip: FC<ChipProps> = ({ type, label }) => {
  const getBgColor = (): string => {
    switch (type) {
      case 'ACTIVE':
        return 'bg-success-90'
      case 'OVERDUE':
        return 'bg-error-70'
      case 'REPAID':
      case 'DEFAULTED':
      case 'admin':
      case 'borrower':
        return 'bg-neutral-500'
      case 'lender':
      default:
        return ''
    }
  }

  return (
    <span className={`font-overline block rounded-full px-2 py-0.5 text-white ${getBgColor()}`}>
      {label ?? type.toUpperCase()}
    </span>
  )
}
