import { createAsyncThunk } from '@reduxjs/toolkit'

import { Notification } from 'models/Notification'
import { notificationsApi } from 'utils/api'
import { MarkNotificationsVars } from 'utils/api/notifications'

export const getNotifications = createAsyncThunk<Notification[], void>(
  'notifications/getNotifications',
  async () => await notificationsApi.getNotifications()
)

export const markNotifications = createAsyncThunk<void, MarkNotificationsVars>(
  'notifications/markNotifications',
  async (variables, thunkAPI) => {
    await notificationsApi.markNotifications(variables)
    thunkAPI.dispatch(getNotifications())
  }
)
