import { FC } from 'react'

import { Status, TileType } from 'models/Style'
import { UserType } from 'models/User'

import { Checkbox, Chip } from 'components'

import { ReactComponent as BellAlertIcon } from 'assets/icons/bellAlert.svg'
import { ReactComponent as CollectionIcon } from 'assets/icons/collection.svg'

interface TileHeaderProps {
  alert?: boolean
  disabled?: boolean
  isSelected?: boolean
  status?: Status
  subtitle?: string
  tileType?: TileType
  title?: string
  collectionName?: string
  userType?: UserType
}

export const TileHeader: FC<TileHeaderProps> = ({
  alert,
  disabled,
  isSelected,
  title,
  subtitle,
  collectionName,
  status,
  tileType,
  userType,
}) => (
  <div className="flex flex-col justify-center gap-1">
    {collectionName && (
      <div className="flex items-center justify-start">
        <CollectionIcon fill="#C3C2D4" height={9} />
        <div className="font-body-5 ml-2.5 text-third-40">{collectionName}</div>
      </div>
    )}

    {tileType === 'nft' ? (
      <div className="flex gap-[14px] p-[2px]">
        <Checkbox checked={isSelected || !!disabled} disabled={disabled} />
        <div className="font-body-4 truncate font-semibold text-third-20">{title}</div>
      </div>
    ) : (
      <div className="flex h-8 items-center justify-between">
        <div className="font-body-3 truncate font-semibold text-third-20">{title}</div>
        {status && <Chip type={status} />}
      </div>
    )}

    {(subtitle || userType) && (
      <div className="flex h-4 items-center justify-between">
        <div className="font-overline flex items-center truncate text-secondary-20">
          {alert && <BellAlertIcon className="mr-1 h-[14px] w-[14px] fill-error-70" />}
          {subtitle}
        </div>
        {userType && <Chip type={userType} />}
      </div>
    )}
  </div>
)
