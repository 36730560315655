import { FC, useState } from 'react'

import { useAppDispatch } from 'hooks/store.hooks'
import { useWallet } from 'hooks/useWallet'
import { CollateralDetails, NFT } from 'models/Collateral'
import { Currencies } from 'models/Currency'
import { convertFromBigNumber } from 'utils/form'

import {
  ActiveOffers,
  Activities,
  BorrowerInformation,
  Button,
  ButtonBack,
  DescriptionInfo,
} from 'components'
import { TileImages } from 'components/tile/components/TileImages'

import { DealNowConfirmationModal } from './components/DealNowConfirmationModal'
import { DealNowModal } from './components/DealNowModal'
import { DeleteCollateralConfirmModal } from './components/DeleteCollateralConfirmModal'
import { DeleteCollateralModal } from './components/DeleteCollateralModal'
import { ProcessInfoModal } from './components/ProcessInfoModal'
import { setLoadingDeletePending } from '../../store/slices/profile/profile.actions'

interface CollateralDetailsProps {
  collateralDetails: CollateralDetails
  isDealNowConfirmationModalOpen: boolean
  isDealNowModalOpen: boolean
  isDeleteConfirmationModalOpen: boolean
  isMakeOfferInfoModalOpen: boolean
  onDealNow: () => void
  onDelete?: () => void
  onDeleteConfirmationModalOpenChange: (open: boolean) => void
  onGoToLoan: () => void
  onGoToProfile: () => void
  onDealNowConfirmationModalOpenChange: (open: boolean) => void
  onMakeOffer: () => void
  onMakeOfferInfoModalButtonClick: () => void
  onMakeOfferInfoModalOpenChange: (open: boolean) => void
  setIsDealNowModalOpen: (open: boolean) => void
}

export const CollateralDetailView: FC<CollateralDetailsProps> = ({
  collateralDetails,
  isDealNowConfirmationModalOpen,
  isDealNowModalOpen,
  isDeleteConfirmationModalOpen,
  isMakeOfferInfoModalOpen,
  onDealNow,
  onDealNowConfirmationModalOpenChange,
  onDelete,
  onDeleteConfirmationModalOpenChange,
  onGoToLoan,
  onGoToProfile,
  onMakeOffer,
  onMakeOfferInfoModalButtonClick,
  onMakeOfferInfoModalOpenChange,
  setIsDealNowModalOpen,
}) => {
  const {
    activeOffers,
    activities,
    allowDealNow,
    apr,
    borrower,
    borrowerInfo,
    currency,
    duration,
    id,
    name,
    nfts,
    principal,
    repayment,
  } = collateralDetails

  const [highlightedNft, setHighlightedNft] = useState<NFT>(nfts[0])
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const parsedPrincipal = convertFromBigNumber(principal, currency as Currencies) as string
  const parsedRepayment = convertFromBigNumber(repayment, currency as Currencies) as string

  const { address } = useWallet()

  const dispatch = useAppDispatch()

  const isLoggedUserOwner = borrower === address

  const handleDeleteConfirm = () => {
    dispatch(setLoadingDeletePending())
    setIsDeleteModalOpen(false)
    onDelete?.()
  }

  return (
    <>
      <div className="container lg:max-w-[1017px]">
        <div className="mt-6 mb-7">
          <ButtonBack text="Back to marketplace" path="/collaterals" />
        </div>
        <div className="grid gap-x-4 gap-y-0 lg:grid-cols-detailsContainer">
          <div>
            <TileImages
              nfts={nfts}
              onHighlightedNftChange={setHighlightedNft}
              size="large"
              tileImageClassName="rounded-b-none"
            />
          </div>
          <DescriptionInfo
            apr={apr}
            currency={currency}
            duration={duration}
            name={name}
            onDelete={() => setIsDeleteModalOpen(true)}
            ownerAddress={borrower}
            principal={parsedPrincipal}
            repayment={parsedRepayment}
            type="collateral"
          >
            {!isLoggedUserOwner && (
              <div className="flex flex-col gap-4">
                {allowDealNow && (
                  <Button
                    onClick={() => {
                      setIsDealNowModalOpen(true)
                    }}
                    className="w-full"
                  >
                    Deal now
                  </Button>
                )}
                <Button onClick={onMakeOffer} className="w-full" variant="secondary">
                  Make an offer
                </Button>
                <button
                  type="button"
                  className="font-button-small -mb-5 font-normal text-third-40 underline"
                  onClick={() => onMakeOfferInfoModalOpenChange(true)}
                >
                  How &quot;make an offer&quot; works?
                </button>
              </div>
            )}
          </DescriptionInfo>
          <div className="mb-8 flex flex-col gap-8 lg:mb-12">
            <Activities
              activities={activities[Object.keys(activities)[0]]}
              nftCollectionName={highlightedNft.collectionName || highlightedNft.address}
            />
            <BorrowerInformation borrowerInfo={borrowerInfo} />
            <ActiveOffers activeOffers={activeOffers} />
          </div>
        </div>
      </div>
      <DealNowModal
        apr={apr}
        collateralName={name}
        currency={currency}
        dueDate="16 Dec"
        duration={duration}
        isOpen={isDealNowModalOpen}
        nfts={nfts}
        onDealNow={onDealNow}
        onOpenChange={setIsDealNowModalOpen}
        principal={principal}
        repayment={repayment}
      />
      <DealNowConfirmationModal
        borrowerAddress={borrower}
        currency={currency as Currencies}
        isOpen={isDealNowConfirmationModalOpen}
        loanId={id}
        onOpenChange={onDealNowConfirmationModalOpenChange}
        onPrimaryButtonClick={onGoToLoan}
        onSecondaryButtonClick={onGoToProfile}
        principal={principal}
      />
      <ProcessInfoModal
        isOpen={isMakeOfferInfoModalOpen}
        onOpenChange={onMakeOfferInfoModalOpenChange}
        onPrimaryButtonClick={onMakeOfferInfoModalButtonClick}
        type="makeOfferCollateral"
      />
      <DeleteCollateralModal
        isOpen={isDeleteModalOpen}
        onConfirm={handleDeleteConfirm}
        onOpenChange={setIsDeleteModalOpen}
      />
      <DeleteCollateralConfirmModal
        collateralName={name}
        isOpen={isDeleteConfirmationModalOpen}
        onOpenChange={onDeleteConfirmationModalOpenChange}
      />
    </>
  )
}
