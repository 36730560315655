import { FC } from 'react'

import { Button, SuccessModal } from 'components'

type PayBackConfirmationModalProps = {
  collateralName: string
  isOpen: boolean
  loanId: number
  onOpenChange: (open: boolean) => void
  onPrimaryButtonClick: () => void
}

export const PayBackConfirmationModal: FC<PayBackConfirmationModalProps> = ({
  collateralName,
  isOpen,
  loanId,
  onOpenChange,
  onPrimaryButtonClick,
}) => {
  const renderBottomBar = () => (
    <div className="mt-8 flex items-center justify-end gap-6">
      <Button onClick={onPrimaryButtonClick} variant="primary">
        Go back to My Profile
      </Button>
    </div>
  )

  const successModalMessage = (
    <div>
      <div className="font-body-3">You have successfully paid back Loan #{loanId}.</div>
      <div className="font-body-5 mt-5">
        We have transferred your {collateralName} back to your wallet.
      </div>
    </div>
  )

  return (
    <SuccessModal
      title="Confirmation"
      isOpen={isOpen}
      message={successModalMessage}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    />
  )
}
