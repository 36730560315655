import { FC } from 'react'

import * as RadixLabel from '@radix-ui/react-label'

interface LabelProps {
  text: string
  isError?: boolean
  classNames?: string
}

export const Label: FC<LabelProps> = ({ text, isError, classNames }) => (
  <RadixLabel.Root className={`mb-[6px] ${isError ? 'text-red-500' : ''} ${classNames}`}>
    {text}
  </RadixLabel.Root>
)
