import { FC, useState } from 'react'

import { useAppSelector } from 'hooks/store.hooks'
import { NFT } from 'models/Collateral'
import { SortItem } from 'models/Sort'
import { RequestStatus } from 'store/helpers'
import { profileSelector } from 'store/slices/profile/profile.slice'

import { ActivityIndicator, Sort, Tile } from 'components'

import { AddCollectionTile } from './AddCollectionTile'
import { WhitelistNftModal } from './WhitelistNftModal'

interface NftSelectorProps {
  nfts?: NFT[]
  onSortMethodChange: (sortMethod: SortItem) => void
  selectedNfts: NFT[]
  setSelectedNfts: (state: NFT[]) => void
}

export const DEFAULT_SORT_METHOD = 'valuation'
const SORT_ITEMS: Array<SortItem> = [
  'valuation',
  '-valuation',
  'collection_name',
  '-collection_name',
]

export const NftSelector: FC<NftSelectorProps> = ({
  nfts,
  onSortMethodChange,
  selectedNfts,
  setSelectedNfts,
}) => {
  const [sortMethod, setSortMethod] = useState<SortItem>(DEFAULT_SORT_METHOD)
  const [isWhitelistModalVisible, setIsWhitelistModalVisible] = useState(false)
  const { getOwnedNftsStatus } = useAppSelector(profileSelector)

  const onSortChange = (method: SortItem) => {
    setSortMethod(method)
    onSortMethodChange(method)
  }

  const handleNftClick = (nft: NFT) => {
    if (!selectedNfts?.some(el => el.address === nft.address && el.tokenId === nft.tokenId)) {
      if (!nft.isWhitelisted) return
      setSelectedNfts([...selectedNfts, nft])
    } else {
      setSelectedNfts(
        selectedNfts.filter(el => !(el.address === nft.address && el.tokenId === nft.tokenId))
      )
    }
  }

  return (
    <>
      <div className="rounded border border-third-70 bg-box-background px-4 py-8">
        <div className="flex items-end justify-between gap-14">
          <h4 className="mb-6 max-w-[352px]">Choose NFT for collateral from your wallet</h4>
          <div className="w-[158px]">
            <Sort items={SORT_ITEMS} defaultValue={sortMethod} onChange={onSortChange} />
          </div>
        </div>
        {!!nfts?.length && (
          <>
            <div className="font-body-4 mb-8">
              Couldn’t find your NFT collection?{' '}
              <button
                type="button"
                className="font-button-2 text-primary-40"
                onClick={() => setIsWhitelistModalVisible(true)}
              >
                Request to add
              </button>
            </div>
            <div className="flex flex-wrap justify-center gap-x-4 gap-y-8 lg:justify-start">
              {nfts.map(nft => {
                if (!nft.isWhitelisted) return null
                const key = `nft-${nft.address}-${nft.tokenId}`
                return (
                  <button type="button" onClick={() => handleNftClick(nft)} key={key}>
                    <Tile
                      type="nft"
                      nfts={[nft]}
                      isSelected={selectedNfts.some(
                        el => el.address === nft.address && el.tokenId === nft.tokenId
                      )}
                      disabled={nft.isUsed}
                    />
                  </button>
                )
              })}
              <div className="flex h-[377px] w-[274px] items-center justify-center rounded border border-dashed border-primary-40">
                <AddCollectionTile onClick={() => setIsWhitelistModalVisible(true)} />
              </div>
            </div>
          </>
        )}
        {getOwnedNftsStatus === RequestStatus.PENDING && (
          <div className="flex h-[385px] w-full items-center justify-center">
            <ActivityIndicator />
          </div>
        )}
        {!nfts?.length && getOwnedNftsStatus !== RequestStatus.PENDING && (
          <>
            <div className="font-body-4 mb-8">You don’t have any whitelisted NFT.</div>
            <div className="flex h-[302px] items-center justify-center rounded border border-dashed border-primary-40">
              <AddCollectionTile onClick={() => setIsWhitelistModalVisible(true)} />
            </div>
          </>
        )}
      </div>
      <WhitelistNftModal
        nftList={nfts || []}
        isOpen={isWhitelistModalVisible}
        onOpenChange={setIsWhitelistModalVisible}
      />
    </>
  )
}
