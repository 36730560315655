import { Address } from 'wagmi'

import { FloorPriceList, ValuationList } from 'models/NFT'
import { API_URLS } from 'utils/constants'

import API from './api'

export const whitelistNft = async (address: string): Promise<void> => {
  await API.post(`${API_URLS.ZEXUS_API}/collaterals/add-to-whitelist/`, {
    address,
  })
}

export type GetPriceValuationVarsItem = {
  address: Address
  token_id: string
}

export type GetPriceValuationData = {
  floorPrices: FloorPriceList
  valuation: ValuationList
}

export const getPriceValuation = async (
  variables: GetPriceValuationVarsItem[]
): Promise<GetPriceValuationData> => {
  const { data } = await API.post<GetPriceValuationData>(`${API_URLS.ZEXUS_API}/prices/`, variables)
  return data
}
