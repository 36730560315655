import { FC, PropsWithChildren, ReactNode } from 'react'

import * as Dialog from '@radix-ui/react-dialog'

import { getButtonClasses } from 'components/buttons'

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'

export type ModalProps = PropsWithChildren & {
  isOpen: boolean
  onCancel?: () => void
  onConfirm?: () => void
  onOpenChange: (open: boolean) => void
  renderBottomBar?: null | (() => ReactNode)
  title?: string
  icon?: ReactNode
}

const primaryButtonClasses = getButtonClasses('small', 'primary', 'default', true, false)
const secondaryButtonClasses = getButtonClasses('small', 'secondary', 'default', true, false)

export const Modal: FC<ModalProps> = props => {
  const { children, icon, isOpen, onCancel, onConfirm, onOpenChange, renderBottomBar, title } =
    props

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-40 backdrop-blur">
          <Dialog.Content className="fixed left-2/4 top-2/4 z-[41] max-h-[calc(100vh-32px)] min-w-[240px] max-w-[calc(100vw-32px)] -translate-x-2/4 -translate-y-2/4 overflow-y-auto rounded-lg border-2 border-third-70 bg-third-95 p-6 sm:min-w-[320px]">
            <Dialog.Title asChild className="mb-8 flex text-secondary-10">
              <div>
                <div className="font-header-h4 mr-3">{title}</div>
                {icon && <div className="mt-2 mr-3 h-7 w-7">{icon}</div>}
                <Dialog.Close asChild>
                  <CloseIcon className="mt-3 ml-auto cursor-pointer fill-third-500" />
                </Dialog.Close>
              </div>
            </Dialog.Title>
            <div className="font-body-3 text-third-10">{children}</div>
            <div className="mt-8 flex items-center justify-end gap-6">
              {renderBottomBar !== undefined ? (
                renderBottomBar?.()
              ) : (
                <>
                  {onCancel && (
                    <Dialog.Close onClick={onCancel} className={secondaryButtonClasses}>
                      Cancel
                    </Dialog.Close>
                  )}
                  <Dialog.Close onClick={onConfirm} className={primaryButtonClasses}>
                    Ok
                  </Dialog.Close>
                </>
              )}
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
