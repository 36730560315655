import { Address } from 'wagmi'

export enum NotificationType {
  COLLATERAL_REJECTED = 'COLLATERAL_REJECTED',
  COLLATERAL_OFFER_REJECTED = 'COLLATERAL_OFFER_REJECTED',
  DEAL_NOW = 'DEAL_NOW',
  DEFAULTED = 'DEFAULTED',
  DUE_DATE = 'DUE_DATE',
  EXTENSION_ACCEPTED = 'EXTENSION_ACCEPTED',
  EXTENSION_REJECTED = 'EXTENSION_REJECTED',
  EXTENSION_REQUEST = 'EXTENSION_REQUEST',
  LOAN_REJECTED = 'LOAN_REJECTED',
  LOAN_OFFER_REJECTED = 'LOAN_OFFER_REJECTED',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  COLLATERAL_OFFER_ACCEPTED = 'COLLATERAL_OFFER_ACCEPTED',
  LOAN_OFFER_ACCEPTED = 'LOAN_OFFER_ACCEPTED',
  OFFER_DELETED = 'OFFER_DELETED',
  OFFER_RECEIVED = 'OFFER_RECEIVED',
  LOAN_OFFER_RECEIVED = 'LOAN_OFFER_RECEIVED',
  COLLATERAL_OFFER_RECEIVED = 'COLLATERAL_OFFER_RECEIVED',
  PAST_DUE_DATE = 'PAST_DUE_DATE',
  REPAIED = 'REPAIED',
  REPAID = 'REPAID',
  LOAN_OFFER_CANCELED = 'LOAN_OFFER_CANCELED',
  COLLATERAL_OFFER_CANCELED = 'COLLATERAL_OFFER_CANCELED',
  EXTENSION_CANCELED = 'EXTENSION_CANCELED',
}

export enum NotificationReferenceType {
  LOAN = 'LOAN',
  OFFER = 'OFFER',
  TERM = 'TERM',
  USER = 'USER',
}

type NotificationReference = {
  objectId?: number
  objectName?: string
  referenceMessage?: string
  referenceType?: NotificationReferenceType
  referenceUser?: Address
}

type Notification = {
  address: Address
  createdAt: string
  id: number
  isRead: boolean
  message: string
  notificationType: NotificationType
  reference: NotificationReference
}

export type { Notification, NotificationReference }
