import { Chain } from '@wagmi/core'
import { Address } from 'wagmi'
import { mainnet, polygonMumbai } from 'wagmi/chains'

type ApiUrls = {
  ZEXUS_API: string | undefined
  ZEXUS_BLOCKCHAIN: string
}

type Addresses = {
  ZEXUS_BORROWER: Address | undefined
  ZEXUS_LENDER: Address | undefined
  ZEXUS_COLLATERAL: Address | undefined
  ZEXUS_STORAGE: Address | undefined
  ZEXUS_UTILS: Address | undefined
  ZEXUS_VAULT: Address | undefined
  ZEXUS_BETA: Address | undefined
  USDC: Address | undefined
  USDT: Address | undefined
  WETH: Address | undefined
  NFT_MOCK: Address | undefined
  NFT_SHAPE_MOCK: Address | undefined
}

export const IS_DEV = process.env.REACT_APP_ENV === 'dev'
export const IS_BETA = process.env.REACT_APP_ENV === 'beta'
export const IS_PROD = process.env.REACT_APP_ENV === 'prod'
export const IS_TEST = process.env.NODE_ENV === 'test' || process.env.REACT_APP_ENV === 'test'

export const API_URLS: ApiUrls = {
  ZEXUS_API: process.env.REACT_APP_ZEXUS_API,
  ZEXUS_BLOCKCHAIN: 'https://blockchain.dev.zexus.finance',
}

export const ADDRESSES: Addresses = {
  ZEXUS_BORROWER: process.env.REACT_APP_ADDRESSES_ZEXUS_BORROWER as Address,
  ZEXUS_LENDER: process.env.REACT_APP_ADDRESSES_ZEXUS_LENDER as Address,
  ZEXUS_COLLATERAL: process.env.REACT_APP_ADDRESSES_ZEXUS_COLLATERAL as Address,
  ZEXUS_STORAGE: process.env.REACT_APP_ADDRESSES_ZEXUS_STORAGE as Address,
  ZEXUS_UTILS: process.env.REACT_APP_ADDRESSES_ZEXUS_UTILS as Address,
  ZEXUS_VAULT: process.env.REACT_APP_ADDRESSES_ZEXUS_VAULT as Address,
  ZEXUS_BETA: process.env.REACT_APP_ADDRESSES_ZEXUS_BETA as Address,
  USDC: process.env.REACT_APP_ADDRESSES_USDC as Address,
  USDT: process.env.REACT_APP_ADDRESSES_USDT as Address,
  WETH: process.env.REACT_APP_ADDRESSES_WETH as Address,
  NFT_MOCK: process.env.REACT_APP_ADDRESSES_NFT_MOCK as Address,
  NFT_SHAPE_MOCK: process.env.REACT_APP_ADDRESSES_NFT_SHAPE_MOCK as Address,
}

export const API_KEYS = {
  ALCHEMY: process.env.REACT_APP_ALCHEMY_KEY,
}

export const GOOGLE_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID || ''

export const URLS = {
  SUPPORT: 'https://discord.com',
  OPEN_SEA: IS_PROD
    ? 'https://opensea.io/assets/ethereum'
    : 'https://testnets.opensea.io/assets/mumbai',
}

const officialPolygonMumbaiChain: Chain = {
  ...polygonMumbai,
  rpcUrls: {
    default: {
      http: ['https://rpc-mumbai.polygon.technology'],
    },
  },
}

export const supportedChains = IS_PROD ? [mainnet] : [officialPolygonMumbaiChain]
