import { FC, useEffect } from 'react'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useWallet } from 'hooks/useWallet'
import {
  getNotifications,
  markNotifications,
} from 'store/slices/notifications/notifications.actions'
import { notificationsStateSelector } from 'store/slices/notifications/notifications.slice'

import { Badge } from 'components'

import { NavigationNotificationItem } from './NavigationNotificationItem'

import { ReactComponent as BellIcon } from 'assets/icons/bell.svg'

type NavigationNotificationProps = {
  className?: string | undefined
}

export const NavigationNotification: FC<NavigationNotificationProps> = props => {
  const { className } = props

  const { isConnected } = useWallet()
  const { notifications } = useAppSelector(notificationsStateSelector)
  const dispatch = useAppDispatch()

  const seenList = new Set<number>()

  useEffect(() => {
    if (isConnected) {
      dispatch(getNotifications())
    }
  }, [dispatch, isConnected])

  const handleOpenChange = (open: boolean) => {
    if (!open && seenList.size > 0) {
      dispatch(markNotifications({ list: Array.from<number>(seenList) }))
    }
  }

  const handleSeen = (id: number) => {
    seenList.add(id)
  }

  if (!isConnected) return <div className={className} />

  return (
    <DropdownMenu.Root onOpenChange={handleOpenChange}>
      <DropdownMenu.Trigger className={`${className} relative w-fit outline-none`}>
        <BellIcon className="h-6 w-6 stroke-third-10" />
        {notifications.length > 0 && <Badge value={notifications.length} />}
      </DropdownMenu.Trigger>
      {notifications.length > 0 && (
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="end"
            className="z-10 mt-2 max-h-[80vh] max-w-[310px] overflow-y-auto rounded-lg border border-third-70 bg-third-95 p-4 sm:max-h-[500px] sm:max-w-[476px]"
          >
            <div className="font-header-h6 mb-7 text-third-10">
              Notifications <Badge position="inline" value={notifications.length} />
            </div>
            <div className="flex flex-col gap-4">
              {notifications.map((item, index) => {
                const key = `notify-${index}`
                return <NavigationNotificationItem item={item} key={key} onSeen={handleSeen} />
              })}
            </div>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      )}
    </DropdownMenu.Root>
  )
}
