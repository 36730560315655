import { FC } from 'react'

import * as Dialog from '@radix-ui/react-dialog'
import { Address } from 'wagmi'

import { shortAddress } from 'utils/address'

import { getButtonClasses, SuccessModal } from 'components'

type OfferConfirmationModalProps = {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  senderAddress: Address
  type: 'reject' | 'accept' | 'cancel'
}

export const OfferConfirmationModal: FC<OfferConfirmationModalProps> = ({
  isOpen,
  onOpenChange,
  senderAddress,
  type,
}) => {
  const primaryButtonClasses = getButtonClasses('medium', 'primary', 'default', true, false)

  const renderBottomBar = () => (
    <Dialog.Close className={primaryButtonClasses}>Go back to My Profile</Dialog.Close>
  )

  const rejectModalMessage = (
    <div>
      <div className="font-body-3">Offer from {shortAddress(senderAddress)} has been rejected.</div>
      <div className="font-body-5 mt-8">Offer was deleted from your “Offers received”.</div>
    </div>
  )

  const acceptModalMessage = (
    <div>
      <div className="font-body-3">
        Request from {shortAddress(senderAddress)} has been accepted.
      </div>
    </div>
  )

  const cancelModalMessage = (
    <div>
      <div className="font-body-3">Your offer has been successfully cancelled.</div>
    </div>
  )

  const handlerMessage = (msg: string) => {
    switch (msg) {
      case 'accept':
        return acceptModalMessage
      case 'reject':
        return rejectModalMessage
      case 'cancel':
        return cancelModalMessage
      default:
        return ''
    }
  }

  return (
    <SuccessModal
      title="Confirmation"
      isOpen={isOpen}
      message={handlerMessage(type)}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    />
  )
}
