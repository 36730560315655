import { Settings } from 'react-slick'

export const sliderSettings = (itemsCount: number): Settings => ({
  dots: true,
  infinite: itemsCount >= 3,
  speed: 500,
  slidesToShow: itemsCount > 3 ? 3 : itemsCount,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        infinite: itemsCount >= 2,
        slidesToShow: itemsCount > 2 ? 2 : itemsCount,
      },
    },
    {
      breakpoint: 920,
      settings: {
        infinite: true,
        slidesToShow: 1,
      },
    },
  ],
})
