import { createSlice } from '@reduxjs/toolkit'

import { Collateral, CollateralDetails } from 'models/Collateral'
import { RequestStatus, RootState } from 'store/helpers'
import { logout } from 'store/slices/auth/auth.actions'

import {
  acceptCollateralOffer,
  createCollateral,
  deleteCollateral,
  getCollateralDetails,
  getCollateralsList,
  makeCollateralOffer,
  rejectCollateralOffer,
  resetCollateralAcceptRejectOffer,
  resetCollateralId,
  resetCollateralOfferId,
  resetCollaterals,
  resetCreateCollateralStatus,
  resetDeleteCollateralStatus,
  setCollateralStatusReady,
} from './collaterals.actions'

export type CollateralState = {
  acceptCollateralOfferStatus: RequestStatus
  collaterals: Collateral[]
  count: number
  collateralDetails?: CollateralDetails
  collateralId?: number
  collateralOfferId?: number
  getCollateralsListStatus: RequestStatus
  getCollateralDetailsStatus: RequestStatus
  getCollateralIdStatus: RequestStatus
  getCollateralOfferIdStatus: RequestStatus
  deleteCollateralStatus: RequestStatus
  rejectCollateralOfferStatus: RequestStatus
}

const initialState: CollateralState = {
  acceptCollateralOfferStatus: RequestStatus.IDLE,
  collaterals: [],
  count: 0,
  collateralDetails: undefined,
  collateralId: undefined,
  collateralOfferId: undefined,
  getCollateralsListStatus: RequestStatus.IDLE,
  getCollateralDetailsStatus: RequestStatus.IDLE,
  getCollateralIdStatus: RequestStatus.IDLE,
  getCollateralOfferIdStatus: RequestStatus.IDLE,
  deleteCollateralStatus: RequestStatus.IDLE,
  rejectCollateralOfferStatus: RequestStatus.IDLE,
}

export const collateralSlice = createSlice({
  name: 'collaterals',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout.pending, state => {
        state.collateralId = initialState.collateralId
        state.collateralOfferId = initialState.collateralOfferId
      })
      .addCase(getCollateralsList.pending, state => {
        state.getCollateralsListStatus = RequestStatus.PENDING
      })
      .addCase(getCollateralsList.fulfilled, (state, action) => {
        state.collaterals = [...state.collaterals, ...action.payload.results]
        state.count = action.payload.count
        state.getCollateralsListStatus = RequestStatus.FULFILLED
      })
      .addCase(getCollateralsList.rejected, state => {
        state.getCollateralsListStatus = RequestStatus.REJECTED
      })
      .addCase(getCollateralDetails.pending, state => {
        state.collateralDetails = undefined
        state.deleteCollateralStatus = RequestStatus.IDLE
        state.getCollateralsListStatus = RequestStatus.PENDING
      })
      .addCase(getCollateralDetails.fulfilled, (state, action) => {
        state.collateralDetails = action.payload
        state.getCollateralsListStatus = RequestStatus.FULFILLED
      })
      .addCase(getCollateralDetails.rejected, state => {
        state.getCollateralsListStatus = RequestStatus.REJECTED
      })
      .addCase(createCollateral.pending, state => {
        state.getCollateralIdStatus = RequestStatus.PENDING
      })
      .addCase(createCollateral.fulfilled, (state, action) => {
        state.collateralId = action.payload
        state.getCollateralIdStatus = RequestStatus.FULFILLED
      })
      .addCase(createCollateral.rejected, state => {
        state.getCollateralIdStatus = RequestStatus.REJECTED
      })
      .addCase(makeCollateralOffer.pending, state => {
        state.getCollateralOfferIdStatus = RequestStatus.PENDING
      })
      .addCase(makeCollateralOffer.fulfilled, (state, action) => {
        state.collateralOfferId = action.payload
        state.getCollateralOfferIdStatus = RequestStatus.FULFILLED
      })
      .addCase(makeCollateralOffer.rejected, state => {
        state.getCollateralOfferIdStatus = RequestStatus.REJECTED
      })
      .addCase(deleteCollateral.pending, state => {
        state.deleteCollateralStatus = RequestStatus.PENDING
      })
      .addCase(deleteCollateral.fulfilled, state => {
        state.deleteCollateralStatus = RequestStatus.FULFILLED
      })
      .addCase(deleteCollateral.rejected, state => {
        state.deleteCollateralStatus = RequestStatus.REJECTED
      })
      .addCase(resetCollaterals.type, state => {
        state.collaterals = []
        state.count = 0
      })
      .addCase(resetCollateralId.type, state => {
        state.collateralId = undefined
      })
      .addCase(resetCollateralOfferId.type, state => {
        state.collateralOfferId = undefined
      })
      .addCase(acceptCollateralOffer.pending, state => {
        state.acceptCollateralOfferStatus = RequestStatus.PENDING
      })
      .addCase(acceptCollateralOffer.fulfilled, state => {
        state.acceptCollateralOfferStatus = RequestStatus.FULFILLED
      })
      .addCase(acceptCollateralOffer.rejected, state => {
        state.acceptCollateralOfferStatus = RequestStatus.REJECTED
      })
      .addCase(rejectCollateralOffer.pending, state => {
        state.rejectCollateralOfferStatus = RequestStatus.PENDING
      })
      .addCase(rejectCollateralOffer.fulfilled, state => {
        state.rejectCollateralOfferStatus = RequestStatus.FULFILLED
      })
      .addCase(rejectCollateralOffer.rejected, state => {
        state.rejectCollateralOfferStatus = RequestStatus.REJECTED
      })
      .addCase(resetCollateralAcceptRejectOffer.type, state => {
        state.acceptCollateralOfferStatus = RequestStatus.IDLE
        state.rejectCollateralOfferStatus = RequestStatus.IDLE
      })
      .addCase(resetDeleteCollateralStatus.type, state => {
        state.deleteCollateralStatus = RequestStatus.IDLE
      })
      .addCase(resetCreateCollateralStatus.type, state => {
        state.getCollateralIdStatus = RequestStatus.IDLE
      })
      .addCase(setCollateralStatusReady.pending, state => {
        state.getCollateralIdStatus = RequestStatus.PENDING
      })
      .addCase(setCollateralStatusReady.fulfilled, state => {
        state.getCollateralIdStatus = RequestStatus.FULFILLED
      })
  },
})

export const collateralStateSelector = (state: RootState): CollateralState => state.collaterals

export default collateralSlice.reducer
