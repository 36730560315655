import { FC } from 'react'

import { Currencies } from 'models/Currency'
import { ActiveOffer } from 'models/Offer'
import { Table as TableType } from 'models/Table'
import { convertFromBigNumber } from 'utils/form'

import { Collapsible, Table } from 'components/index'

interface ActiveOffersProps {
  activeOffers: ActiveOffer[]
}

export const ActiveOffers: FC<ActiveOffersProps> = ({ activeOffers }) => {
  if (!activeOffers || activeOffers.length === 0) return null

  const table: TableType<ActiveOffer> = {
    headers: [
      { key: 'id', header: 'ID' },
      { key: 'principal', header: 'Loan Principal' },
      { key: 'repayment', header: 'Repayment' },
      { key: 'apr', header: 'APR', type: 'percent' },
      { key: 'duration', header: 'Duration', type: 'days' },
    ],
    data: activeOffers.map(item => ({
      ...item,
      principal: `${Number(
        (
          convertFromBigNumber(item.principal, item.currency as Currencies, false) as number
        ).toFixed(4)
      )} ${item.currency}`,
      repayment: `${Number(
        (
          convertFromBigNumber(item.repayment, item.currency as Currencies, false) as number
        ).toFixed(4)
      )} ${item.currency}`,
    })),
  }

  return (
    <div>
      <Collapsible
        defaultOpen
        title={
          <span className="flex items-center">
            Active offers{' '}
            <span className="font-button-small ml-4 min-w-[25px] rounded-full bg-error-60 px-1 text-center text-white">
              {activeOffers.length}
            </span>
          </span>
        }
      >
        <Table columns={table.headers} data={table.data} />
      </Collapsible>
    </div>
  )
}
