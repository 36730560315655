import { FC, useState } from 'react'

import { NFT } from 'models/Collateral'
import { Currencies } from 'models/Currency'
import { Size } from 'models/Style'
import { URLS } from 'utils/constants'
import { convertFromBigNumber } from 'utils/form'
import { getCurrencyIcon } from 'utils/getCurrencyIcon'

import { getNFTImage } from '../../../utils/nft'

import { ReactComponent as CollectionIcon } from 'assets/icons/collection.svg'
import openSeaLogo from 'assets/openSeaLogo.png'

interface TileImagesProps {
  disabled?: boolean
  nfts: NFT[]
  onHighlightedNftChange?: (nft: NFT) => void
  size?: Size
  tileImageClassName?: string
}

export const TileImages: FC<TileImagesProps> = ({
  disabled,
  nfts,
  onHighlightedNftChange,
  size,
  tileImageClassName,
}) => {
  const [highlightedNft, setHighlightedNft] = useState<NFT>(nfts[0])

  const handleMouseEnter = (index: number) => {
    const hoveredNft = nfts[index]
    setHighlightedNft(hoveredNft)
    onHighlightedNftChange?.(hoveredNft)
  }

  const valuation = highlightedNft.valuation
    ? (convertFromBigNumber(highlightedNft.valuation, Currencies.WETH, false) as number)
    : null

  return (
    <div className={`relative h-full ${size === 'large' ? 'w-full' : ''}`}>
      {nfts.length > 1 && (
        <div className="scrollbar-hide absolute left-0 top-0 z-[1] flex flex-col items-center gap-2 overflow-auto rounded bg-transparent-container p-2">
          {nfts.map((nft, index) => {
            const imgSrc = getNFTImage(nft)
            const key = `${imgSrc}${index}`
            return (
              <img
                key={key}
                src={imgSrc}
                onMouseEnter={() => handleMouseEnter(index)}
                alt="desc"
                className={`${disabled ? 'backdrop-blur-[2px]' : ''} ${
                  size === 'large' ? 'md:h-[100px] md:w-[100px]' : ''
                } h-[56px] w-[56px] rounded object-cover shadow-tile`}
              />
            )
          })}
        </div>
      )}
      <div className={`relative h-full ${size === 'large' ? 'w-full' : ''}`}>
        <img
          src={getNFTImage(highlightedNft)}
          alt="desc"
          className={`h-full w-full rounded object-cover ${
            size === 'large' ? 'w-full' : 'max-w-[257px]'
          } ${disabled ? 'backdrop-blur-[2px]' : ''} ${tileImageClassName}`}
        />
        {size === 'large' && (
          <div className="absolute bottom-2 right-2 rounded bg-transparent-container p-4">
            <div className="mb-2 flex items-center justify-between">
              {highlightedNft.collectionName && (
                <div className="flex items-center">
                  <CollectionIcon fill="#F8F8FC" />
                  <span className="font-body-4 ml-2.5 w-24 truncate text-third-10 underline underline-offset-4">
                    {highlightedNft.collectionName}
                  </span>
                </div>
              )}
              <a
                href={`${URLS.OPEN_SEA}/${highlightedNft.address}/${parseInt(
                  highlightedNft.tokenId,
                  16
                )}`}
                target="_blank"
                rel="noreferrer"
                className={highlightedNft.collectionName ? 'ml-2' : 'ml-auto'}
              >
                <img src={openSeaLogo} alt="OpenSea logo" className="aspect-square h-[20px]" />
              </a>
            </div>

            <div className="flex">
              <div className="mr-[25px]">
                <div className="font-body-5 text-third-40">Project floor</div>
                <div className="flex items-center">
                  {getCurrencyIcon(Currencies.ALL)}
                  <div
                    className="ml-1 truncate text-sm font-semibold"
                    title={
                      highlightedNft.projectFloor ? String(highlightedNft.projectFloor) : 'N/A'
                    }
                  >
                    {highlightedNft.projectFloor
                      ? Number(highlightedNft.projectFloor.toFixed(4))
                      : 'N/A'}
                  </div>
                </div>
              </div>
              <div>
                <div className="font-body-5 text-third-40">Valuation</div>
                <div className="flex items-center">
                  {getCurrencyIcon(Currencies.ALL)}
                  <div className="ml-1 truncate text-sm font-semibold" title={String(valuation)}>
                    {typeof valuation === 'number' ? Number(valuation.toFixed(4)) : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
