import { FC, useEffect, useState } from 'react'

import { ProfileLoansFilter } from 'models/Filter'
import { SortItem } from 'models/Sort'

import { FilterLoanType, FilterStatus, Sort } from 'components'

const sortItems: Array<SortItem> = [
  '-created_at',
  'created_at',
  '-principal',
  'principal',
  '-due_date',
  'due_date',
]

type ProfileLoansFiltersProps = {
  className?: string
  onChange: (filters: ProfileLoansFilter) => void
}

const defaultFilters: ProfileLoansFilter = {
  sort: '-created_at',
  status: 'all',
  type: 'all',
}

export const ProfileLoansFilters: FC<ProfileLoansFiltersProps> = props => {
  const { className, onChange } = props

  const [filters, setFilters] = useState<ProfileLoansFilter>(defaultFilters)

  const handleSortChange = (value: SortItem) => {
    setFilters(prevState => ({ ...prevState, sort: value }))
  }

  const handleTypeChange = (value: ProfileLoansFilter['type']) => {
    setFilters(prevState => ({ ...prevState, type: value }))
  }

  const handleStatusChange = (value: ProfileLoansFilter['status']) => {
    setFilters(prevState => ({ ...prevState, status: value }))
  }

  useEffect(() => {
    onChange(filters)
  }, [filters])

  return (
    <div className={`flex gap-4 ${className}`}>
      <div className="w-[119px]">
        <FilterLoanType onChange={handleTypeChange} defaultValue="all" />
      </div>
      <div className="w-[126px]">
        <FilterStatus onChange={handleStatusChange} defaultValue="all" />
      </div>
      <div className="ml-auto w-[158px]">
        {sortItems && (
          <Sort items={sortItems} onChange={handleSortChange} defaultValue={defaultFilters.sort} />
        )}
      </div>
    </div>
  )
}
