import { createSlice } from '@reduxjs/toolkit'

import { RequestStatus, RootState } from 'store/helpers'

import { getAuthMessage, getAuthToken, resetAuth } from './auth.actions'

type AuthState = {
  message?: string
  getAuthMessageRequestId?: string
  getAuthMessageStatus: RequestStatus
  getAuthTokenStatus: RequestStatus
}

const initialState: AuthState = {
  getAuthMessageStatus: RequestStatus.IDLE,
  getAuthTokenStatus: RequestStatus.IDLE,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // allow only single getAuthMessage request at a time
      .addCase(getAuthMessage.pending, (state, action) => {
        if (state.getAuthMessageStatus === RequestStatus.IDLE) {
          state.getAuthMessageRequestId = action.meta.requestId
          state.getAuthMessageStatus = RequestStatus.PENDING
        }
      })
      .addCase(getAuthMessage.fulfilled, (state, action) => {
        if (
          state.getAuthMessageStatus === RequestStatus.PENDING &&
          state.getAuthMessageRequestId === action.meta.requestId
        ) {
          state.message = action.payload
          state.getAuthMessageRequestId = undefined
          state.getAuthMessageStatus = RequestStatus.FULFILLED
        }
      })
      .addCase(getAuthMessage.rejected, (state, action) => {
        if (
          state.getAuthMessageStatus === RequestStatus.PENDING &&
          state.getAuthMessageRequestId === action.meta.requestId
        ) {
          state.getAuthMessageRequestId = undefined
          state.getAuthMessageStatus = RequestStatus.REJECTED
        }
      })
      .addCase(getAuthToken.pending, state => {
        state.getAuthTokenStatus = RequestStatus.PENDING
      })
      .addCase(getAuthToken.fulfilled, state => {
        state.getAuthTokenStatus = RequestStatus.FULFILLED
      })
      .addCase(getAuthToken.rejected, state => {
        state.getAuthTokenStatus = RequestStatus.REJECTED
      })
      .addCase(resetAuth.type, state => {
        state.message = undefined
        state.getAuthMessageStatus = RequestStatus.IDLE
        state.getAuthTokenStatus = RequestStatus.IDLE
      })
  },
})

export const authStateSelector = (state: RootState): AuthState => state.auth

export default authSlice.reducer
