import { FloorPriceList, NFT, ValuationList } from 'models/NFT'

import placeholder from 'assets/images/placeholder.png'

type MergeNFTWithPricesArgs = {
  nfts: NFT[]
  floorPrices: FloorPriceList
  valuation: ValuationList
}

export const mergeNFTWithPrices = (args: MergeNFTWithPricesArgs) => {
  const { nfts, floorPrices, valuation: valuations } = args

  return nfts.map(item => {
    const floorPrice = floorPrices[item.address]
    const valuation = valuations[`${item.address}/${item.tokenId}`]
    return {
      ...item,
      projectFloor: floorPrice ? Number(floorPrice.floorPrice) : undefined,
      valuation: valuation ? Number(valuation.amount) : undefined,
    }
  })
}

export const getNFTImage = (nft: NFT): string => nft.imageUrl || placeholder
