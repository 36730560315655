import { Link } from 'react-router-dom'

export const PrivacyPolicyScreen = () => (
  <div className="container max-w-[848px]">
    <h1 className="mb-4">Privacy policy</h1>
    <p>Last updated - 14 April 2023</p>
    <p className="my-6">
      Zexus Finance has prepared this Privacy Policy (“<b>Policy</b>”) to inform you about how we
      collect, process, and use your Personal Data. By continuing to access or use our service, you
      confirm your acceptance of this Policy and its revisions in the future.
    </p>
    <p className="my-6">
      This Policy applies only to your and Zexus Finance&apos;s actions with respect to our platform
      and service. It does not extend to your actions on third-party websites that can be accessed
      from our platform.
    </p>
    <ol className="ml-16">
      <h3>
        <li className="my-6">Definitions</li>
      </h3>
      <p className="my-6">
        “<b>We/Us/Our</b>” means Zexus Finance, owned and operated by Zexus Group Co.
      </p>
      <p className="my-6">
        “<b>User/You/Your</b>” means an individual and/or company who use our service; If you use
        our service on behalf of a company or other entity, then “you” includes you and that entity,
        and such other entity shall be deemed to have accepted this Policy via your continued access
        and use of our platform on the entity&apos;s behalf.
      </p>
      <p className="my-6">
        “<b>Personal Data</b>” means any data relating to you and from which you may be directly or
        indirectly identified, including without limitation your name, email address, blockchain
        address, IP address, as well as any other information about you that is associated with or
        linked to any of the foregoing.
      </p>
      <h3>
        <li className="my-6">What Personal Data We Collect</li>
      </h3>
      <p className="my-6">We may collect the following Personal Data from you:</p>
      <p className="my-6">(1) Data you provide us:</p>
      <p className="my-6 ml-6">
        a. When you use our service, we will collect your <b>blockchain address</b> and{' '}
        <b>wallet type</b>, which may become associated with your Personal Data.
      </p>
      <p className="my-6 ml-6">
        b. Your{' '}
        <b>name, email address, contact information, as well as any other content included in</b>{' '}
        any feedback, comment, or message that you send to us in any form and for whatever purpose.
      </p>
      <p className="my-6 ml-6">
        c. <b>Any other Personal Data</b> that we request from you in the course of your use of our
        service on our platform, or any Personal Data that you <b>voluntarily provide</b> to us
        during the same. We shall clearly indicate that your Personal Data is being collected when
        we request Personal Data from you.
      </p>
      <p className="my-6">(2) Automated data collection:</p>
      <p className="my-6 ml-6">
        As you browse through or use our service, we may use automated data collection technologies
        to collect certain information about your equipment, browsing actions, and patterns. We use
        this information to analyze trends, administer our service, track users&apos; movements
        around our service, and better tailor our services to our users&apos; needs. For example, we
        may collect data from your activities that are publicly visible and/or accessible on
        blockchains.
      </p>
      <h3>
        <li className="my-6">How We Use Your Personal Data</li>
      </h3>
      <p className="my-6">
        We process your Personal Data to run our business, personalize your experience on our
        service, and improve our service. Specifically, we use your Personal Data to:
      </p>
      <p className="my-6">
        (1) Improve the <b>administration of our service and quality of experience</b> when you
        interact with our service, including without limitation by analyzing how you and other users
        find and interact with our service;
      </p>
      <p className="my-6">
        (2) Provide <b>customer support</b> and respond to your requests and inquiries;
      </p>
      <p className="my-6">
        (3) Respond to your inquiries related to <b>employment opportunities or other requests</b>;
      </p>
      <p className="my-6">
        (4) Comply with <b>applicable laws</b>, cooperate with investigations by law enforcement or
        other authorities regarding suspected illegal activity, and/or to pursue or defend against
        legal threats and/or claims; and
      </p>
      <p className="my-6">
        (5) Any other manner that we describe to you when we request you to provide your Personal
        Data in connection with our service.
      </p>
      <h3>
        <li className="my-6">How We Share/Disclose Data</li>
      </h3>
      <p className="my-6">
        We may share or disclose the Personal Data we collect in the following manners or
        circumstances:
      </p>
      <p className="my-6">
        (1) With our <b>service providers and vendors</b> to assist us in providing, delivering, and
        improving our services. For example, we may share your wallet address with service providers
        to provide technical infrastructure services, and with blockchain analytics providers to
        detect, prevent, and mitigate financial crime and other illicit or harmful activities;
      </p>
      <p className="my-6">
        (2) With <b>competent authorities and/or legal entities</b> in the course of litigation,
        regulatory proceedings, compliance measures, and when compelled by summons, court order, or
        other legal procedure to disclose such data;
      </p>
      <p className="my-6">
        (3) To protect against, investigate, and stop <b>unauthorized or illegal activity</b> on our
        platform to prevent harm to our users, our company, or others;
      </p>
      <p className="my-6">
        (4) With another entity in the event of a merger, acquisition, bankruptcy, dissolution,
        reorganization, asset or stock sale, or other <b>business transaction</b>; or
      </p>
      <p className="my-6">
        (5) Any other time you provide us with <b>your consent</b> for us to share your Personal
        Data.
      </p>
      <h3>
        <li className="my-6">How Long We Retain Data</li>
      </h3>
      <p className="my-6">
        We will retain the Personal Data we collect or receive from you for as long as you continue
        to use our service, or for as long as it is necessary to fulfill the purpose(s) that the
        Personal Data were collected or received, as outlined in this Policy.
      </p>
      <p className="my-6">
        We may continue to retain your Personal Data even after you cease to use our service if it
        is reasonably necessary to do so to comply with our legal obligations, resolve disputes,
        prevent fraud and abuse, enforce our Terms or other agreements or policies, and/or protect
        our legitimate interests.
      </p>
      <p className="my-6">
        We will immediately delete your Personal Data when we deem such data is no longer required
        for the purpose(s) that they were collected or received.
      </p>
      <h3>
        <li className="my-6">Your Access And Control of Data (including CCPA/GDPR users)</li>
      </h3>
      <p className="my-6">
        You can view, access, copy, edit, or delete your Personal Data for certain aspects of our
        service under <Link to="../profile">“My Profile”</Link>. You may also have certain
        additional rights:
      </p>
      <p className="my-6">
        (1) If you are a <b>user in the European Economic Area or the United Kingdom</b>, you have
        certain rights under the respective European and UK General Data Protection Regulations
        (GDPR), including the right to:
      </p>
      <p className="my-6 ml-6">a. request access and obtain a copy of your Personal Data;</p>
      <p className="my-6 ml-6">b. request rectification or erasure;</p>
      <p className="my-6 ml-6">
        c. object to or restrict the processing of your Personal Data; and
      </p>
      <p className="my-6 ml-6">d. request portability of your Personal Data.</p>
      <p className="my-6 ml-6">
        Additionally, if we have collected and processed your Personal Data with your consent, you
        have the right to withdraw your consent at any time.
      </p>
      <p className="my-6">
        (2) If you are a <b>California resident</b>, you have certain rights under the California
        Consumer Privacy Act (CCPA), including the right to:
      </p>
      <p className="my-6 ml-6">
        a. request access to, details regarding, and a copy of the Personal Data we have collected
        about you and/or shared with third parties;
      </p>
      <p className="my-6 ml-6">
        b. request deletion of the Personal Data that we have collected or received about you; and
      </p>
      <p className="my-6 ml-6">c. the right to opt-out of sale of your Personal Data.</p>
      <p className="my-6 ml-6">
        For the avoidance of doubt, we do not “sell” your “personal information” pursuant to how
        such terms are defined in the CCPA.
      </p>
      <p className="my-6">
        If you wish to exercise your rights under the GDPR, CCPA, or other applicable data
        protection or privacy laws, please contact us at the email provided in{' '}
        <b>Section 12. Contact and Report </b>
        below, specify your request, and reference the applicable law. You may need to verify your
        identity, or provide more information about your request. We will consider and act upon any
        such request in accordance with applicable law, and we will not discriminate against you for
        exercising any of these rights.
      </p>
      <p className="my-6">
        Notwithstanding the above, you acknowledge and understand that we cannot edit or delete any
        information that is stored on a blockchain, which may include information such as transfers
        related to your blockchain address and NFTs held at that address.
      </p>
      <h3>
        <li className="my-6">Cross-Border Transmission of Data for Processing</li>
      </h3>
      <p className="my-6">
        Your Personal Data may be processed in the country in which it was collected and/or
        transferred and processed in other countries, including Taiwan, where laws regarding the
        processing of Personal Data may be less stringent than the laws in your country. By
        providing your Personal Data in using our service, you consent to such cross-border transfer
        of your Personal Data.
      </p>
      <h3>
        <li className="my-6">Data Security</li>
      </h3>
      <p className="my-6">
        We implement and maintain reasonable administrative, physical, and technical security
        safeguards to help protect data from loss, theft, misuse, unauthorized access, disclosure,
        alteration, and destruction. However, transmission via the internet is not completely secure
        and we cannot guarantee the security of information about you.{' '}
        <b>
          You are responsible for all of your activity on our services, including the security of
          your blockchain network addresses, cryptocurrency wallets, and their cryptographic keys.
        </b>
      </p>
      <h3>
        <li className="my-6">Third-Party Websites</li>
      </h3>
      <p className="my-6">
        Our service may contain links to third-party websites. When you click on a link to any other
        website or location, you will leave our service, and another entity may collect Personal
        Data from you. We have no control over, do not review, and cannot be responsible for whether
        and how those third-party websites collect Personal Data from you. You are therefore
        encouraged to read the privacy policies of every such third-party website you visit to
        understand your rights and obligations. Any links to third-party websites or locations as
        found on our platform are for your convenience only and do not signify our endorsement of
        such third parties or their products, content, or websites.
      </p>
      <h3>
        <li className="my-6">Third-Party Wallets</li>
      </h3>
      <p className="my-6">
        To use our service, you must use a third-party wallet which allows you to engage in
        transactions on public blockchains. Your interactions with any third-party wallet provider
        are governed by the applicable terms of service and privacy policy of such provider.
      </p>
      <h3>
        <li className="my-6">Third Party Online Tracking</li>
      </h3>
      <p className="my-6">
        We do not use services provided by Google or other third parties that use tracking
        technology such as cookies, mobile device ID, advertising ID, and local storage to collect
        information from you.
      </p>
      <h3>
        <li className="my-6">Contact And Report</li>
      </h3>
      <p className="my-6">
        If you have any questions, concerns, or complaints about this Policy or our data collection
        or processing practices, or if you want to report any security violations to us, please
        contact us at <a href="mailto:info@zexus.finance">info@zexus.finance</a>.
      </p>
    </ol>
  </div>
)
