import * as Toast from '@radix-ui/react-toast'
import { Provider } from 'react-redux'
import { WagmiConfig } from 'wagmi'

import { client } from 'hooks/useWallet'
import { store } from 'store/store'

import { injectStore } from './utils/api/blockchain'
import AppContainer from './AppContainer'

const App = () => {
  injectStore(store)

  return (
    <WagmiConfig client={client}>
      <Provider store={store}>
        <Toast.Provider>
          <AppContainer />
        </Toast.Provider>
      </Provider>
    </WagmiConfig>
  )
}

export default App
