import { FC } from 'react'

import { Modal } from 'components'

import { SimpleModalProps } from './SimpleModal'

import { ReactComponent as ExclamationTriangleIcon } from 'assets/icons/exclamationTriangle.svg'

export const WarningModal: FC<SimpleModalProps> = props => {
  const { isOpen, message, onOpenChange, title } = props
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      icon={<ExclamationTriangleIcon className="stroke-warning-50" />}
      title={title || 'Warning!'}
    >
      {message}
    </Modal>
  )
}
