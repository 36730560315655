import { FC, useEffect, useMemo, useState } from 'react'

import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { StoreModalType } from 'models/Comm'
import { FormInput } from 'models/Form'
import { NFT } from 'models/NFT'
import { RequestStatus } from 'store/helpers'
import { pushModal } from 'store/slices/comm/comm.actions'
import { resetWhiteListNft, whitelistNft } from 'store/slices/nft/nft.actions'
import { nftStateSelector } from 'store/slices/nft/nft.slice'
import { setLoadingIdle } from 'store/slices/profile/profile.actions'
import { addressValidationSchema } from 'utils/validation'

import { Button, Modal } from 'components'
import { Dropdown } from 'components/form/dropdown'
import { TextInput } from 'components/form/text-input/TextInput'

type WhitelistNftModalProps = {
  nftList: NFT[]
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

export const WhitelistNftModal: FC<WhitelistNftModalProps> = props => {
  const { nftList, isOpen, onOpenChange } = props

  const [nft, setNft] = useState<NFT>()
  const [nftAddress, setNftAddress] = useState<FormInput>({ value: undefined, isValid: true })

  const { whitelistNftStatus } = useAppSelector(nftStateSelector)
  const dispatch = useAppDispatch()

  const notWhitelistedNfts = useMemo(() => nftList.filter(item => !item.isWhitelisted), [nftList])

  const handleNftSelect = (value: NFT) => {
    setNft(value)
    setNftAddress({ value: undefined, isValid: true })
  }

  const handleNftAddressChange = (input: FormInput) => {
    setNftAddress(input)
    setNft(undefined)
  }

  const handleSendRequest = () => {
    let address
    if (nft) {
      address = nft.address
    } else {
      address = nftAddress.value
    }
    dispatch(whitelistNft(address!))
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setNft(undefined)
      setNftAddress({ value: '', isValid: true })
      dispatch(resetWhiteListNft())
    }
    onOpenChange(open)
  }

  useEffect(() => {
    if (whitelistNftStatus === RequestStatus.FULFILLED) {
      handleOpenChange(false)
      dispatch(
        pushModal({
          message: 'We received your request to whitelist selected NFT.',
          title: 'Success!',
          type: StoreModalType.SUCCESS_MESSAGE,
        })
      )
    } else if (whitelistNftStatus === RequestStatus.REJECTED) {
      dispatch(setLoadingIdle())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
    }
  }, [whitelistNftStatus])

  const renderBottomBar = () => (
    <div className="mt-8 flex items-center justify-end gap-6">
      <Button onClick={() => handleOpenChange(false)} variant="secondary" size="small">
        Cancel
      </Button>
      <Button
        onClick={handleSendRequest}
        variant="primary"
        size="small"
        disabled={
          (!nft && (!nftAddress?.value || !nftAddress?.isValid)) ||
          whitelistNftStatus === RequestStatus.PENDING
        }
      >
        Send request
      </Button>
    </div>
  )

  return (
    <Modal
      title="Request to add collection"
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      renderBottomBar={renderBottomBar}
    >
      <Dropdown title={nft?.name || nft?.address} label="Select NFT from your wallet">
        {notWhitelistedNfts.map(item => {
          const key = `${item.address}-${item.tokenId}`
          return (
            <DropdownMenuItem
              key={key}
              onClick={() => handleNftSelect(item)}
              className="font-body-5 truncate py-2.5 sm:font-body-3"
            >
              {item.name.length ? item.name : item.address}
            </DropdownMenuItem>
          )
        })}
      </Dropdown>
      <p className="font-header-h6 mb-8 text-center font-book">or</p>
      <TextInput
        onValueChange={handleNftAddressChange}
        value={nftAddress.value}
        label="Paste NFT address"
        validationSchema={addressValidationSchema}
      />
    </Modal>
  )
}
