import { FC, useMemo } from 'react'

import Avatar, { jsNumberForAddress } from 'react-jazzicon'
import { useNavigate } from 'react-router-dom'

import { useWallet } from 'hooks/useWallet'
import { shortAddress } from 'utils/address'

import { Button, Link } from 'components'

import { NavigationWalletInfo } from './NavigationWalletInfo'

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as MetaMaskIcon } from 'assets/icons/metaMask.svg'
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg'

type SideNavProps = {
  isVisible: boolean
  onHide: () => void
}

export const SideNav: FC<SideNavProps> = props => {
  const { isVisible, onHide } = props

  const { address, chain, connectWallet, disconnectWallet, isLoading, isConnected } = useWallet()
  const navigate = useNavigate()

  const handleBorrowMoneyClick = () => {
    navigate('/collaterals/new')
    onHide()
  }

  const addressShort = useMemo(() => shortAddress(address), [address])

  const getLinkClass = (args: { isActive: boolean; isPending: boolean }) => {
    const { isActive } = args
    return isActive ? 'text-primary-50' : 'hover:text-primary-50'
  }

  return (
    <div className={`fixed inset-0 ${isVisible ? 'visible' : 'invisible'}`}>
      <button
        type="button"
        onClick={onHide}
        className="absolute z-0 h-full w-full cursor-default backdrop-blur-sm"
      >
        {' '}
      </button>

      <div
        className={`absolute right-0 top-0 z-10 flex h-[calc(100%_-_8px)] w-[412px] max-w-full flex-col rounded-l-[10px] border-l-2 border-b-2 border-l-third-70 border-b-third-70 bg-third-95 px-5 py-4 transition-all duration-300 ${
          isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
        }`}
      >
        {!isConnected && (
          <>
            <div className="mb-6 flex items-center">
              <div className="font-header-h5 flex items-center text-third-10">
                <WalletIcon className="h-6 pr-4" />
                Connect wallet
              </div>
              <button type="button" onClick={onHide} className="ml-auto">
                <CloseIcon />
              </button>
            </div>

            <div className="font-body-3 mb-8 font-semibold text-third-40">
              Connect a wallet to continue
            </div>

            <div className="flex items-center rounded-2xl border border-third-80 py-4 px-5">
              <MetaMaskIcon className="mr-3" />
              <div className="font-body-3 font-semibold text-third-10">MetaMask</div>
              <Button onClick={connectWallet} size="small" className="ml-auto" disabled={isLoading}>
                Connect
              </Button>
            </div>
          </>
        )}
        {isConnected && (
          <>
            <div className="flex items-center">
              <Link
                to="/profile"
                internal
                className="font-header-h5 flex items-center text-third-300"
              >
                <Avatar
                  diameter={36}
                  seed={jsNumberForAddress(address as string)}
                  paperStyles={{ marginRight: 12 }}
                />
                {addressShort}
              </Link>
              <button type="button" onClick={onHide} className="ml-auto">
                <CloseIcon />
              </button>
            </div>

            <div className="font-header-h5 mt-10 ml-8">
              <div className="font-header-h6 text-third-300">My profile</div>
              <div className="flex flex-col gap-4 py-2.5 pl-8">
                <Link to="/profile/loans" internal onClick={onHide} className={getLinkClass}>
                  My Loans
                </Link>
                <Link to="/profile/collaterals" internal onClick={onHide} className={getLinkClass}>
                  My Collaterals
                </Link>
                <Link to="/profile/offers-made" internal onClick={onHide} className={getLinkClass}>
                  My Offers
                </Link>
              </div>
            </div>

            <div className="mt-auto">
              <div className="font-caption mb-2 ml-7 text-third-10">Connected wallet</div>
              <NavigationWalletInfo
                address={addressShort}
                network={chain?.name}
                onDisconnect={disconnectWallet}
              />
            </div>

            <Button
              onClick={handleBorrowMoneyClick}
              type="button"
              variant="primary"
              className="mt-4"
            >
              Create a new collateral
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
