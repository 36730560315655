import { FC, useEffect, useState } from 'react'

interface CheckboxProps {
  checked?: boolean
  disabled?: boolean
  onChange?: (isChecked: boolean) => void
}

const baseClasses =
  "relative h-5 w-5 cursor-pointer appearance-none rounded-sm border before:absolute before:left-1/2 before:top-1/2 before:h-[20px] before:w-[20px] before:-translate-y-1/2 before:-translate-x-1/2   checked:before:content-[url('/src/assets/icons/check.svg')]"

export const Checkbox: FC<CheckboxProps> = ({ checked = false, disabled, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    if (typeof checked !== 'boolean') return
    setIsChecked(checked)
  }, [checked])

  const getCheckboxClasses = (): string => {
    if (disabled) {
      return `${baseClasses} checked:border-third-70 bg-third-70`
    }
    return `${baseClasses} border-third-40 checked:border-primary-50 checked:bg-primary-50`
  }

  const handleOnChange = (): void | null => (!disabled ? setIsChecked(prev => !prev) : null)

  useEffect(() => {
    onChange?.(isChecked)
  }, [isChecked])

  return (
    <div className="h-5 w-5">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleOnChange}
        className={getCheckboxClasses()}
      />
    </div>
  )
}
