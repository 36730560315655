import { createAction } from '@reduxjs/toolkit'

import { CollateralDetails } from 'models/Collateral'
import { MakeOfferVars, RejectOfferData } from 'models/Offer'
import { createAppAsyncThunk } from 'store/helpers'
import { collateralsApi } from 'utils/api'
import {
  CreateCollateralData,
  CreateCollateralVars,
  GetCollateralDetailsVars,
  GetCollateralsListResponse,
  GetCollateralsListVars,
  SetDealNowStatusVars,
} from 'utils/api/collaterals'
import { GetPriceValuationVarsItem } from 'utils/api/nft'
import * as nftApi from 'utils/api/nft'
import { mergeNFTWithPrices } from 'utils/nft'

export const getCollateralsList = createAppAsyncThunk<
  GetCollateralsListResponse,
  GetCollateralsListVars
>('collaterals/getCollateralsList', async (variables, thunkAPI) => {
  const extendedVariables: GetCollateralsListVars = { ...variables, signal: thunkAPI.signal }
  return await collateralsApi.getCollateralsList(extendedVariables)
})

export const getCollateralDetails = createAppAsyncThunk<
  CollateralDetails,
  GetCollateralDetailsVars
>('collaterals/getCollateralDetails', async variables => {
  const collateralDetails = await collateralsApi.getCollateralDetails(variables)

  const pricesVariables: GetPriceValuationVarsItem[] = collateralDetails.nfts.map(item => ({
    address: item.address,
    token_id: item.tokenId,
  }))
  const nftPriceValuation = await nftApi.getPriceValuation(pricesVariables)

  collateralDetails.nfts = mergeNFTWithPrices({
    ...nftPriceValuation,
    nfts: collateralDetails.nfts,
  })

  return collateralDetails
})

export const createCollateral = createAppAsyncThunk<number, CreateCollateralVars>(
  'collaterals/createCollateral',
  async variables => {
    const { id } = await collateralsApi.createCollateral(variables)
    return id
  }
)

export const resetCreateCollateralStatus = createAction('collateral/resetCreateCollateralStatus')

export const setCollateralStatusReady = createAppAsyncThunk<void, CreateCollateralData>(
  'collaterals/setCollateralStatusReady',
  async variables => await collateralsApi.setCollateralStatusReady(variables)
)

export const setDealNowStatus = createAppAsyncThunk<void, SetDealNowStatusVars>(
  'collaterals/setDealNowStatus',
  async variables => await collateralsApi.setDealNowStatus(variables)
)

export const makeCollateralOffer = createAppAsyncThunk<number, MakeOfferVars>(
  'collaterals/makeOffer',
  async variables => {
    const { id } = await collateralsApi.makeCollateralOffer(variables)
    return id
  }
)

export const deleteCollateral = createAppAsyncThunk<void, number>(
  'collaterals/deleteCollateral',
  async id => {
    await collateralsApi.deleteCollateral(id)
  }
)

export const resetDeleteCollateralStatus = createAction('collaterals/resetDeleteCollateralStatus')

export const acceptCollateralOffer = createAppAsyncThunk<void, number>(
  'collaterals/acceptCollateralOffer',
  async id => {
    await collateralsApi.acceptCollateralTermsOffer(id)
  }
)

export const rejectCollateralOffer = createAppAsyncThunk<void, RejectOfferData>(
  'collaterals/rejectCollateralOffer',
  async variables => {
    const { id, message } = variables
    await collateralsApi.rejectCollateralTermsOffer(id, message)
  }
)

export const cancelCollateralOffer = createAppAsyncThunk<void, number>(
  'collaterals/cancelCollateralOffer',
  async id => {
    await collateralsApi.cancelCollateralTermsOffer(id)
  }
)

export const resetCollaterals = createAction('collaterals/resetCollaterals')

export const resetCollateralId = createAction('collaterals/resetCollateralId')

export const resetCollateralOfferId = createAction('collaterals/resetCollateralOfferId')

export const resetCollateralAcceptRejectOffer = createAction(
  'collaterals/resetCollateralAcceptRejectOffer'
)
