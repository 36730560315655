import { createSlice } from '@reduxjs/toolkit'

import { RootState } from 'store/helpers'

import { hideSideNav, showSideNav } from './nav.actions'

type NavState = {
  isOpen: boolean
}

const initialState: NavState = {
  isOpen: false,
}

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(showSideNav.type, state => {
        state.isOpen = true
      })
      .addCase(hideSideNav.type, state => {
        state.isOpen = false
      })
  },
})

export const navStateSelector = (state: RootState): NavState => state.nav

export default navSlice.reducer
