import { FC } from 'react'

import { Currencies } from 'models/Currency'
import { NFT } from 'models/NFT'
import { URLS } from 'utils/constants'
import { convertFromBigNumber } from 'utils/form'
import { getCurrencyIcon } from 'utils/getCurrencyIcon'

import { getNFTImage } from '../../../utils/nft'

import { ReactComponent as CollectionIcon } from 'assets/icons/collection.svg'
import openSeaLogo from 'assets/openSeaLogo.png'

type ClaimNFTItemProps = {
  nft: NFT
}

export const ClaimNFTItem: FC<ClaimNFTItemProps> = props => {
  const { nft } = props

  return (
    <div className="mt-6 flex items-start gap-8">
      <img
        src={getNFTImage(nft)}
        alt={`${nft.collectionName} - ${nft.name}`}
        className="w-max-[152px] h-full w-full rounded object-cover"
      />

      <div>
        <div className="mb-2 flex items-center justify-between">
          {nft.collectionName && (
            <div className="flex items-center">
              <CollectionIcon fill="#F8F8FC" />
              <span className="font-body-4 ml-2.5 w-28 truncate text-third-10 underline underline-offset-4">
                {nft.collectionName}
              </span>
            </div>
          )}
          <a
            href={`${URLS.OPEN_SEA}/${nft.address}/${parseInt(nft.tokenId, 16)}`}
            target="_blank"
            rel="noreferrer"
            className={nft.collectionName ? 'ml-2' : 'ml-auto'}
          >
            <img src={openSeaLogo} alt="OpenSea logo" className="aspect-square h-[20px]" />
          </a>
        </div>

        <div className="mt-4 flex">
          <div className="mr-[25px]">
            <div className="font-body-4 text-third-40">Project floor</div>
            <div className="flex items-center">
              {getCurrencyIcon(Currencies.ALL)}
              <span className="ml-1 text-sm font-semibold">{nft.projectFloor || 'N/A'}</span>
            </div>
          </div>
          <div>
            <div className="font-body-4 text-third-40">Valuation</div>
            <div className="flex items-center">
              {getCurrencyIcon(Currencies.ALL)}
              <span className="ml-1 text-sm font-semibold">
                {nft.valuation ? convertFromBigNumber(nft.valuation, Currencies.WETH) : 'N/A'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
