import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useWallet } from 'hooks/useWallet'
import { getProfileCollaterals } from 'store/slices/profile/profile.actions'
import { profileSelector } from 'store/slices/profile/profile.slice'

import { ProfileCollateralsView } from './ProfileCollateralsView'

export const ProfileCollateralsScreen = () => {
  const { collaterals } = useAppSelector(profileSelector)
  const dispatch = useAppDispatch()

  const { address } = useWallet()

  useEffect(() => {
    dispatch(getProfileCollaterals(address))
  }, [dispatch, address])

  return <ProfileCollateralsView collaterals={collaterals} />
}
