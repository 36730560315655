import { createSlice } from '@reduxjs/toolkit'

import { Notification } from 'models/Notification'
import { RequestStatus, RootState } from 'store/helpers'
import { logout } from 'store/slices/auth/auth.actions'

import { getNotifications, markNotifications } from './notifications.actions'

export type NotificationsState = {
  notifications: Notification[]
  getNotificationsStatus: RequestStatus
  markNotificationsStatus: RequestStatus
}

const initialState: NotificationsState = {
  notifications: [],
  getNotificationsStatus: RequestStatus.IDLE,
  markNotificationsStatus: RequestStatus.IDLE,
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout.pending, state => {
        state.notifications = initialState.notifications
      })
      .addCase(getNotifications.pending, state => {
        state.getNotificationsStatus = RequestStatus.PENDING
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload
        state.getNotificationsStatus = RequestStatus.FULFILLED
      })
      .addCase(getNotifications.rejected, state => {
        state.getNotificationsStatus = RequestStatus.REJECTED
      })
      .addCase(markNotifications.pending, state => {
        state.markNotificationsStatus = RequestStatus.PENDING
      })
      .addCase(markNotifications.fulfilled, state => {
        state.markNotificationsStatus = RequestStatus.FULFILLED
      })
      .addCase(markNotifications.rejected, state => {
        state.markNotificationsStatus = RequestStatus.REJECTED
      })
  },
})

export const notificationsStateSelector = (state: RootState): NotificationsState =>
  state.notifications

export default notificationsSlice.reducer
