import { useNavigate } from 'react-router-dom'

import { Button } from 'components'

import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg'

export const HeroSection = () => {
  const navigate = useNavigate()

  return (
    <div className="flex h-[calc(100vh-96px)] min-h-[460px] flex-col items-center justify-center border-b border-third-20 bg-[url('assets/gradient.jpg')] bg-cover">
      <div className="container flex flex-col items-center">
        <h1 className="mb-6 text-center text-third-90">
          Need more funds for investment?
          <br />
          Release liquidity from your NFT
        </h1>
        <h6 className="mb-10 max-w-[586px] text-center text-third-90">
          Zexus Finance provides a peer-to-peer marketplace for users to “borrow” or “lend out”
          ERC-20 tokens with NFT collateral.
        </h6>
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-8">
          <Button onClick={() => navigate('/collaterals')} variant="secondary">
            Lend ERC-20 to earn APR%
          </Button>
          <Button
            theme="light"
            onClick={() => navigate('/collaterals/new')}
            icon={<ArrowRight className="fill-third-95 stroke-third-95" />}
          >
            Borrow with NFT collateral
          </Button>
        </div>
      </div>
    </div>
  )
}
