import { FC, ReactNode } from 'react'

import { useAppSelector } from 'hooks/store.hooks'
import { CollateralDetails } from 'models/Collateral'
import { Currencies } from 'models/Currency'
import { LoanDetails } from 'models/Loan'
import { UserOffer } from 'models/Offer'
import { Table as TableType } from 'models/Table'
import { OffersTypes } from 'screens/profile-offers/ProfileOffersScreen'
import { RequestStatus } from 'store/helpers'
import { profileSelector } from 'store/slices/profile/profile.slice'
import { convertFromBigNumber } from 'utils/form'
import { roundUnknownTypeDigits } from 'utils/number'

import { Modal } from 'components/comm'
import { ActivityIndicator, getButtonClasses, Table } from 'components/index'
import { TileImages } from 'components/tile/components/TileImages'

type OfferDetails = {
  stat: string
  current_terms: ReactNode
  offer: ReactNode
}

const getDisplayValue = (value: number | string, currency: Currencies) => {
  if (value?.toString().includes('e')) {
    const formattedValue = value.toLocaleString('fullwide', { useGrouping: false })
    return convertFromBigNumber(formattedValue, Currencies[currency])
  }
  return convertFromBigNumber(value, Currencies[currency])
}

const generateTableData = (
  data: UserOffer,
  singleOffer: LoanDetails | CollateralDetails
): TableType<OfferDetails> => ({
  headers: [
    {
      header: '',
      key: 'stat',
    },
    {
      header: 'Current terms',
      key: 'current_terms',
      className: 'w-1/4',
    },
    {
      header: 'Offer',
      key: 'offer',
      className: 'text-primary-40 w-1/4',
    },
  ],
  data: [
    {
      stat: 'Principal',
      current_terms: (
        <div
          className="flex gap-1 pr-1"
          title={`${getDisplayValue(singleOffer.principal, singleOffer.currency)} ${
            singleOffer.currency
          }`}
        >
          <div className="truncate">
            {roundUnknownTypeDigits(
              getDisplayValue(singleOffer.principal, singleOffer.currency),
              4
            )}
          </div>
          <div>{String(singleOffer.currency)}</div>
        </div>
      ),
      offer: (
        <div
          className="flex gap-1 pr-1"
          title={`${getDisplayValue(data.principal, data.currency)} ${data.currency}`}
        >
          <div className="truncate">
            {roundUnknownTypeDigits(getDisplayValue(data.principal, data.currency), 4)}
          </div>
          <div>{String(data.currency)}</div>
        </div>
      ),
    },
    {
      stat: 'Repayment',
      current_terms: (
        <div
          className="flex gap-1 pr-1"
          title={`${getDisplayValue(singleOffer.repayment, singleOffer.currency)} ${
            singleOffer.currency
          }`}
        >
          <div className="truncate">
            {roundUnknownTypeDigits(
              getDisplayValue(singleOffer.repayment, singleOffer.currency),
              4
            )}
          </div>
          <div>{String(singleOffer.currency)}</div>
        </div>
      ),
      offer: (
        <div
          className="flex gap-1 pr-1"
          title={`${getDisplayValue(data.repayment, data.currency)} ${data.currency}`}
        >
          <div className="truncate">
            {roundUnknownTypeDigits(getDisplayValue(data.repayment, data.currency), 4)}
          </div>
          <div>{String(data.currency)}</div>
        </div>
      ),
    },
    {
      stat: 'APR',
      current_terms: `${singleOffer.apr}%`,
      offer: `${data.apr}%`,
    },
    {
      stat: 'Duration',
      current_terms: `${singleOffer.duration} days`,
      offer: `${data.duration} days`,
    },
  ],
})

const primaryButtonClasses = getButtonClasses('medium', 'primary', 'default', true, false)
const secondaryButtonClasses = getButtonClasses('medium', 'secondary', 'default', true, false)

interface DetailsModalProps {
  isOpen: boolean
  onOfferAccept: () => void
  onOfferReject: () => void
  onOfferCancel: () => void
  onOpenChange: (open: boolean) => void
  offersType: OffersTypes
}

export const DetailsModal: FC<DetailsModalProps> = ({
  isOpen,
  onOfferAccept,
  onOfferReject,
  offersType,
  onOpenChange,
  onOfferCancel,
}) => {
  const { singleOffer, selectedOffer, loadingState } = useAppSelector(profileSelector)
  if (!selectedOffer) return null
  const { expiryDate } = selectedOffer
  const today = new Date()
  const expiry = new Date(expiryDate)
  const expired = today > expiry
  const renderBottomBar = () => {
    if (expired) return null
    if (offersType === 'received') {
      return (
        <>
          <button type="button" onClick={onOfferReject} className={secondaryButtonClasses}>
            Reject
          </button>
          <button type="button" onClick={onOfferAccept} className={primaryButtonClasses}>
            {loadingState === RequestStatus.PENDING ? (
              <ActivityIndicator className="w-[70px]" whiteColor />
            ) : (
              'Accept offer'
            )}
          </button>
        </>
      )
    }
    return (
      <button type="button" onClick={onOfferCancel} className={secondaryButtonClasses}>
        Cancel offer
      </button>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title="Offer details"
      renderBottomBar={renderBottomBar}
    >
      <div className="mb-14 flex h-[300px] w-[80vw] flex-col gap-10 md:w-[677px] md:flex-row">
        {singleOffer && (
          <>
            <div className="flex flex-col">
              <h6 className="mb-4">
                {selectedOffer.offerType === 'OFFER' ? 'Collateral #' : 'Loan #'}
                {selectedOffer.loan}
              </h6>
              <div className="h-[179px] w-[200px]">
                <TileImages nfts={singleOffer.nfts} />
              </div>
            </div>
            <Table
              data={generateTableData(selectedOffer, singleOffer).data}
              columns={generateTableData(selectedOffer, singleOffer).headers}
            />
          </>
        )}
      </div>
    </Modal>
  )
}
