import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { StoreAlert, StoreModal, StoreToast } from 'models/Comm'
import { RootState } from 'store/helpers'

import { pushAlert, pushModal, pushToast, shiftAlert, shiftModal, shiftToast } from './comm.actions'

export type CommState = {
  modalStack: StoreModal[]
  toastStack: StoreToast[]
  alertStack: StoreAlert[]
}

const initialState: CommState = {
  modalStack: [],
  toastStack: [],
  alertStack: [],
}

// We will use FIFO method for organising the manipulation of Modals in the stack
export const commSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(pushModal.type, (state, action: PayloadAction<StoreModal>) => {
        // check if last modal data are same to not duplicate unnecessary
        const lastModal =
          state.modalStack.length > 0 ? state.modalStack[state.modalStack.length - 1] : null
        if (!lastModal || JSON.stringify(lastModal) !== JSON.stringify(action.payload)) {
          state.modalStack.push(action.payload)
        }
      })
      .addCase(shiftModal.type, state => {
        state.modalStack.shift()
      })
      .addCase(pushToast.type, (state, action: PayloadAction<StoreToast>) => {
        // check if last toast data are same to not duplicate unnecessary
        const lastToast =
          state.toastStack.length > 0 ? state.toastStack[state.toastStack.length - 1] : null
        if (!lastToast || lastToast.message !== action.payload.message) {
          state.toastStack.push(action.payload)
        }
      })
      .addCase(shiftToast.type, state => {
        state.toastStack.shift()
      })
      .addCase(pushAlert.type, (state, action: PayloadAction<StoreAlert>) => {
        // check if last alert data are same to not duplicate unnecessary
        const lastAlert =
          state.alertStack.length > 0 ? state.alertStack[state.alertStack.length - 1] : null
        if (!lastAlert || lastAlert.message !== action.payload.message) {
          state.alertStack.push(action.payload)
        }
      })
      .addCase(shiftAlert.type, state => {
        state.alertStack.shift()
      })
  },
})

export const commStateSelector = (state: RootState): CommState => state.comm

export default commSlice.reducer
