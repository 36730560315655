import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import { useAppSelector } from 'hooks/store.hooks'
import { homeStateSelector } from 'store/slices/home/home.slice'

import { Button, Link, Tile } from 'components'

import { sliderSettings } from '../helpers'

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrowRight.svg'

export const CollateralSection = () => {
  const { collaterals } = useAppSelector(homeStateSelector)

  const navigate = useNavigate()

  const settings = sliderSettings(collaterals?.length)
  const maxSliderWidth = collaterals?.length < 3 ? 289 * collaterals.length : 886

  return (
    <div>
      <div className="container flex flex-col items-center gap-3 pb-14 pt-[120px] sm:flex-row sm:items-center sm:justify-between">
        <div className="flex w-[320px] flex-col items-center pr-1 text-center sm:items-start sm:text-left">
          <h4 className="mb-2">Collateral Marketplace</h4>
          <p className="font-body-3">
            Looking forward to lend out ERC-20 to earn APR%? This is where you could browse all
            collaterals and either directly accept the borrower’s “loan terms” or “make an offer”.
          </p>
          <Button
            onClick={() => navigate('collaterals')}
            icon={<ArrowRightIcon />}
            className="mt-8"
          >
            View all collaterals
          </Button>
        </div>
        <div
          className={`mx-6 mt-4 max-[919px]:w-[274px] sm:mt-0 min-[920px]:w-[580px] min-[1200px]:w-[${maxSliderWidth}px]`}
        >
          {collaterals?.length > 1 && (
            <Slider {...settings}>
              {collaterals.map((item, index) => {
                const { name, id, nfts, apr, currency, principal } = item
                const key = `${id}-${index}`
                return (
                  <Link to={`/collaterals/${id}`} internal key={key}>
                    <Tile
                      apr={apr}
                      className="mx-auto"
                      currency={currency}
                      nfts={nfts}
                      principal={principal}
                      title={name}
                      type="collateral"
                      homepage={collaterals.length}
                    />
                  </Link>
                )
              })}
            </Slider>
          )}
          {collaterals?.length === 1 && (
            <Link to={`collaterals/${collaterals[0].id}`}>
              <Tile
                apr={collaterals[0].apr}
                className="mx-auto"
                currency={collaterals[0].currency}
                nfts={collaterals[0].nfts}
                principal={collaterals[0].principal}
                title={collaterals[0].name}
                type="collateral"
              />
            </Link>
          )}
        </div>
      </div>
      <div className="mx-1 h-[2px] bg-third-20" />
    </div>
  )
}
