import { Address } from 'wagmi'

import { Collateral, NFT } from 'models/Collateral'
import { ProfileLoansFilter, ProfileOffersFilter } from 'models/Filter'
import { Loan } from 'models/Loan'
import { UserOffer } from 'models/Offer'
import { SortItem } from 'models/Sort'
import { mapLoanResult } from 'utils/api/helpers'
import { API_URLS } from 'utils/constants'

import API from './api'
import { GetCollateralsListResponse } from './collaterals'
import { GetLoansListResponse } from './loans'

export type GetOwnedNftsVars = {
  sort: SortItem
}

export type GetOwnedNftsResponse = NFT[]

export type GetProfileLoansVars = {
  address: Address
  filter: ProfileLoansFilter
}

export type UserOfferApiResponse = {
  count: number
  results: UserOffer[]
}

export type GetOffersVars = {
  type: 'made' | 'received'
  address: string
  filters?: ProfileOffersFilter
}

export type GetSingleOfferVars = {
  type: 'loan' | 'collateral'
  id: string
}

export const getProfileLoans = async (variables: GetProfileLoansVars): Promise<Loan[]> => {
  const { address, filter } = variables
  const { data } = await API.get<GetLoansListResponse>(`${API_URLS.ZEXUS_API}/loans/${address}/`, {
    params: {
      sort_by: filter?.sort,
      status: filter?.status !== 'all' ? filter?.status : undefined,
      type: filter?.type !== 'all' ? filter?.type : undefined,
    },
  })
  const { results } = data
  return results.map(mapLoanResult)
}

export const getOwnedNfts = async (variables: GetOwnedNftsVars): Promise<NFT[]> => {
  const { sort } = variables
  const { data } = await API.get<GetOwnedNftsResponse>(`${API_URLS.ZEXUS_API}/owned-nfts/`, {
    params: {
      sort_by: sort,
    },
  })
  return data
}

export const getProfileCollaterals = async (userAddress: Address): Promise<Collateral[]> => {
  const { data } = await API.get<GetCollateralsListResponse>(
    `${API_URLS.ZEXUS_API}/offers/${userAddress}/`
  )
  const { results } = data
  return results
}

export const getOffers = async (variables: GetOffersVars): Promise<UserOffer[]> => {
  const { address, filters, type } = variables

  const {
    data: { results },
  } = await API.get<UserOfferApiResponse>(`${API_URLS.ZEXUS_API}/offers-${type}/${address}`, {
    params: {
      type: filters?.type === 'all' ? null : filters?.type,
      sort_by: filters?.sort,
    },
  })

  return results
}
