import { useEffect, useRef } from 'react'

export const useIntersectingObserver = (callback: () => void) => {
  const elementRef = useRef<HTMLDivElement>(null)

  const callbackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries
    if (entry.isIntersecting) callback()
  }

  const options = {
    root: null,
    rootMargin: '200px',
    threshold: 0.1,
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (elementRef.current) observer.observe(elementRef.current)

    return () => {
      if (elementRef.current) observer.unobserve(elementRef.current)
    }
  }, [elementRef, options])

  return { elementRef }
}
