import { Link } from 'react-router-dom'

export const TermsServiceScreen = () => (
  <div className="container max-w-[848px]">
    <h1 className="mb-4">Terms of Service</h1>
    <p>Last updated - 14 April 2023</p>
    <p className="my-6">
      These Terms of Service (“<b>Terms</b>”) govern your access to and use of the Zexus Finance
      platform, and any other tools or functionalities in connection with our services; including
      without limitation using our services to borrow and lend Ethereum Token Standard tokens
      against NFT collateral via a permissionless protocol operating on a blockchain.
    </p>
    <p className="my-6">
      <b>
        Please read these Terms carefully as they contain important information regarding your legal
        rights and your use of our service. By clicking to accept and using our service, you agree
        to be legally bound by these Terms. If you do not agree, you should leave the platform and
        may not access or use our service.
      </b>
    </p>
    <ol className="ml-16">
      <h3>
        <li className="my-6">Definitions</li>
      </h3>
      <p className="my-6">
        “<b>We/Us/Our</b>” means Zexus Finance, owned and operated by Zexus Group Co.
      </p>
      <p className="my-6">
        “<b>User/You/Your</b>” means an individual and/ or company who use our service; If you use
        our service on behalf of a company or other entity, then “you” includes you and that entity,
        and such other entity shall be deemed to have accepted this Policy via your continued access
        and use of our platform on the entity&apos;s behalf.
      </p>
      <p className="my-6">
        “<b>NFT</b>” means any non-fungible token tracked, traded and issued on the relevant
        blockchain.
      </p>
      <h3>
        <li className="my-6">User Account and Accessing Our Service</li>
      </h3>
      <p className="my-6">
        You will need a blockchain address and a third-party wallet to access our services. Your
        blockchain address will serve as your identity on our platform. Zexus Finance does not
        operate or maintain any cryptocurrency wallet. By connecting your third-party wallet, you
        agree that you alone will be responsible for all activities that occur under your account;
        we do not have custody or control over the contents of your wallet and has no ability to
        retrieve or transfer its contents. We accept no responsibility for or liability to you in
        connection with your use of a third-party wallet, and make no representations or warranties
        regarding how our service will operate with any specific wallet.{' '}
      </p>
      <p className="my-6">
        <b>
          By using this platform, you represent and warrant that you (1) are at least 18 years old;
          (2) are not barred from using the platform under any applicable law; (3) are solely
          responsible for ensuring that your access and use of our service in such country,
          territory or jurisdiction does not violate any applicable laws.
        </b>
      </p>
      <p className="my-6">
        We reserve the right to block, suspend, or disable your access to our platform at any time
        without notice to you in our sole discretion.
      </p>
      <h3>
        <li className="my-6">Intellectual Property Rights</li>
      </h3>
      <p className="my-6">
        Our platform, including without limitation its appearance (e.g., our banners, logos,
        graphics, icons, sounds), as well as any names or slogans used in a nature to identify our
        services, whether registered as a trademark or otherwise, is the proprietary property of
        Zexus Group Co. You shall not copy, imitate, or use any such aforementioned material without
        our prior written consent. We reserve all rights in connection with the platform and its
        material.{' '}
      </p>
      <p className="my-6">
        By using our service, you (and/or any other applicable entity) shall retain ownership
        relating to the content that you submit or post on or through our service, including without
        limitation NFTs and the associated digital files, and text, materials, images, files,
        communications, comments, feedback, suggestions, ideas, concepts, or questions. However, you
        hereby grant us a worldwide, non-exclusive, sub-licensable, and royalty-free license to
        display, use, reproduce, and modify (if needed) all of the aforementioned content for our
        current and future business purposes, including to provide, promote, and improve our
        service.
      </p>
      <p className="my-6">
        You represent and warrant that you have, or have obtained, all rights, licenses,
        permissions, consents, power, or authority necessary to grant the rights provided above. You
        represent and warrant that such content does not contain material subject to copyright,
        trademark, publicity rights, or other intellectual property rights that will interfere with
        the use of the contents by us, and that the content, as presented on our platform, does not
        violate any applicable law.
      </p>
      <h3>
        <li className="my-6">Disclaimer of Warranties and Limitation of Liability</li>
      </h3>
      <p className="my-6">
        YOU UNDERSTAND AND AGREE WE ARE NOT A WALLET OPERATOR, CRYPTO EXCHANGE, FINANCIAL
        INSTITUTION, OR CUSTODIAN. WE ARE NOT APPROVED OR LICENSED AS ANY OF THE ABOVE IN ANY
        JURISDICTION BY ANY FINANCIAL AUTHORITY OR REGULATOR. YOU UNDERSTAND AND FURTHER AGREE THAT
        OUR SERVICE IS PROVIDED ON AN “AS AVAILABLE” BASIS, AND WE EXPRESSLY DISCLAIM WARRANTIES,
        CONDITIONS, OR RESPONSIBILITIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, FOR WHETHER OUR
        SERVICE: (1) WILL MEET YOUR REQUIREMENTS (INCLUDING WITHOUT LIMITATION WHETHER THE LOAN WILL
        BE REPAID, OR WHETHER THE NFT WILL BE MERCHANTABLE OR FREE OF ANY LICENSES OR CLAIMS); (2)
        WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS (INCLUDING
        WITHOUT LIMITATION WHETHER THE SMART CONTRACTS WILL FAIL); OR (3) WILL BE ACCURATE,
        RELIABLE, COMPLETE, VIRUS-FREE, OR SAFE (INCLUDING WITHOUT LIMITATION WHETHER THE NFT WILL
        BE STOLEN AND TRANSFERRED BY HACKERS).
      </p>
      <p className="my-6">
        WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. NO ADVICE OR
        INFORMATION, WHETHER ORAL OR OBTAINED FROM ZEXUS FINANCE OR THROUGH OUR SERVICE, WILL CREATE
        ANY WARRANTY OR REPRESENTATION NOT EXPRESSLY MADE HEREIN. YOU ACCEPT THE INHERENT SECURITY
        RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD US
        RESPONSIBLE FOR ANY BREACH OF SECURITY.
      </p>
      <p className="my-6">
        WE WILL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES OR CLAIMS ARISING FROM (1) USER ERROR,
        INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (2) SERVER, SMART CONTRACT, OR
        PROTOCOL FAILURE OR DATA LOSS; (3) UNAUTHORIZED ACCESS OR USE; OR (4) ANY UNAUTHORIZED
        THIRD-PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING,
        BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST OUR SERVICE. IN NO EVENT SHALL WE BE HELD
        LIABLE TO YOU FOR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY NATURE WHATSOEVER,
        REGARDLESS OF THE LEGAL THEORY ON WHICH SUCH CLAIM MAY BE MADE.
      </p>
      <p className="my-6">
        IF, FOR ANY REASON, WE ARE FOUND BY ANY COURT, TRIBUNAL OR AUTHORITY TO BE LIABLE FOR ANY
        COMPENSATION OR OTHER REMEDY TO YOU IN RELATION TO A SINGLE EVENT OR INCIDENT, THEN, TO THE
        MAXIMUM EXTENT PERMISSIBLE BY APPLICABLE LAW, OUR LIABILITY PER SUCH INCIDENT SHALL BE
        LIMITED TO AN AMOUNT EQUAL TO THE FEES COLLECTED BY US IN DIRECT RELATION TO SUCH INCIDENT.
      </p>
      <h3>
        <li className="my-6">Assumption of Risk</li>
      </h3>
      <p className="my-6">
        You accept and acknowledge that the value of NFTs and cryptocurrencies is highly volatile
        due to different market conditions. Lending and borrowing transactions based on NFT
        collateral are strongly related to the current market price of the NFT collateral and loan
        principal currency market prices. You understand that sharp fluctuations in the value of
        NFTs and cryptocurrencies may cause you to incur a financial loss in a transaction.
      </p>
      <p className="my-6">
        You understand that blockchain-based transactions are irreversible. We do not have any
        control over any public blockchain and have no ability to reverse any transactions on the
        blockchain. We further have no control over the contents and execution of smart contracts
        that you may interact with to perform your desired transaction.{' '}
      </p>
      <p className="my-6">
        You understand and acknowledge that there are risks associated with using blockchain-based
        products and services, including without limitation the risk associated with hardware,
        software (e.g., smart contract, protocols), and internet connections and the risk of
        operating third-party wallets. You accept and acknowledge that we will not be responsible
        for any errors, distortions, delays, or hacker activities you may experience when using our
        service.
      </p>
      <h3>
        <li className="my-6">User Conduct</li>
      </h3>
      <p className="my-6">
        You understand and agree that in the course of your use of our service, you shall not
        violate any law, contract or third-party right, and you agree to further refrain from
        engaging in the following conduct
      </p>
      <p className="my-6">
        i. Access and/or use another User&apos;s account without the permission of such other User;
      </p>
      <p className="my-6">
        ii. Bypass, circumvent or overcome any security or control measures implemented on our
        platform for the functionality of our service;
      </p>
      <p className="my-6">
        iii. Decompile, decode or reverse engineer any part or aspect of our platform and service;
      </p>
      <p className="my-6">
        iv. Use any unauthorized software or other automated means to access our service or extract
        data from our platform;
      </p>
      <p className="my-6">
        v. Use our platform to engage in any financial activity that is subject to license or
        registration from the relevant competent authorit(ies) in the relevant jurisdiction(s);
      </p>
      <p className="my-6">
        vi. Use our platform to engage in price manipulation, fraud, or other deceptive, misleading,
        or manipulative activity;
      </p>
      <p className="my-6">
        vii. Use our platform for or in connection with money laundering, terrorist financing, or
        other illicit financial activity; or
      </p>
      <p className="my-6">
        viii. Use our platform in any other manner not already stated above that would interfere or
        disrupt the other Users’ use of our service.
      </p>
      <h3>
        <li className="my-6">Indemnification</li>
      </h3>
      <p className="my-6">
        By agreeing to these Terms and accessing our service, you agree, to the fullest extent
        permitted by applicable law, to indemnify, defend, and hold harmless Zexus Group Co., and
        our employees, officers, directors, contractors, agents and representatives, from and
        against all actual or alleged claims, damages, expenses (including attorneys&apos; fees and
        expenses), and costs (including court costs) of any kind (collectively “<b>Claims</b>”),
        including, but not limited to, damages to persons or properties that are caused attributable
        to your violation or breach of any term of these Terms or applicable law, and/or your
        violation of the rights of a third party. You agree to promptly notify us of any such Claim
        against us and cooperate with us in defending such Claims. Zexus Group Co. shall have the
        right to fully direct its defense of such Claims without objection from you.
      </p>
      <h3>
        <li className="my-6">Data & Privacy</li>
      </h3>
      <p className="my-6">
        Please refer to our <Link to="../privacy">privacy policy</Link> for information about how we
        collect, use, and share/disclose personal data about you.
      </p>
      <h3>
        <li className="my-6">Termination</li>
      </h3>
      <p className="my-6">
        Your access to our service shall be immediately and automatically terminated, whether
        temporarily or permanently, at our sole discretion upon your breach of any of the provisions
        of these Terms. In such event, you understand and agree that we shall not be held liable to
        you for any claim you may have against us under any relevant legal theory whatsoever in
        relation to such revocation of access to our service.
      </p>
      <h3>
        <li className="my-6">Severability</li>
      </h3>
      <p className="my-6">
        If any portion of these Terms is found to be invalid, void or unenforceable, the remaining
        provisions shall nevertheless continue in full force without being impaired or invalidated
        in any way.
      </p>
      <h3>
        <li className="my-6">Amendment</li>
      </h3>
      <p className="my-6">
        We reserve the right to change or modify these Terms at any time and at our sole discretion.
        By continuing to access or use our service, you confirm your acceptance of the revised Terms
        and all of the terms and conditions incorporated therein by reference effective as of the
        date these Terms are updated.
      </p>
      <h3>
        <li className="my-6">Governing Law And Forum</li>
      </h3>
      <p className="my-6">
        These Terms shall be governed by and construed in accordance with the laws of the Republic
        of China (Taiwan). Any dispute, controversy, difference, or claim arising from or related to
        these Terms shall be settled by arbitration referred to the Chinese Arbitration Association
        in accordance with the Association&apos;s arbitration rules. The seat of arbitration shall
        be Taipei, Taiwan, and the language of the arbitration shall be English. The arbitral award
        shall be final and binding upon both parties.
      </p>
    </ol>
  </div>
)
