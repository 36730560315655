import { FC } from 'react'

import { ReactComponent as ActivityIndicatorIcon } from 'assets/icons/activityIndicator.svg'

type ActivityIndicatorProps = {
  className?: string
  fullScreen?: boolean
  whiteColor?: boolean
}

export const ActivityIndicator: FC<ActivityIndicatorProps> = props => {
  const { className, fullScreen, whiteColor = false } = props
  return (
    <div
      className={`flex ${
        fullScreen ? 'mx-auto my-auto h-[50vh]' : 'h-full'
      } w-full items-center justify-center ${className}`}
    >
      <ActivityIndicatorIcon
        className={
          whiteColor
            ? 'max-w-8 h-full max-h-8 w-full fill-white'
            : 'max-w-8 h-full max-h-8 w-full fill-primary-50'
        }
      />
    </div>
  )
}
