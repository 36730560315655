import { ColumnDefinition } from 'models/Table'

import { TableHeader } from './components/TableHeader'
import { TableRows, TableRowsProps } from './components/TableRows'

interface TableProps<T> {
  data: Array<T>
  columns: Array<ColumnDefinition<T>>
  headerBoldColsIndexes?: number[]
  bodyBoldColsIndexes?: number[]
  renderCell?: TableRowsProps<T>['renderCell']
}

// <T extends {}> -> https://stackoverflow.com/questions/47848828/how-to-create-stateless-functional-components-that-accept-generics/51585233#51585233
export const Table = <T extends {}>({
  data,
  columns,
  headerBoldColsIndexes,
  bodyBoldColsIndexes,
  renderCell,
}: TableProps<T>): JSX.Element => (
  <table className="w-full table-fixed text-left">
    <TableHeader columns={columns} boldColsIndexes={headerBoldColsIndexes} />
    <TableRows
      data={data}
      columns={columns}
      boldColsIndexes={bodyBoldColsIndexes}
      renderCell={renderCell}
    />
  </table>
)
