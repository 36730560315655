type StoreAlert = {
  message: string
}

export enum StoreModalType {
  // these types are for the simple modals with no styles but custom title and/or message
  ERROR_MESSAGE,
  INFO_MESSAGE,
  SIMPLE_MESSAGE,
  SUCCESS_MESSAGE,
  WARNING_MESSAGE,
  // these are predefined modal types
  GENERAL_ERROR,
  NETWORK_ERROR,
  NOT_FOUND,
}

type StoreModal = {
  icon?: JSX.Element
  message?: string
  title?: string
  type: StoreModalType
  url?: string
}

type StoreToast = {
  message: string
}

export type { StoreAlert, StoreModal, StoreToast }
