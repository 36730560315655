import { useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { StoreModal, StoreModalType } from 'models/Comm'
import { shiftModal } from 'store/slices/comm/comm.actions'
import { commStateSelector } from 'store/slices/comm/comm.slice'

import {
  GeneralErrorModal,
  NetworkErrorModal,
  NotFoundModal,
  SimpleModal,
} from 'components/comm/predefined-modals'

import { ErrorModal } from '../predefined-modals/ErrorModal'
import { InfoModal } from '../predefined-modals/InfoModal'
import { SuccessModal } from '../predefined-modals/SuccessModal'
import { WarningModal } from '../predefined-modals/WarningModal'

export const GlobalModal = () => {
  const [storeModal, setStoreModal] = useState<StoreModal | null>(null)

  const { modalStack } = useAppSelector(commStateSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setStoreModal(modalStack.length > 0 ? modalStack[0] : null)
  }, [modalStack])

  const handleOpenChange = (open: boolean) => {
    if (!open) dispatch(shiftModal())
  }

  const renderContent = (modal: StoreModal | null) => {
    if (!modal) return null
    switch (modal?.type) {
      case StoreModalType.ERROR_MESSAGE:
        return (
          <ErrorModal
            isOpen={!!storeModal}
            message={modal?.message}
            onOpenChange={handleOpenChange}
            title={storeModal?.title}
          />
        )
      case StoreModalType.INFO_MESSAGE:
        return (
          <InfoModal
            isOpen={!!storeModal}
            message={modal?.message}
            onOpenChange={handleOpenChange}
            title={storeModal?.title}
          />
        )
      case StoreModalType.SUCCESS_MESSAGE:
        return (
          <SuccessModal
            isOpen={!!storeModal}
            message={modal?.message}
            onOpenChange={handleOpenChange}
            title={storeModal?.title}
          />
        )
      case StoreModalType.WARNING_MESSAGE:
        return (
          <WarningModal
            isOpen={!!storeModal}
            message={modal?.message}
            onOpenChange={handleOpenChange}
            title={storeModal?.title}
          />
        )
      case StoreModalType.GENERAL_ERROR:
        return <GeneralErrorModal isOpen={!!storeModal} onOpenChange={handleOpenChange} />
      case StoreModalType.NETWORK_ERROR:
        return <NetworkErrorModal isOpen={!!storeModal} onOpenChange={handleOpenChange} />
      case StoreModalType.NOT_FOUND:
        return <NotFoundModal isOpen={!!storeModal} onOpenChange={handleOpenChange} />
      default:
        return (
          <SimpleModal
            icon={storeModal?.icon}
            isOpen={!!storeModal}
            message={modal?.message}
            onOpenChange={handleOpenChange}
            title={storeModal?.title}
          />
        )
    }
  }

  return renderContent(storeModal)
}
