import { forwardRef, PropsWithChildren } from 'react'

import dayjs from 'dayjs'

import { useAppSelector } from 'hooks/store.hooks'
import { LoansFilter } from 'models/Filter'
import { Loan } from 'models/Loan'
import { RequestStatus } from 'store/helpers'
import { loansStateSelector } from 'store/slices/loans/loans.slice'

import { ActivityIndicator, Link, Tile } from 'components'

import { LoansFilters } from './components/LoansFilters'

interface LoansViewProps {
  loans?: Loan[]
  onFiltersChange: (value: LoansFilter) => void
}
export const LoansView = forwardRef<HTMLDivElement, PropsWithChildren<LoansViewProps>>(
  (props, scrollRef) => {
    const { loans, onFiltersChange } = props

    const { getLoansListStatus } = useAppSelector(loansStateSelector)

    return (
      <div className="container py-20">
        <h2 className="mb-4 text-third-10">Loans</h2>
        <div className="font-body-2 text-third-10 lg:w-[821px]">
          Here we presented all active loans. As a lender, you can make offers to active loans,
          which means you are proposing a loan renewal to the borrower.
        </div>
        <div className="mt-12 mb-[44px]">
          <LoansFilters onChange={onFiltersChange} />
        </div>
        <div className="flex flex-wrap justify-center gap-12 lg:justify-start">
          {getLoansListStatus === RequestStatus.PENDING && (
            <div className="flex h-[410px] w-full items-center justify-center">
              <ActivityIndicator />
            </div>
          )}
          {getLoansListStatus === RequestStatus.FULFILLED &&
            loans?.map((item, index) => {
              const { name, id, nfts, apr, currency, principal, dueDate } = item
              const key = `${id}_${index}`
              const date = dayjs(dueDate).format('[DUE] DD MMM')
              return (
                <Link to={`/loans/${id}`} internal key={key}>
                  <Tile
                    apr={apr}
                    currency={currency}
                    id={id}
                    nfts={nfts}
                    principal={principal}
                    subtitle={date}
                    title={name}
                    type="loan"
                  />
                </Link>
              )
            })}
        </div>
        {loans && (
          <div className="end-of-list invisible relative h-[0px]">
            <div
              ref={scrollRef}
              className="virtual absolute -top-[400px] h-[420px] w-full bg-cyan-200"
            />
          </div>
        )}
      </div>
    )
  }
)
