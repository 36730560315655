import { FC } from 'react'

import { Close } from '@radix-ui/react-dialog'

import { getButtonClasses, Modal } from 'components'

interface CancelOfferModalProps {
  isOpen: boolean
  onClose: () => void
  onOpenChange: (open: boolean) => void
  onCancel: () => void
}

const primaryButtonClasses = getButtonClasses('medium', 'primary', 'default', true, false)
const secondaryButtonClasses = getButtonClasses('medium', 'secondary', 'default', true, false)

export const CancelOfferModal: FC<CancelOfferModalProps> = ({
  isOpen,
  onCancel,
  onOpenChange,
  onClose,
}) => {
  const renderBottomBar = () => (
    <>
      <Close onClick={onClose} className={secondaryButtonClasses}>
        No, go back
      </Close>
      <Close onClick={onCancel} className={primaryButtonClasses}>
        Cancel offer
      </Close>
    </>
  )

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title="Cancel offer"
      renderBottomBar={renderBottomBar}
    >
      <div className="font-body-3">Are you sure you want to cancel offer?</div>
      <div className="mt-8 flex flex-col gap-1" />
    </Modal>
  )
}
