import { createSlice } from '@reduxjs/toolkit'

import { RequestStatus, RootState } from 'store/helpers'

import { resetWhiteListNft, whitelistNft } from './nft.actions'

type NftState = {
  whitelistNftStatus: RequestStatus
}

const initialState: NftState = {
  whitelistNftStatus: RequestStatus.IDLE,
}

export const nftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(whitelistNft.pending, state => {
        state.whitelistNftStatus = RequestStatus.PENDING
      })
      .addCase(whitelistNft.fulfilled, state => {
        state.whitelistNftStatus = RequestStatus.FULFILLED
      })
      .addCase(whitelistNft.rejected, state => {
        state.whitelistNftStatus = RequestStatus.REJECTED
      })
      .addCase(resetWhiteListNft.type, state => {
        state.whitelistNftStatus = RequestStatus.IDLE
      })
  },
})

export const nftStateSelector = (state: RootState) => state.nft

export default nftSlice.reducer
