import { useEffect, useState } from 'react'

import { readContract, signTypedData } from '@wagmi/core'
import { BigNumber } from 'ethers'
import { useNavigate, useParams } from 'react-router-dom'
import { Address } from 'wagmi'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useWallet } from 'hooks/useWallet'
import { blockchainCollateralOfferType, blockchainLoanType } from 'models/Blockchain'
import { StoreModalType } from 'models/Comm'
import { Currencies } from 'models/Currency'
import { CollateralOffer, LoanForBlockchain } from 'models/Loan'
import { Terms } from 'models/Terms'
import { pushModal } from 'store/slices/comm/comm.actions'
import {
  makeLoanOffer,
  requestTermsUpdate,
  resetLoanOfferId,
} from 'store/slices/loans/loans.actions'
import { loansStateSelector } from 'store/slices/loans/loans.slice'
import { setLoadingIdle, setLoadingPending } from 'store/slices/profile/profile.actions'
import { erc20Approve, getPrincipalFee, getUtilsDomain } from 'utils/api/blockchain'
import { ADDRESSES } from 'utils/constants'
import zexusStorageAbi from 'utils/contracts/ZexusStorage.json'
import { getDeadlineInSeconds } from 'utils/date'
import { calculateRepayment, convertFromBigNumber, convertToBigNumber } from 'utils/form'

import { MakeOfferConfirmationModal } from 'components/offer'

import { RequestTermsUpdateConfirmationModal } from './components/RequestTermsUpdateConfirmationModal'
import { MakeLoanOfferView } from './MakeLoanOfferView'

export const MakeLoanOfferScreen = () => {
  const { loanDetails, loanOfferId } = useAppSelector(loansStateSelector)
  const navigate = useNavigate()

  useEffect(() => {
    if (!loanDetails) navigate('/loans')
  }, [loanDetails])

  // the next line is a must to fulfill typescript checks + it is advised to use useNavigate inside of useEffect
  if (!loanDetails) return null

  const originalTerms: Terms = {
    allowDealNow: loanDetails.allowDealNow,
    apr: loanDetails.apr,
    currency: loanDetails.currency as Exclude<Currencies, Currencies.ALL>,
    duration: loanDetails.duration,
    principal: loanDetails.principal,
    repayment: loanDetails.repayment,
  }

  const [terms, setTerms] = useState<Terms>({
    ...originalTerms,
    principal: convertFromBigNumber(originalTerms.principal, originalTerms.currency) as string,
    expirationDate: 3,
  })

  const [isMakeOfferSuccessModalVisible, setIsMakeOfferSuccessModalVisible] = useState(false)
  const [isRequestTermsUpdateSuccessModalVisible, setIsRequestTermsUpdateSuccessModalVisible] =
    useState(false)

  const { id } = useParams()
  const { address, chain } = useWallet()
  const dispatch = useAppDispatch()

  // if current user is a borrower - this is loan extension
  const isCurrentUserBorrower = loanDetails?.borrower === address

  useEffect(() => {
    if (!terms.apr || !terms.duration || !terms.principal) {
      setTerms((prevState: Terms) => ({ ...prevState, repayment: undefined }))
      return
    }

    const repaymentValue = calculateRepayment(
      terms.apr,
      terms.duration,
      terms.principal,
      terms.currency
    )

    setTerms((prevState: Terms) => ({ ...prevState, repayment: repaymentValue }))
  }, [terms.apr, terms.duration, terms.principal, terms.currency])

  const handleTermsChange = (newTerms: Partial<Terms>) => {
    setTerms((prevState: Terms) => ({ ...prevState, ...newTerms }))
  }

  useEffect(() => {
    if (loanOfferId) {
      if (isCurrentUserBorrower) {
        setIsRequestTermsUpdateSuccessModalVisible(true)
      } else {
        setIsMakeOfferSuccessModalVisible(true)
      }
      dispatch(resetLoanOfferId())
    }
  }, [loanOfferId])

  const handleSendOffer = async () => {
    dispatch(setLoadingPending())
    if (!terms.principal || !terms.apr || !terms.duration || !id) return

    const loanDeadline = String(getDeadlineInSeconds(terms.expirationDate as number))

    const nonce = (await readContract({
      address: ADDRESSES.ZEXUS_STORAGE as Address,
      abi: zexusStorageAbi,
      functionName: 'nonces',
      args: [address as Address, loanDetails.collateralId],
    })) as BigNumber

    const hexNonce: BigNumber = nonce as BigNumber
    const hex = hexNonce.toHexString()
    const loan: LoanForBlockchain = {
      value: convertToBigNumber(terms.principal, terms.currency, false) as BigNumber,
      interestRate: terms.apr,
      duration: terms.duration,
      currency: ADDRESSES[terms.currency] as Address,
      creator: address,
      collateralId: loanDetails.collateralId,
      nonce,
      deadline: BigNumber.from(loanDeadline),
    }

    const approveResult = await erc20Approve(
      loan.value,
      loan.currency as Address,
      ADDRESSES.ZEXUS_BORROWER as Address
    )
    const approveTransactionReceipt = await approveResult.wait()

    if (approveTransactionReceipt.status !== 1) {
      dispatch(setLoadingIdle())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
      return
    }

    const signature = await signTypedData({
      domain: getUtilsDomain(chain?.id as number),
      types: blockchainLoanType,
      value: loan,
    })

    dispatch(makeLoanOffer({ id, signature, nonce: hex, deadline: loanDeadline, terms }))
  }

  const handleCancel = () => {
    navigate(`/loans/${id}`)
  }

  const handleMakeOfferSuccessModalOpenChange = (open: boolean) => {
    dispatch(setLoadingIdle())
    if (!open) {
      navigate('/loans')
    }
  }

  const handleRequestTermsUpdateSuccessModalOpenChange = (open: boolean) => {
    dispatch(setLoadingIdle())
    if (!open) {
      navigate('/profile')
    }
  }

  const handleSendTermsUpdate = async () => {
    dispatch(setLoadingPending())
    if (!terms.principal || !terms.apr || !terms.duration || !id) return

    const loanDeadline = String(getDeadlineInSeconds(terms.expirationDate as number))

    const nonce = (await readContract({
      address: ADDRESSES.ZEXUS_STORAGE as Address,
      abi: zexusStorageAbi,
      functionName: 'nonces',
      args: [address as Address, loanDetails.collateralId],
    })) as BigNumber

    const hexNonce: BigNumber = nonce as BigNumber
    const hex = hexNonce.toHexString()

    const loan: CollateralOffer = {
      idCollateral: loanDetails.collateralId,
      value: convertToBigNumber(terms.principal, terms.currency, false) as BigNumber,
      interestRate: terms.apr,
      duration: terms.duration,
      currency: ADDRESSES[terms.currency] as Address,
      loaner: loanDetails.lender,
      nonce,
      deadline: BigNumber.from(loanDeadline),
    }

    const principalBN: BigNumber = convertToBigNumber(
      terms.principal,
      terms.currency,
      false
    ) as BigNumber

    const originalPrincipalNumber = convertFromBigNumber(
      loanDetails.principal,
      loanDetails.currency,
      false
    )

    const originalPrincipalBN: BigNumber = convertToBigNumber(
      originalPrincipalNumber,
      loanDetails.currency,
      false
    ) as BigNumber

    const fee = await getPrincipalFee(1, terms.apr, terms.duration, principalBN)

    // REQUEST TERMS UPDATE - LTE
    if (principalBN.lte(originalPrincipalBN)) {
      const valueToApprove =
        Number(convertFromBigNumber(loanDetails.repayment, loanDetails.currency)) -
        Number(terms.principal) +
        Number(convertFromBigNumber(fee, terms.currency))

      const approveResult = await erc20Approve(
        convertToBigNumber(valueToApprove, terms.currency, false) as BigNumber,
        ADDRESSES[terms.currency as Exclude<Currencies, Currencies.ALL>] as Address,
        ADDRESSES.ZEXUS_LENDER as Address
      )
      const approveTransactionReceipt = await approveResult.wait()
      if (approveTransactionReceipt.status !== 1) {
        dispatch(setLoadingIdle())
        dispatch(
          pushModal({
            type: StoreModalType.GENERAL_ERROR,
          })
        )
        return
      }
    }

    const signature = await signTypedData({
      domain: getUtilsDomain(chain?.id as number),
      types: blockchainCollateralOfferType,
      value: loan,
    })

    dispatch(
      requestTermsUpdate({
        id,
        signature,
        nonce: hex,
        deadline: loanDeadline,
        terms,
      })
    )
  }

  return (
    <>
      <MakeLoanOfferView
        id={id}
        isLoanExtension={isCurrentUserBorrower}
        onCancel={handleCancel}
        onSendOffer={
          isCurrentUserBorrower
            ? () => handleSendTermsUpdate().catch(() => dispatch(setLoadingIdle()))
            : () => handleSendOffer().catch(() => dispatch(setLoadingIdle()))
        }
        originalTerms={originalTerms}
        terms={terms}
        onTermsChange={handleTermsChange}
      />
      <MakeOfferConfirmationModal
        borrowerAddress={loanDetails.borrower}
        isOpen={isMakeOfferSuccessModalVisible}
        onOpenChange={handleMakeOfferSuccessModalOpenChange}
      />
      <RequestTermsUpdateConfirmationModal
        lenderAddress={loanDetails.lender}
        isOpen={isRequestTermsUpdateSuccessModalVisible}
        onOpenChange={handleRequestTermsUpdateSuccessModalOpenChange}
      />
    </>
  )
}
