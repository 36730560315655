export enum SortOptions {
  '-created_at' = 'Newest',
  created_at = 'Oldest',
  '-principal' = 'Descending principal amount',
  principal = 'Ascending principal amount',
  due_date = 'Earliest due date',
  '-due_date' = 'Latest due date',
  expiry_date = 'Earliest expiry date',
  '-expiry_date' = 'Latest expiry date',
  valuation = 'Ascending valuation',
  '-valuation' = 'Descending valuation',
  collection_name = 'Collection name ascending',
  '-collection_name' = 'Collection name descending',
  '-apr' = 'Descending APR',
  apr = 'Ascending APR',
}

export type SortItem = keyof typeof SortOptions
