import { FC } from 'react'

import { Address } from 'wagmi'

import { Currencies } from 'models/Currency'
import { shortAddress } from 'utils/address'
import { convertFromBigNumber } from 'utils/form'

import { Button, SuccessModal } from 'components'

type DealNowConfirmationModalProps = {
  borrowerAddress: Address
  currency: Currencies
  isOpen: boolean
  loanId: number
  onOpenChange: (open: boolean) => void
  onPrimaryButtonClick: () => void
  onSecondaryButtonClick: () => void
  principal: string
}

export const DealNowConfirmationModal: FC<DealNowConfirmationModalProps> = ({
  borrowerAddress,
  currency,
  isOpen,
  loanId,
  onOpenChange,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  principal,
}) => {
  const renderBottomBar = () => (
    <div className="mt-8 flex items-center justify-end gap-6">
      <Button onClick={onSecondaryButtonClick} variant="secondary">
        See it in My profile
      </Button>
      <Button onClick={onPrimaryButtonClick} variant="primary">
        Got it
      </Button>
    </div>
  )

  const successModalMessage = (
    <div>
      <div className="font-body-3">
        You have successfully lent{' '}
        <span className="font-semibold">
          {convertFromBigNumber(principal, currency)} {currency}
        </span>{' '}
        to user <span className="font-semibold">{shortAddress(borrowerAddress)}</span>.
      </div>
      <div className="font-body-5 mt-5">
        You may check the loan details in My profile &#8594; My loans &#8594; Loan #{loanId}.
      </div>
    </div>
  )

  return (
    <SuccessModal
      title="Confirmation"
      isOpen={isOpen}
      message={successModalMessage}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    />
  )
}
