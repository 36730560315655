import { useEffect, useRef } from 'react'

import _ from 'lodash'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useIntersectingObserver } from 'hooks/useIntersectingObserver'
import { CollateralsFilter } from 'models/Filter'
import { getCollateralsList, resetCollaterals } from 'store/slices/collaterals/collaterals.actions'
import { collateralStateSelector } from 'store/slices/collaterals/collaterals.slice'

import { CollateralsView } from './CollateralsView'

const COLLATERALS_PER_PAGE = 20

export const CollateralsScreen = () => {
  const filters = useRef<CollateralsFilter>()
  const currentPage = useRef<number>(0)
  const dispatch = useAppDispatch()
  const { collaterals, count } = useAppSelector(collateralStateSelector)
  const abortController = useRef<any>()

  const getCollaterals = () => {
    abortController.current = dispatch(
      getCollateralsList({
        limit: COLLATERALS_PER_PAGE,
        offset: COLLATERALS_PER_PAGE * currentPage.current,
        filters: filters.current,
      })
    )
  }

  const onScroll = () => {
    if (collaterals.length < count) {
      currentPage.current += 1
      getCollaterals()
    }
  }

  const handleFiltersChange = (value: CollateralsFilter) => {
    if (_.isEqual(value, filters.current)) return
    filters.current = value
    currentPage.current = 0
    abortController.current?.abort()
    dispatch(resetCollaterals())
    getCollaterals()
  }

  useEffect(
    () => () => {
      abortController.current?.abort()
      dispatch(resetCollaterals())
    },
    []
  )

  const { elementRef: scrollRef } = useIntersectingObserver(onScroll)

  return (
    <CollateralsView
      ref={scrollRef}
      collaterals={collaterals}
      onFiltersChange={handleFiltersChange}
    />
  )
}
