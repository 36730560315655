import { FC } from 'react'

import * as Dialog from '@radix-ui/react-dialog'

import { getButtonClasses, SuccessModal } from 'components'

const primaryButtonClasses = getButtonClasses('small', 'primary', 'default', true, false)

type DeleteCollateralConfirmModalProps = {
  collateralName: string
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

export const DeleteCollateralConfirmModal: FC<DeleteCollateralConfirmModalProps> = props => {
  const { collateralName, isOpen, onOpenChange } = props

  const renderBottomBar = () => (
    <Dialog.Close onClick={() => onOpenChange(false)} className={primaryButtonClasses}>
      Got it
    </Dialog.Close>
  )

  return (
    <SuccessModal
      title="Confirmation"
      isOpen={isOpen}
      message={`Your ${collateralName} offer has been deleted.`}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    />
  )
}
