import { FC } from 'react'

import { Modal } from 'components'

import { SimpleModalProps } from './SimpleModal'

import { ReactComponent as CheckBadgeIcon } from 'assets/icons/checkBadge.svg'

export const SuccessModal: FC<SimpleModalProps> = props => {
  const { isOpen, message, onOpenChange, renderBottomBar, title } = props
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      icon={<CheckBadgeIcon className="stroke-success-50" />}
      renderBottomBar={renderBottomBar}
      title={title || 'Success!'}
    >
      {message}
    </Modal>
  )
}
