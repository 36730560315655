import { createSlice } from '@reduxjs/toolkit'

import { Collateral } from 'models/Collateral'
import { Loan } from 'models/Loan'
import { RequestStatus, RootState } from 'store/helpers'

import { getHomeLists } from './home.actions'

export type HomeState = {
  collaterals: Collateral[]
  loans: Loan[]
  getHomeListsStatus: RequestStatus
}

const initialState: HomeState = {
  collaterals: [],
  loans: [],
  getHomeListsStatus: RequestStatus.IDLE,
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getHomeLists.pending, state => {
        state.getHomeListsStatus = RequestStatus.PENDING
      })
      .addCase(getHomeLists.fulfilled, (state, action) => {
        state.collaterals = action.payload.offers
        state.loans = action.payload.loans
        state.getHomeListsStatus = RequestStatus.FULFILLED
      })
      .addCase(getHomeLists.rejected, state => {
        state.getHomeListsStatus = RequestStatus.REJECTED
      })
  },
})

export const homeStateSelector = (state: RootState) => state.home

export default homeSlice.reducer
