import * as yup from 'yup'

export const addressValidationSchema = yup
  .string()
  .matches(/^0x[a-fA-F0-9]{40}$/, { message: 'Please provide valid address.' })

export const durationValidationSchema: yup.BaseSchema = yup
  .number()
  .required()
  .positive()
  .integer()
  .min(8, data => `Duration must be minimum ${data.min} days`)
  .typeError('You must specify a number')

export const aprValidationSchema = (minApr: number): yup.BaseSchema =>
  yup.number().required().positive().min(minApr, 'New APR should not be lower than original rate.')

export const principalValidationSchema = (): yup.BaseSchema =>
  yup.number().required().positive().typeError('this must be a positive number')
