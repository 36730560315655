import { useEffect, useState } from 'react'

import { Address, readContract, signTypedData } from '@wagmi/core'
import { BigNumber } from 'ethers'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useWallet } from 'hooks/useWallet'
import { blockchainLoanType } from 'models/Blockchain'
import { StoreModalType } from 'models/Comm'
import { Currencies } from 'models/Currency'
import { LoanForBlockchain } from 'models/Loan'
import { Terms } from 'models/Terms'
import {
  makeCollateralOffer,
  resetCollateralOfferId,
} from 'store/slices/collaterals/collaterals.actions'
import { collateralStateSelector } from 'store/slices/collaterals/collaterals.slice'
import { pushModal } from 'store/slices/comm/comm.actions'
import { setLoadingIdle, setLoadingPending } from 'store/slices/profile/profile.actions'
import { erc20Approve, getUtilsDomain } from 'utils/api/blockchain'
import { ADDRESSES } from 'utils/constants'
import zexusStorageAbi from 'utils/contracts/ZexusStorage.json'
import { getDeadlineInSeconds } from 'utils/date'
import { calculateRepayment, convertFromBigNumber, convertToBigNumber } from 'utils/form'

import { MakeOfferConfirmationModal } from 'components/offer'

import { MakeOfferView } from './MakeCollateralOfferView'

export const MakeCollateralOfferScreen = () => {
  const { collateralDetails, collateralOfferId } = useAppSelector(collateralStateSelector)
  const navigate = useNavigate()
  const { chain } = useWallet()

  useEffect(() => {
    if (!collateralDetails) navigate('/collaterals')
  }, [collateralDetails])

  // the next line is a must to fulfill typescript checks + it is advised to use useNavigate inside of useEffect
  if (!collateralDetails) return null

  const originalTerms: Terms = {
    allowDealNow: collateralDetails.allowDealNow,
    apr: collateralDetails.apr,
    currency: collateralDetails.currency as Exclude<Currencies, Currencies.ALL>,
    duration: collateralDetails.duration,
    principal: collateralDetails.principal,
    repayment: collateralDetails.repayment,
  }

  const [terms, setTerms] = useState<Terms>({
    ...originalTerms,
    principal: convertFromBigNumber(originalTerms.principal, originalTerms.currency) as string,
    expirationDate: 3,
  })

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false)

  const { id } = useParams()
  const { address } = useWallet()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!terms.apr || !terms.duration || !terms.principal) {
      setTerms({ ...terms, repayment: undefined })
      return
    }

    const repaymentValue = calculateRepayment(
      terms.apr,
      terms.duration,
      terms.principal,
      terms.currency
    )
    setTerms({ ...terms, repayment: repaymentValue })
  }, [terms.apr, terms.duration, terms.principal, terms.currency])

  const handleTermsChange = (newTerms: Partial<Terms>) => {
    setTerms((prevState: Terms) => ({ ...prevState, ...newTerms }))
  }

  useEffect(() => {
    if (collateralOfferId) {
      setIsSuccessModalVisible(true)
      dispatch(resetCollateralOfferId())
    }
  }, [collateralOfferId])

  const handleSendOffer = async () => {
    dispatch(setLoadingPending())
    if (!terms.principal || !terms.apr || !terms.duration || !id) return

    const loanDeadline = String(getDeadlineInSeconds(terms.expirationDate as number))

    const nonce = (await readContract({
      address: ADDRESSES.ZEXUS_STORAGE as Address,
      abi: zexusStorageAbi,
      functionName: 'nonces',
      args: [address as Address, collateralDetails.collateralId],
    })) as BigNumber
    const hexNonce: BigNumber = nonce as BigNumber
    const hex = hexNonce.toHexString()
    const loan: LoanForBlockchain = {
      value: convertToBigNumber(terms.principal, terms.currency, false) as BigNumber,
      interestRate: terms.apr,
      duration: terms.duration,
      currency: ADDRESSES[terms.currency] as Address,
      creator: address,
      collateralId: collateralDetails.collateralId,
      nonce,
      deadline: BigNumber.from(loanDeadline),
    }

    const approveResult = await erc20Approve(
      loan.value,
      loan.currency as Address,
      ADDRESSES.ZEXUS_BORROWER as Address
    )
    const approveTransactionReceipt = await approveResult.wait()

    if (approveTransactionReceipt.status !== 1) {
      dispatch(setLoadingIdle())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
      return
    }

    const signature = await signTypedData({
      domain: getUtilsDomain(chain?.id as number),
      types: blockchainLoanType,
      value: loan,
    })

    dispatch(
      makeCollateralOffer({
        id,
        signature,
        nonce: hex,
        deadline: loanDeadline,
        terms,
      })
    )
  }

  const handleCancel = () => {
    navigate(`/collaterals/${id}`)
  }

  const handleSuccessModalOpenChange = (open: boolean) => {
    if (!open) {
      navigate('/collaterals')
    }
  }

  return (
    <>
      <MakeOfferView
        id={id}
        onCancel={handleCancel}
        onSendOffer={() => handleSendOffer().catch(() => dispatch(setLoadingIdle()))}
        originalTerms={originalTerms}
        terms={terms}
        onTermsChange={handleTermsChange}
      />
      <MakeOfferConfirmationModal
        borrowerAddress={collateralDetails.borrower}
        isOpen={isSuccessModalVisible}
        onOpenChange={handleSuccessModalOpenChange}
      />
    </>
  )
}
