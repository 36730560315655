import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import {
  AddCollateralScreen,
  BetaScreen,
  CollateralDetailScreen,
  CollateralsScreen,
  DemoScreen,
  Error404Screen,
  HomeScreen,
  LoanDetailScreen,
  LoansScreen,
  MakeCollateralOfferScreen,
  MakeLoanOfferScreen,
  PrivacyPolicyScreen,
  ProfileCollateralsScreen,
  ProfileLoansScreen,
  ProfileOffersScreen,
  ProfileScreen,
  TermsServiceScreen,
} from 'screens'

import {
  AlertContainer,
  Footer,
  GlobalModal,
  Navigation,
  ProtectedElement,
  ToastContainer,
} from 'components'
import PreventReload from 'components/prevent-reload/PreventReload'
import ScrollToTop from 'components/scroll-to-top/ScrollToTop'

const AppContainer = () => (
  <Router>
    <ScrollToTop />
    <PreventReload />
    <Navigation />
    <main className="mb-auto">
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route
          path="collaterals/new"
          element={
            <ProtectedElement>
              <AddCollateralScreen />
            </ProtectedElement>
          }
        />
        <Route path="collaterals/:id" element={<CollateralDetailScreen />} />
        <Route
          path="collaterals/:id/offer"
          element={
            <ProtectedElement>
              <MakeCollateralOfferScreen />
            </ProtectedElement>
          }
        />
        <Route path="collaterals" element={<CollateralsScreen />} />
        <Route path="loans/:id" element={<LoanDetailScreen />} />
        <Route
          path="loans/:id/offer"
          element={
            <ProtectedElement>
              <MakeLoanOfferScreen />
            </ProtectedElement>
          }
        />
        <Route path="loans" element={<LoansScreen />} />
        <Route
          path="profile"
          element={
            <ProtectedElement>
              <ProfileScreen />
            </ProtectedElement>
          }
        >
          <Route index element={<ProfileLoansScreen />} />
          <Route path="loans" element={<ProfileLoansScreen />} />
          <Route path="collaterals" element={<ProfileCollateralsScreen />} />
          <Route path="offers-made" element={<ProfileOffersScreen />} />
          <Route path="offers-received" element={<ProfileOffersScreen />} />
        </Route>
        <Route path="privacy" element={<PrivacyPolicyScreen />} />
        <Route path="terms" element={<TermsServiceScreen />} />
        <Route path="beta" element={<BetaScreen />} />
        <Route path="demo" element={<DemoScreen />} />
        <Route path="*" element={<Error404Screen />} />
      </Routes>
    </main>
    <Footer />
    <GlobalModal />
    <ToastContainer />
    <AlertContainer />
  </Router>
)

export default AppContainer
