import { Link } from 'components'

import { ReactComponent as DiscordIcon } from 'assets/icons/discord.svg'
import { ReactComponent as MediumIcon } from 'assets/icons/medium.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg'
import { ReactComponent as Logo } from 'assets/logo.svg'

export const Footer = () => (
  <footer className="bg-[url('assets/gradient.jpg')] bg-cover">
    <div className="container pt-12 pb-11">
      <div className="flex flex-col gap-10 lg:flex-row lg:gap-10 xl:gap-16">
        <div className="mr-auto flex flex-col gap-y-4">
          <div className="mb-2.5">
            <Logo className="h-[18px] w-[113px] fill-primary-95" />
          </div>
          <div className="font-header-h6 text-third-700 font-book lg:max-w-[390px]">
            Zexus Finance aims to provide diversified NFT financing solutions. More features to come
            in the future.
            <br />
            Follow us on social media to stay tuned!
          </div>
        </div>

        <div className="flex flex-col gap-y-4">
          <div className="font-header-h5 text-third-700">Marketplace</div>
          <Link to="collaterals" internal className="font-body-3 text-third-700">
            Collateral list
          </Link>
          <Link to="loans" internal className="font-body-3 text-third-700">
            Active loans
          </Link>
        </div>

        <div className="flex flex-col gap-y-4">
          <div className="font-header-h5 whitespace-nowrap text-third-700">My Account</div>
          <Link to="/profile/loans" internal className="font-body-3 text-third-90">
            My Loans
          </Link>
          <Link to="/profile/collaterals" internal className="font-body-3 text-third-700">
            My collaterals
          </Link>
          <Link to="/profile/offers-made" internal className="font-body-3 text-third-700">
            Offers made
          </Link>
          <Link to="/profile/offers-received" internal className="font-body-3 text-third-700">
            Offers received
          </Link>
        </div>

        <div className="flex flex-col gap-y-5">
          <div className="font-header-h5 whitespace-nowrap text-third-700">
            Join the community on
          </div>
          <div className="flex gap-7">
            <a href="https://discord.gg/YuzU5JCzfc" target="_blank" rel="noreferrer">
              <DiscordIcon className="fill-primary-90 transition-transform duration-300 hover:scale-90" />
            </a>
            <a href="https://twitter.com/zexusfinance" target="_blank" rel="noreferrer">
              <TwitterIcon className="fill-primary-90 transition-transform duration-300 hover:scale-90" />
            </a>
            <a href="https://medium.com/@zexus.finance" target="_blank" rel="noreferrer">
              <MediumIcon className="fill-primary-90 transition-transform duration-300 hover:scale-90" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="border-t border-primary-50">
      <div className="container flex items-center justify-end gap-8">
        <a
          className="font-button-2 text-third-90"
          href=" https://docs.zexus.finance/zexus-finance/"
          target="_blank"
          rel="noreferrer"
        >
          Docs
        </a>
        <Link to="privacy" internal className="font-button-2 text-third-90">
          Privacy policy
        </Link>
        <Link to="terms" internal className="font-button-2 text-third-90">
          Terms of service
        </Link>
      </div>
    </div>
  </footer>
)
