import { FC, useState } from 'react'

import { Terms } from 'models/Terms'
import { OfferSummary } from 'screens/collaterals/components/OfferSummary'
import { TermsForm } from 'screens/collaterals/components/TermsForm'
import {
  aprValidationSchema,
  durationValidationSchema,
  principalValidationSchema,
} from 'utils/validation'

import { ButtonBack } from 'components'

interface MakeLoanOfferViewProps {
  id: string | undefined
  isLoanExtension?: boolean
  onCancel: () => void
  onSendOffer: () => void
  originalTerms: Terms
  onTermsChange: (terms: Partial<Terms>) => void
  terms: Terms
}

export const MakeLoanOfferView: FC<MakeLoanOfferViewProps> = ({
  id,
  isLoanExtension,
  onCancel,
  onSendOffer,
  originalTerms,
  onTermsChange,
  terms,
}) => {
  const [isDurationValid, setIsDurationValid] = useState(
    durationValidationSchema.isValidSync(terms.duration)
  )
  const [isAprValid, setIsAprValid] = useState(
    terms.apr ? aprValidationSchema(terms.apr).isValidSync(terms.apr) : true
  )
  const [isPrincipalValid, setIsPrincipalValid] = useState(
    principalValidationSchema().isValidSync(terms.principal)
  )
  return (
    <div className="container py-10 lg:max-w-[1056px]">
      <ButtonBack text="Back to current loan" path={`/loans/${id}`} />
      <div className="mt-10 grid gap-x-4 gap-y-0 lg:grid-cols-detailsContainer">
        <TermsForm
          isMakeOffer={!isLoanExtension}
          isTermsUpdate={isLoanExtension}
          onAprValidation={setIsAprValid}
          onDurationValidation={setIsDurationValid}
          onPrincipalValidation={setIsPrincipalValid}
          onTermsChange={onTermsChange}
          originalTerms={originalTerms}
          terms={terms}
        />
        <OfferSummary
          isFormValid={isDurationValid && isAprValid && isPrincipalValid}
          isTermsUpdate={isLoanExtension}
          offerTerms={terms}
          onSendOffer={onSendOffer}
          onCancel={onCancel}
          originalTerms={originalTerms}
        />
      </div>
    </div>
  )
}
