import { createAction } from '@reduxjs/toolkit'
import { Address } from 'wagmi'

import { getStorageItem, removeStorageItem, setStorageItem } from 'hooks/useStorage'
import { createAppAsyncThunk, RequestStatus } from 'store/helpers'
import { authApi } from 'utils/api'
import { GetAuthTokenVars } from 'utils/api/auth'

export const getAuthMessage = createAppAsyncThunk<string | undefined, Address>(
  'auth/getAuthMessage',
  async (address, thunkAPI) => {
    const { getAuthMessageRequestId, getAuthMessageStatus } = thunkAPI.getState().auth
    // Allow only single request at a time
    if (
      getAuthMessageStatus !== RequestStatus.PENDING ||
      thunkAPI.requestId !== getAuthMessageRequestId
    ) {
      return undefined
    }
    return await authApi.getAuthMessage(address)
  }
)

export const getAuthToken = createAppAsyncThunk<string, GetAuthTokenVars>(
  'auth/getAuthToken',
  async variables => {
    const data = await authApi.getAuthToken(variables)
    // Store token for future use
    setStorageItem('token', data)
    setStorageItem('token.address', variables.address)
    return data
  }
)

export const resetAuth = createAction('auth/resetAuth')

export const logout = createAppAsyncThunk<void, void>('auth/logout', async (_, thunkAPI) => {
  const token = getStorageItem('token')

  if (token) {
    await authApi.deleteAuthToken({ token })
  }

  removeStorageItem('token')
  removeStorageItem('token.address')
  removeStorageItem('token.message')

  // We need also reset auth as soon as possible to be ready for new connection and
  // authentication without waiting for BE response
  thunkAPI.dispatch(resetAuth())
})
