import { FC } from 'react'

import * as ReactToast from '@radix-ui/react-toast'

type ToastProps = {
  message: string
  onClose?: () => void
}

export const Toast: FC<ToastProps> = props => {
  const { message, onClose } = props

  const handleOpenChange = (open: boolean) => {
    if (!open) onClose?.()
  }

  return (
    <ReactToast.Root onOpenChange={handleOpenChange} className="relative p-2">
      <div className="absolute inset-0 rounded-lg border border-third-70 bg-third-95 opacity-50" />
      <ReactToast.Description className="relative">{message}</ReactToast.Description>
    </ReactToast.Root>
  )
}
