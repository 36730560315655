import { useEffect } from 'react'

export const setStorageItem = (key: string, value: string) => {
  window.localStorage.setItem(key, value)
  window.dispatchEvent(new Event('local-storage'))
}

export const getStorageItem = (key: string): string | null => window.localStorage.getItem(key)

export const removeStorageItem = (key: string) => {
  window.localStorage.removeItem(key)
  window.dispatchEvent(new Event('local-storage'))
}

export const useStorage = (key: string, onStorageChange?: (value: string | null) => void) => {
  const storeValue = (value: string) => {
    setStorageItem(key, value)
  }

  const retrieveValue = () => getStorageItem(key)

  const removeValue = () => {
    removeStorageItem(key)
  }

  const handleStorageChange = () => {
    const value = getStorageItem(key)
    onStorageChange?.(value)
  }

  useEffect(() => {
    // react on storage changes initiated in other tabs
    window.addEventListener('storage', handleStorageChange)
    // react on storage changes initiated in current tab
    window.addEventListener('local-storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
      window.removeEventListener('local-storage', handleStorageChange)
    }
  }, [])

  return {
    storeValue,
    retrieveValue,
    removeValue,
  }
}
