import { FC } from 'react'

import * as Dialog from '@radix-ui/react-dialog'
import { Address } from 'wagmi'

import { shortAddress } from 'utils/address'

import { getButtonClasses, SuccessModal } from 'components'

type MakeOfferConfirmationModalProps = {
  borrowerAddress: Address
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

export const MakeOfferConfirmationModal: FC<MakeOfferConfirmationModalProps> = ({
  borrowerAddress,
  isOpen,
  onOpenChange,
}) => {
  const primaryButtonClasses = getButtonClasses('medium', 'primary', 'default', true, false)

  const renderBottomBar = () => (
    <Dialog.Close className={primaryButtonClasses}>Go back to marketplace</Dialog.Close>
  )

  const successModalMessage = (
    <div>
      <div className="font-body-3">
        You have sent loan offer to user {shortAddress(borrowerAddress)}.
      </div>
      <div className="font-body-5 mt-8">
        You can check this loan status in My profile &#8594; Offers made
      </div>
    </div>
  )

  return (
    <SuccessModal
      title="Confirmation"
      isOpen={isOpen}
      message={successModalMessage}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    />
  )
}
