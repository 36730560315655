import { useEffect } from 'react'

import { useAppSelector } from 'hooks/store.hooks'
import { RequestStatus } from 'store/helpers'
import { profileSelector } from 'store/slices/profile/profile.slice'

const PreventReload = () => {
  const { loadingState, loadingDeleteState } = useAppSelector(profileSelector)
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (loadingState === RequestStatus.PENDING || loadingDeleteState === RequestStatus.PENDING) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [loadingState, loadingDeleteState])

  return null
}

export default PreventReload
