import { FC, useEffect, useRef, useState } from 'react'

import * as Portal from '@radix-ui/react-portal'
import _debounce from 'lodash/debounce'

import { Currencies } from 'models/Currency'
import { CollateralsFilter, LoansFilter } from 'models/Filter'
import { SortItem } from 'models/Sort'

import {
  Button,
  FilterApr,
  FilterCurrency,
  FilterDuration,
  FilterPrincipal,
  Sort,
} from 'components'
import { TextInput } from 'components/form/text-input/TextInput'

import { ReactComponent as Close } from 'assets/icons/close.svg'
import { ReactComponent as Funnel } from 'assets/icons/funnel.svg'
import { ReactComponent as MagnifyingGlass } from 'assets/icons/magnifyingGlass.svg'

interface FiltersProps {
  onChange: (value: LoansFilter) => void
}

const DEFAULT_FILTERS: LoansFilter = {
  apr: { min: 1, max: 156 },
  currency: Currencies.ALL,
  duration: { min: 1, max: 365 },
  principal: { min: 0, max: 1000000 },
  search: '',
  sort: '-created_at',
}

const DEBOUNCE_SEARCH_TIME = 500
const SORT_ITEMS: Array<SortItem> = [
  '-created_at',
  'created_at',
  '-principal',
  'principal',
  '-due_date',
  'due_date',
]
const STORED_FILTERS_KEY = 'loanFilters'

export const LoansFilters: FC<FiltersProps> = ({ onChange }) => {
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState<LoansFilter>()
  const [areFiltersMobileVisible, setAreFiltersMobileVisible] = useState(false)

  useEffect(() => {
    const storedFilters = window.sessionStorage.getItem(STORED_FILTERS_KEY)
    let initialFilters: LoansFilter = DEFAULT_FILTERS
    if (storedFilters) {
      initialFilters = JSON.parse(storedFilters)
    }
    setFilters(initialFilters)
    setSearchValue(initialFilters.search)
  }, [])

  useEffect(() => {
    if (filters) {
      window.sessionStorage.setItem(STORED_FILTERS_KEY, JSON.stringify(filters))
      onChange(filters)
    }
  }, [filters])

  const updateFilters = (newFilters: Partial<CollateralsFilter>) => {
    setFilters(prevState => {
      if (!prevState) return prevState
      return {
        ...prevState,
        ...newFilters,
      }
    })
  }

  const handleDebouncedSearch = (value: string) => {
    updateFilters({ search: value })
  }

  const debounceSearch = useRef(_debounce(handleDebouncedSearch, DEBOUNCE_SEARCH_TIME)).current

  useEffect(() => () => debounceSearch.cancel(), [debounceSearch])

  const onSearchValueChange = (value: string) => {
    setSearchValue(value)
    debounceSearch(value)
  }

  return (
    <>
      <div className="hidden w-full items-end gap-4 md:flex">
        <TextInput
          className="h-full grow"
          value={searchValue}
          onValueChange={newValue => onSearchValueChange(newValue.value || '')}
          placeholder="Search"
          icon={<MagnifyingGlass />}
          iconPosition="left"
        />
        <div className="w-[158px]">
          <FilterApr
            onChange={value => updateFilters({ apr: value })}
            defaultValue={DEFAULT_FILTERS.apr}
            value={filters?.apr}
          />
        </div>
        <div className="w-[110px]">
          <FilterCurrency
            onChange={value => updateFilters({ currency: value })}
            defaultValue={DEFAULT_FILTERS.currency}
            value={filters?.currency}
          />
        </div>
        <div className="w-[158px]">
          <FilterPrincipal
            onChange={value => updateFilters({ principal: value })}
            defaultValue={DEFAULT_FILTERS.principal}
            value={filters?.principal}
          />
        </div>
        <div className="w-[158px]">
          <FilterDuration
            onChange={value => updateFilters({ duration: value })}
            defaultValue={DEFAULT_FILTERS.duration}
            value={filters?.duration}
          />
        </div>
        <div className="ml-[18px] w-[158px]">
          {SORT_ITEMS && (
            <Sort
              items={SORT_ITEMS}
              onChange={value => updateFilters({ sort: value })}
              defaultValue={DEFAULT_FILTERS.sort}
              value={filters?.sort}
            />
          )}
        </div>
      </div>

      <div className="flex w-full items-start gap-4 md:hidden">
        <TextInput
          className="h-full grow"
          value={searchValue}
          onValueChange={newValue => setSearchValue(newValue.value || '')}
          placeholder="Search"
          icon={<MagnifyingGlass />}
          iconPosition="left"
        />
        <Button
          onClick={() => {
            setAreFiltersMobileVisible(true)
          }}
          icon={<Funnel />}
        />

        <Portal.Root
          className={`fixed top-0 left-0 flex ${
            areFiltersMobileVisible ? 't-0 h-screen' : '-top-full h-0'
          } w-screen flex-col items-center justify-center gap-4 bg-third-95 transition-all`}
        >
          <Button
            theme="dark"
            className="absolute right-10 top-5"
            onClick={() => setAreFiltersMobileVisible(false)}
            icon={<Close />}
          />

          <div className="w-10/12 max-w-sm">
            <FilterApr
              onChange={value => updateFilters({ apr: value })}
              defaultValue={DEFAULT_FILTERS.apr}
              value={filters?.apr}
            />
          </div>
          <div className="w-10/12 max-w-sm">
            <FilterCurrency
              onChange={value => updateFilters({ currency: value })}
              defaultValue={DEFAULT_FILTERS.currency}
              value={filters?.currency}
            />
          </div>
          <div className="w-10/12 max-w-sm">
            <FilterPrincipal
              onChange={value => updateFilters({ principal: value })}
              defaultValue={DEFAULT_FILTERS.principal}
              value={filters?.principal}
            />
          </div>
          <div className="w-10/12 max-w-sm">
            <FilterDuration
              onChange={value => updateFilters({ duration: value })}
              defaultValue={DEFAULT_FILTERS.duration}
              value={filters?.duration}
            />
          </div>
          <div className="w-10/12 max-w-sm">
            {SORT_ITEMS && (
              <Sort
                items={SORT_ITEMS}
                onChange={value => updateFilters({ sort: value })}
                defaultValue={DEFAULT_FILTERS.sort}
                value={filters?.sort}
              />
            )}
          </div>
        </Portal.Root>
      </div>
    </>
  )
}
