import { Collateral, CollateralDetails, NFT } from 'models/Collateral'
import { Currencies } from 'models/Currency'
import { CollateralsFilter } from 'models/Filter'
import { MakeOfferData, MakeOfferVars } from 'models/Offer'
import { API_URLS } from 'utils/constants'
import { convertToBigNumber } from 'utils/form'

import API from './api'

export type GetCollateralsListResponse = {
  count: number
  results: Collateral[]
}

export type GetCollateralsListVars = {
  limit: number
  offset: number
  filters?: CollateralsFilter
  signal?: AbortSignal
}

export type GetCollateralDetailsVars = {
  id: string
}

export type CreateCollateralData = {
  id: number
  collateralIdBC: string
}

export type SetCollateralStatusReadyVars = {
  id: number
  collateralIdBC: string
}

export type CreateCollateralVars = {
  allowDealNow: boolean
  apr: number
  currency: Currencies
  duration: number
  name: string
  nfts: NFT[]
  principal: string
  repayment: string
}

export type SetDealNowStatusVars = { id: number }

export const getCollateralsList = async (
  variables: GetCollateralsListVars
): Promise<GetCollateralsListResponse> => {
  const { filters, limit, offset, signal } = variables
  const { data } = await API.get<GetCollateralsListResponse>(`${API_URLS.ZEXUS_API}/offers/`, {
    params: {
      limit,
      offset,
      currency: filters?.currency !== Currencies.ALL ? filters?.currency : undefined,
      apr_from: filters?.apr.min,
      apr_to: filters?.apr.max,
      duration_from: filters?.duration.min,
      duration_to: filters?.duration.max,
      principal_from: convertToBigNumber(filters?.principal.min, filters?.currency),
      principal_to: convertToBigNumber(filters?.principal.max, filters?.currency),
      name: filters?.search,
      sort_by: filters?.sort,
    },
    signal,
  })

  const { results, count } = data

  return { results, count }
}

export const createCollateral = async (
  variables: CreateCollateralVars
): Promise<CreateCollateralData> => {
  const { allowDealNow, apr, currency, duration, name, nfts, principal, repayment } = variables
  const { data } = await API.post<CreateCollateralData>(`${API_URLS.ZEXUS_API}/offers/`, {
    apr,
    currency,
    duration,
    name,
    nfts,
    principal: convertToBigNumber(principal, currency),
    repayment: convertToBigNumber(repayment, currency),
    allow_deal_now: allowDealNow,
  })
  return data
}

export const makeCollateralOffer = async (variables: MakeOfferVars): Promise<MakeOfferData> => {
  const { id, signature, nonce, deadline, terms } = variables
  const offerTermsForBackend = {
    principal: convertToBigNumber(terms.principal, terms.currency),
    repayment: convertToBigNumber(terms.repayment, terms.currency),
    currency: terms.currency,
    apr: terms.apr,
    duration: terms.duration,
    expirationDays: terms.expirationDate,
    signature,
    nonce,
    deadline,
  }

  const { data } = await API.post<MakeOfferData>(`${API_URLS.ZEXUS_API}/terms-offers/${id}/`, {
    ...offerTermsForBackend,
  })
  return data
}

export const setCollateralStatusReady = async (
  variables: SetCollateralStatusReadyVars
): Promise<void> => {
  const { id, collateralIdBC } = variables

  await API.post<void>(`${API_URLS.ZEXUS_API}/offers/${id}/ready/`, {
    collateralId: collateralIdBC,
  })
}

export const setDealNowStatus = async (variables: SetDealNowStatusVars): Promise<void> => {
  const { id } = variables

  await API.post<void>(`${API_URLS.ZEXUS_API}/deal-now/${id}/`)
}

export const getCollateralDetails = async (
  variables: GetCollateralDetailsVars
): Promise<CollateralDetails> => {
  const { id } = variables
  const { data } = await API.get<CollateralDetails>(`${API_URLS.ZEXUS_API}/offers/${id}/`)
  return {
    ...data,
    principal: String(data.principal),
    repayment: String(data.repayment),
  }
}

export const deleteCollateral = async (id: number): Promise<void> => {
  await API.delete(`${API_URLS.ZEXUS_API}/offers/${id}/`)
}

export const acceptCollateralTermsOffer = async (id: number) => {
  await API.post(`${API_URLS.ZEXUS_API}/terms-offers/${id}/accept/`)
}

export const rejectCollateralTermsOffer = async (id: number, message: string): Promise<void> => {
  await API.post(`${API_URLS.ZEXUS_API}/terms-offers/${id}/reject/`, {
    message,
  })
}

export const cancelCollateralTermsOffer = async (id: number): Promise<void> => {
  await API.post(`${API_URLS.ZEXUS_API}/terms-offers/${id}/cancel/`)
}
