import { ReactComponent as BanknotesIcon } from 'assets/icons/banknotes.svg'
import { ReactComponent as ScissorsIcon } from 'assets/icons/scissors.svg'
import { ReactComponent as SparklesIcon } from 'assets/icons/sparkles.svg'

export const BenefitsSection = () => (
  <div>
    <div className="container pt-14 pb-14 lg:pt-[116px] lg:pb-40">
      <h3 className="mb-14 text-center">Benefits</h3>
      <div className="flex flex-wrap justify-center gap-11">
        <div className="w-[284px]">
          <div className="flex h-[58px] items-center justify-center">
            <SparklesIcon className="h-[41px] w-[41px]" />
          </div>
          <h6 className="text-center text-[18px] leading-[31px] text-third-30">
            Release liquidity for new
            <br />
            financial actions.
          </h6>
        </div>

        <div className="w-[284px]">
          <div className="flex h-[58px] items-center justify-center">
            <BanknotesIcon className="h-[41px] w-[35px]" />
          </div>
          <h6 className="text-center text-[18px] leading-[31px] text-third-30">
            Utilize idle tokens
            <br />
            and earn fixed APR%.
          </h6>
        </div>

        <div className="w-[284px]">
          <div className="flex h-[58px] items-center justify-center">
            <ScissorsIcon className="h-[41px] w-[41px]" />
          </div>
          <h6 className="text-center text-[18px] leading-[31px] text-third-30">
            Whole process is permissionless
            <br />
            and customizable.
          </h6>
        </div>
      </div>
    </div>
    <div className="mx-1 h-[2px] bg-third-20" />
  </div>
)
