import { FC, useState } from 'react'

import dayjs from 'dayjs'

import { useWallet } from 'hooks/useWallet'
import { Currencies } from 'models/Currency'
import { LoanDetails } from 'models/Loan'
import { NFT } from 'models/NFT'
import { ProcessInfoModal } from 'screens/collaterals/components/ProcessInfoModal'
import { convertFromBigNumber } from 'utils/form'

import {
  ActiveOffers,
  Activities,
  BorrowerInformation,
  Button,
  ButtonBack,
  DescriptionInfo,
} from 'components'
import { TileImages } from 'components/tile/components/TileImages'

import { ClaimNFTsModal } from './components/ClaimNFTsModal'
import { PayBackConfirmationModal } from './components/PayBackConfirmationModal'
import { PayBackModal } from './components/PayBackModal'

type LoanDetailViewProps = {
  isMakeOfferInfoModalOpen: boolean
  isPayBackConfirmationModalOpen: boolean
  isPayBackModalOpen: boolean
  isRequestTermsUpdateInfoModalOpen: boolean
  loanDetails: LoanDetails
  onGoToProfile: () => void
  onMakeOfferInfoModalButtonClick: () => void
  onMakeOfferInfoModalOpenChange: (open: boolean) => void
  onOfferButtonClick: () => void
  onPayBack: () => void
  onPayBackConfirmationModalChange: (open: boolean) => void
  onRequestTermsUpdateInfoModalButtonClick: () => void
  onRequestTermsUpdateInfoModalOpenChange: (open: boolean) => void
  setIsPayBackModalOpen: (open: boolean) => void
}

export const LoanDetailView: FC<LoanDetailViewProps> = props => {
  const {
    isMakeOfferInfoModalOpen,
    isPayBackConfirmationModalOpen,
    isPayBackModalOpen,
    isRequestTermsUpdateInfoModalOpen,
    loanDetails,
    onGoToProfile,
    onMakeOfferInfoModalButtonClick,
    onMakeOfferInfoModalOpenChange,
    onOfferButtonClick,
    onPayBack,
    onPayBackConfirmationModalChange,
    onRequestTermsUpdateInfoModalButtonClick,
    onRequestTermsUpdateInfoModalOpenChange,
    setIsPayBackModalOpen,
  } = props

  const {
    activeOffers,
    activities,
    apr,
    borrower,
    borrowerInfo,
    currency,
    dueDate,
    duration,
    id,
    lender,
    name,
    nfts,
    principal,
    repayment,
    status,
  } = loanDetails

  const [highlightedNft, setHighlightedNft] = useState<NFT>(nfts[0])
  const [isClaimNFTsModalVisible, setIsClaimNFTsModalVisible] = useState(false)
  const { address } = useWallet()

  const parsedPrincipal = convertFromBigNumber(principal, currency as Currencies) as string
  const parsedRepayment = convertFromBigNumber(repayment, currency as Currencies) as string

  const isCurrentUserLender = lender === address
  const isCurrentUserBorrower = borrower === address

  const isMakeOfferVisible =
    address && !isCurrentUserBorrower && !isCurrentUserLender && status === 'ACTIVE'
  const isPastDueDate =
    (isCurrentUserBorrower || isCurrentUserLender) &&
    (status === 'OVERDUE' || dayjs().isAfter(dayjs(dueDate)))

  return (
    <>
      <div className="container lg:max-w-[1017px]">
        <div className="mt-6 mb-7">
          <ButtonBack text="Back to marketplace" path="/loans" />
        </div>
        <div className="grid gap-x-4 gap-y-0 lg:grid-cols-detailsContainer">
          <div>
            <TileImages
              nfts={nfts}
              onHighlightedNftChange={setHighlightedNft}
              size="large"
              tileImageClassName="rounded-b-none"
            />
          </div>
          <DescriptionInfo
            alert={isPastDueDate}
            apr={apr}
            currency={currency}
            dueDate={dueDate}
            duration={duration}
            lenderAddress={lender}
            name={name}
            ownerAddress={borrower}
            principal={parsedPrincipal}
            repayment={parsedRepayment}
            status={status}
            type="loan"
          >
            {isCurrentUserBorrower && (
              <div className="flex flex-col gap-4">
                <Button
                  className="w-full"
                  disabled={isPastDueDate}
                  onClick={() => {
                    setIsPayBackModalOpen(true)
                  }}
                >
                  Pay back
                </Button>
                <Button
                  className="w-full"
                  disabled={isPastDueDate}
                  onClick={onOfferButtonClick}
                  variant="secondary"
                >
                  Request terms update
                </Button>
                <button
                  type="button"
                  className="font-button-small -mb-5 font-normal text-third-40 underline transition-colors duration-300 hover:text-primary-50"
                  onClick={() => onRequestTermsUpdateInfoModalOpenChange(true)}
                >
                  What is &quot;request terms update&quot;?
                </button>
              </div>
            )}
            {isMakeOfferVisible && (
              <div className="flex flex-col gap-4">
                <Button onClick={onOfferButtonClick} className="w-full">
                  Make an offer
                </Button>
                <button
                  type="button"
                  className="font-button-small -mb-5 font-normal text-third-40 underline transition-colors duration-300 hover:text-primary-50"
                  onClick={() => onMakeOfferInfoModalOpenChange(true)}
                >
                  How &quot;make an offer&quot; works?
                </button>
              </div>
            )}
            {isCurrentUserLender && isPastDueDate && (
              <div className="flex flex-col gap-4">
                <Button onClick={() => setIsClaimNFTsModalVisible(true)} className="w-full">
                  Claim NFTs
                </Button>
              </div>
            )}
          </DescriptionInfo>
          <div className="mb-8 flex flex-col gap-8 lg:mb-12">
            <Activities
              activities={activities[Object.keys(activities)[0]]}
              nftCollectionName={highlightedNft.collectionName || highlightedNft.address}
            />
            <BorrowerInformation borrowerInfo={borrowerInfo} />
            <ActiveOffers activeOffers={activeOffers} />
          </div>
        </div>
      </div>
      <PayBackModal
        apr={apr}
        currency={currency}
        dueDate={dueDate}
        duration={duration}
        id={id}
        isOpen={isPayBackModalOpen}
        nfts={nfts}
        onOpenChange={setIsPayBackModalOpen}
        onPayBack={onPayBack}
        principal={principal}
        repayment={repayment}
      />
      <PayBackConfirmationModal
        collateralName={name}
        isOpen={isPayBackConfirmationModalOpen}
        loanId={id}
        onOpenChange={onPayBackConfirmationModalChange}
        onPrimaryButtonClick={onGoToProfile}
      />
      {isMakeOfferVisible && (
        <ProcessInfoModal
          isOpen={isMakeOfferInfoModalOpen}
          onOpenChange={onMakeOfferInfoModalOpenChange}
          onPrimaryButtonClick={onMakeOfferInfoModalButtonClick}
          type="makeOfferLoan"
        />
      )}
      {!isMakeOfferVisible && (
        <ProcessInfoModal
          isOpen={isRequestTermsUpdateInfoModalOpen}
          onOpenChange={onRequestTermsUpdateInfoModalOpenChange}
          onPrimaryButtonClick={onRequestTermsUpdateInfoModalButtonClick}
          type="termsUpdate"
        />
      )}
      {isCurrentUserLender && isPastDueDate && (
        <ClaimNFTsModal
          loanDetails={loanDetails}
          isOpen={isClaimNFTsModalVisible}
          onOpenChange={setIsClaimNFTsModalVisible}
        />
      )}
    </>
  )
}
