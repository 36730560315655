import { FC } from 'react'

import { AddCollateralStages } from '../collateral.types'

import { ReactComponent as Check } from 'assets/icons/check.svg'
import { ReactComponent as ChevronRight } from 'assets/icons/chevronRight.svg'

interface StagesProps {
  currentStage: AddCollateralStages
}

const stages = Object.entries(AddCollateralStages).filter(item => Number.isNaN(+item[0]))

export const Stages: FC<StagesProps> = ({ currentStage }) => (
  <div className="font-body-3 my-8 flex h-[42px] items-center gap-3 font-semibold text-third-40">
    {stages.map(([stage, stageIndex]) => (
      <div className="flex h-full items-center gap-2" key={stageIndex}>
        <div
          className={`flex aspect-square h-full items-center justify-center rounded-full ${
            currentStage === Number(stageIndex) + 1 ? 'bg-secondary-70' : 'bg-secondary-80'
          }`}
        >
          {currentStage > Number(stageIndex) + 1 ? <Check /> : Number(stageIndex) + 1}
        </div>
        <div className={`${currentStage === Number(stageIndex) + 1 ? '' : 'hidden md:inline'} `}>
          {stage}
        </div>
        <span className={`ml-1 mr-2 ${stageIndex === stages.length - 1 ? 'hidden' : ''}`}>
          <ChevronRight className="h-6 w-6  stroke-third-40" />
        </span>
      </div>
    ))}
  </div>
)
