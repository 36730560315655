import {
  erc20ABI,
  fetchBalance,
  getAccount,
  getNetwork,
  prepareWriteContract,
  readContract,
  SendTransactionResult,
  watchContractEvent,
  writeContract,
} from '@wagmi/core'
import { BigNumber, ethers } from 'ethers'
import { Address, erc721ABI } from 'wagmi'

import { Domain } from 'models/Blockchain'
import { BlockchainCollateral } from 'models/Collateral'
import { StoreModalType } from 'models/Comm'
import { Currencies } from 'models/Currency'
import { CollateralOffer, LoanForBlockchain } from 'models/Loan'
import { NFT } from 'models/NFT'
import { Terms } from 'models/Terms'
import { Store } from 'store/helpers'
import { pushModal } from 'store/slices/comm/comm.actions'
import { ADDRESSES, supportedChains } from 'utils/constants'
import EventsAbi from 'utils/contracts/Events.json'
import UtilsAbi from 'utils/contracts/Utils.json'
import zexusBetaAbi from 'utils/contracts/ZexusBeta.json'
import zexusBorrowerAbi from 'utils/contracts/ZexusBorrower.json'
import zexusCollateralAbi from 'utils/contracts/ZexusCollateral.json'
import zexusLenderAbi from 'utils/contracts/ZexusLender.json'

import { convertToBigNumber } from '../form'

let appStore: Store

export const injectStore = (store: Store) => {
  appStore = store
}

export class WrongChainError extends Error {
  public static code = 'WRONG_CHAIN'

  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, WrongChainError.prototype)
  }
}

const checkChain = () => {
  const { chain } = getNetwork()
  if (!chain || chain?.unsupported) {
    appStore.dispatch(
      pushModal({
        message: `Please switch to ${supportedChains[0].name} network.`,
        title: 'Error!',
        type: StoreModalType.ERROR_MESSAGE,
      })
    )
    throw new WrongChainError(`Please, switch to ${supportedChains[0].name} network.`)
  }
}

const getNftsAddresses = (nfts: NFT[]): Address[] => nfts.map(nft => nft.address)

const getNftsId = (nfts: NFT[]): string[] => nfts.map(nft => nft.tokenId)

export const writeContractApproveNft = async (nft: NFT): Promise<SendTransactionResult | void> => {
  checkChain()

  const nftIdBN = BigNumber.from(nft.tokenId)

  const config = await prepareWriteContract({
    abi: erc721ABI,
    address: nft.address,
    functionName: 'approve',
    args: [ADDRESSES.ZEXUS_COLLATERAL as Address, nftIdBN],
  })

  return await writeContract(config)
}

export const writeContractAddNFTCollateral = async (
  nfts: NFT[],
  terms: Terms
): Promise<SendTransactionResult> => {
  checkChain()

  const collateral: BlockchainCollateral = {
    value: convertToBigNumber(terms.principal, terms.currency, false) as BigNumber,
    interestRate: terms.apr as number,
    duration: terms.duration as number,
    currency: ADDRESSES[terms.currency] as Address,
    loanId: ethers.utils.formatBytes32String(''),
    loaner: ethers.constants.AddressZero,
  }

  const config = await prepareWriteContract({
    abi: zexusCollateralAbi,
    address: ADDRESSES.ZEXUS_COLLATERAL as Address,
    functionName: 'addNFTCollateral',
    args: [getNftsAddresses(nfts), getNftsId(nfts), collateral],
  })

  return await writeContract(config)
}

export const approveNfts = async (nfts: NFT[]): Promise<{ status: 'success' | 'fail' }> => {
  checkChain()

  let allSuccess = true
  await Promise.all(
    nfts.map(async nft => {
      const nftIdBN = BigNumber.from(nft.tokenId)

      const approvedAddress = await readContract({
        address: nft.address,
        abi: erc721ABI,
        functionName: 'getApproved',
        args: [nftIdBN],
      })

      if (approvedAddress !== ADDRESSES.ZEXUS_COLLATERAL) {
        const approveData = await writeContractApproveNft(nft)
        const transactionReceipt = await approveData?.wait()
        if (transactionReceipt?.status !== 1) allSuccess = false
      }
    })
  )
  return { status: allSuccess ? 'success' : 'fail' }
}

export const cancelCollateral = async (collateralId: string): Promise<SendTransactionResult> => {
  checkChain()

  const config = await prepareWriteContract({
    abi: zexusCollateralAbi,
    address: ADDRESSES.ZEXUS_COLLATERAL as Address,
    functionName: 'cancelCollateral',
    args: [collateralId],
  })

  return await writeContract(config)
}

export const erc20Approve = async (
  amount: BigNumber,
  currencyAddress: Address,
  receiverAddress: Address
): Promise<SendTransactionResult> => {
  checkChain()

  const account = getAccount()
  const allowance = await readContract({
    address: currencyAddress,
    abi: erc20ABI,
    functionName: 'allowance',
    args: [account.address as Address, receiverAddress],
  })
  const sum = allowance.add(amount)

  const config = await prepareWriteContract({
    address: currencyAddress,
    abi: erc20ABI,
    functionName: 'approve',
    args: [receiverAddress, sum],
  })

  return await writeContract(config)
}

export const acceptLoanAsLender = async (collateralId: string) => {
  checkChain()

  const config = await prepareWriteContract({
    address: ADDRESSES.ZEXUS_LENDER as Address,
    abi: zexusLenderAbi,
    functionName: 'acceptBorrowerOffer',
    args: [collateralId],
  })

  return await writeContract(config)
}

export const getPrincipalFee = async (
  feeType: number,
  apr: number,
  duration: number,
  principal: BigNumber
): Promise<BigNumber> => {
  checkChain()

  return (await readContract({
    address: ADDRESSES.ZEXUS_UTILS as Address,
    abi: UtilsAbi,
    functionName: 'calculateFee',
    args: [feeType, apr, duration, principal],
  })) as BigNumber
}

export const getRepaymentAmount = async (
  apr: number,
  duration: number,
  principal: BigNumber
): Promise<BigNumber> => {
  checkChain()

  return (await readContract({
    address: ADDRESSES.ZEXUS_UTILS as Address,
    abi: UtilsAbi,
    functionName: 'calculateRepayment',
    args: [apr, duration, principal],
  })) as BigNumber
}

export const repayLoan = async (collateralId: string) => {
  checkChain()

  const config = await prepareWriteContract({
    address: ADDRESSES.ZEXUS_BORROWER as Address,
    abi: zexusBorrowerAbi,
    functionName: 'repayLoan',
    args: [collateralId],
  })

  return await writeContract(config)
}

export const loanDefaulted = async (collateralId: string) => {
  checkChain()

  const config = await prepareWriteContract({
    address: ADDRESSES.ZEXUS_LENDER as Address,
    abi: zexusLenderAbi,
    functionName: 'loanDefaulted',
    args: [collateralId],
  })

  return await writeContract(config)
}

export const getUtilsDomain = (chainId: number): Domain => ({
  name: 'Zexus',
  version: '1.0',
  chainId,
  verifyingContract: process.env.REACT_APP_ADDRESSES_ZEXUS_UTILS as Address,
})

export const acceptOfferForLoan = async (
  loan: LoanForBlockchain,
  signature: string,
  collateralId: string
) => {
  checkChain()

  const config = await prepareWriteContract({
    address: ADDRESSES.ZEXUS_BORROWER as Address,
    abi: zexusBorrowerAbi,
    functionName: 'acceptLoanExtensionAsBorrower',
    args: [loan, signature, collateralId],
  })

  return await writeContract(config)
}

export const acceptOfferForLoanExtension = async (
  collateral: CollateralOffer,
  signature: string
) => {
  checkChain()

  const config = await prepareWriteContract({
    address: ADDRESSES.ZEXUS_LENDER as Address,
    abi: zexusLenderAbi,
    functionName: 'acceptLoanExtensionAsLender',
    args: [collateral, signature],
  })

  return await writeContract(config)
}

export const acceptOfferForCollateral = async (loan: LoanForBlockchain, signature: string) => {
  checkChain()

  const config = await prepareWriteContract({
    address: ADDRESSES.ZEXUS_BORROWER as Address,
    abi: zexusBorrowerAbi,
    functionName: 'acceptLoan',
    args: [loan, signature],
  })

  return await writeContract(config)
}

export const getBalanceBN = async (
  address: Address,
  currency: Exclude<Currencies, Currencies.ALL>
): Promise<BigNumber> => {
  const { value } = await fetchBalance({
    address,
    token: ADDRESSES[currency] as Address,
  })
  return value
}

export const watchCollateralAddedEvent = (
  callback: (creator: Address, collateralIdBC: string) => void
): (() => void) => {
  checkChain()

  return watchContractEvent(
    {
      address: ADDRESSES.ZEXUS_COLLATERAL as Address,
      abi: EventsAbi,
      eventName: 'CollateralAdded',
    },
    (creator, collateralIdBC) => {
      callback(creator as Address, collateralIdBC as string)
    }
  )
}

export const getBetaTokens = async () => {
  checkChain()

  const config = await prepareWriteContract({
    address: ADDRESSES.ZEXUS_BETA as Address,
    abi: zexusBetaAbi,
    functionName: 'getTokens',
  })

  return await writeContract(config)
}
