import { useEffect, useState } from 'react'

import * as ReactToast from '@radix-ui/react-toast'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { StoreToast } from 'models/Comm'
import { shiftToast } from 'store/slices/comm/comm.actions'
import { commStateSelector } from 'store/slices/comm/comm.slice'

import { Toast } from './Toast'

export const ToastContainer = () => {
  const [storeToast, setStoreToast] = useState<StoreToast>()

  const { toastStack } = useAppSelector(commStateSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setStoreToast(toastStack.length > 0 ? toastStack[0] : undefined)
  }, [toastStack])

  const handleClose = () => {
    setStoreToast(undefined)
    dispatch(shiftToast())
  }

  return (
    <>
      {!!storeToast && (
        <Toast message={storeToast.message} onClose={handleClose} key={storeToast.message} />
      )}
      <ReactToast.Viewport className="fixed right-4 bottom-4" />
    </>
  )
}
