import { createSlice } from '@reduxjs/toolkit'

import { Loan, LoanDetails } from 'models/Loan'
import { RequestStatus, RootState } from 'store/helpers'
import { logout } from 'store/slices/auth/auth.actions'

import {
  acceptLoanExtension,
  acceptLoanOffer,
  getLoanDetails,
  getLoansList,
  makeLoanOffer,
  rejectLoanExtension,
  rejectLoanOffer,
  requestTermsUpdate,
  resetAcceptLoanOfferDraftId,
  resetDefaultedStatus,
  resetLoanOfferId,
  resetLoansList,
  setDefaulted,
} from './loans.actions'

export type LoansState = {
  acceptLoanExtensionStatus: RequestStatus
  acceptLoanOfferStatus: RequestStatus
  count: number
  getLoanDetailsStatus: RequestStatus
  getLoanOfferIdStatus: RequestStatus
  getLoansListStatus: RequestStatus
  loanDetails?: LoanDetails
  loanOfferId?: number
  loanTermsOfferDraftId?: number
  loans: Loan[]
  rejectLoanOfferStatus: RequestStatus
  rejectOfferExtensionStatus: RequestStatus
  setDefaultedStatus: RequestStatus
}

const initialState: LoansState = {
  acceptLoanExtensionStatus: RequestStatus.IDLE,
  acceptLoanOfferStatus: RequestStatus.IDLE,
  count: 0,
  getLoanDetailsStatus: RequestStatus.IDLE,
  getLoanOfferIdStatus: RequestStatus.IDLE,
  getLoansListStatus: RequestStatus.IDLE,
  loanDetails: undefined,
  loanOfferId: undefined,
  loanTermsOfferDraftId: undefined,
  loans: [],
  rejectLoanOfferStatus: RequestStatus.IDLE,
  rejectOfferExtensionStatus: RequestStatus.IDLE,
  setDefaultedStatus: RequestStatus.IDLE,
}

export const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout.pending, state => {
        state.loanOfferId = initialState.loanOfferId
        state.loanTermsOfferDraftId = initialState.loanTermsOfferDraftId
      })
      .addCase(getLoansList.pending, state => {
        state.getLoansListStatus = RequestStatus.PENDING
      })
      .addCase(getLoansList.fulfilled, (state, action) => {
        state.loans = [...state.loans, ...action.payload.results]
        state.count = action.payload.count
        state.getLoansListStatus = RequestStatus.FULFILLED
      })
      .addCase(getLoansList.rejected, state => {
        state.getLoansListStatus = RequestStatus.REJECTED
      })
      .addCase(resetLoansList.type, state => {
        state.loans = []
        state.count = 0
        state.getLoansListStatus = RequestStatus.IDLE
      })
      .addCase(getLoanDetails.pending, state => {
        state.loanDetails = undefined
        state.getLoanDetailsStatus = RequestStatus.PENDING
      })
      .addCase(getLoanDetails.fulfilled, (state, action) => {
        state.loanDetails = action.payload
        state.getLoanDetailsStatus = RequestStatus.FULFILLED
      })
      .addCase(getLoanDetails.rejected, state => {
        state.getLoanDetailsStatus = RequestStatus.REJECTED
      })
      .addCase(makeLoanOffer.pending, state => {
        state.getLoanOfferIdStatus = RequestStatus.PENDING
      })
      .addCase(makeLoanOffer.fulfilled, (state, action) => {
        state.loanOfferId = action.payload
        state.getLoanOfferIdStatus = RequestStatus.FULFILLED
      })
      .addCase(makeLoanOffer.rejected, state => {
        state.getLoanOfferIdStatus = RequestStatus.REJECTED
      })
      .addCase(requestTermsUpdate.pending, state => {
        state.getLoanOfferIdStatus = RequestStatus.PENDING
      })
      .addCase(requestTermsUpdate.fulfilled, (state, action) => {
        state.loanOfferId = action.payload
        state.getLoanOfferIdStatus = RequestStatus.FULFILLED
      })
      .addCase(requestTermsUpdate.rejected, state => {
        state.getLoanOfferIdStatus = RequestStatus.REJECTED
      })
      .addCase(resetLoanOfferId.type, state => {
        state.loanOfferId = undefined
      })
      .addCase(acceptLoanOffer.pending, state => {
        state.acceptLoanOfferStatus = RequestStatus.PENDING
      })
      .addCase(acceptLoanOffer.fulfilled, (state, action) => {
        state.loanTermsOfferDraftId = action.payload
        state.acceptLoanOfferStatus = RequestStatus.FULFILLED
      })
      .addCase(acceptLoanOffer.rejected, state => {
        state.acceptLoanOfferStatus = RequestStatus.REJECTED
      })
      .addCase(rejectLoanOffer.pending, state => {
        state.rejectLoanOfferStatus = RequestStatus.PENDING
      })
      .addCase(rejectLoanOffer.fulfilled, state => {
        state.rejectLoanOfferStatus = RequestStatus.FULFILLED
      })
      .addCase(rejectLoanOffer.rejected, state => {
        state.rejectLoanOfferStatus = RequestStatus.REJECTED
      })
      .addCase(acceptLoanExtension.pending, state => {
        state.acceptLoanExtensionStatus = RequestStatus.PENDING
      })
      .addCase(acceptLoanExtension.fulfilled, state => {
        state.acceptLoanExtensionStatus = RequestStatus.FULFILLED
      })
      .addCase(acceptLoanExtension.rejected, state => {
        state.acceptLoanExtensionStatus = RequestStatus.REJECTED
      })
      .addCase(rejectLoanExtension.pending, state => {
        state.rejectOfferExtensionStatus = RequestStatus.PENDING
      })
      .addCase(rejectLoanExtension.fulfilled, state => {
        state.rejectOfferExtensionStatus = RequestStatus.FULFILLED
      })
      .addCase(rejectLoanExtension.rejected, state => {
        state.rejectOfferExtensionStatus = RequestStatus.REJECTED
      })
      .addCase(resetAcceptLoanOfferDraftId.type, state => {
        state.loanTermsOfferDraftId = undefined
        state.acceptLoanExtensionStatus = RequestStatus.IDLE
        state.acceptLoanOfferStatus = RequestStatus.IDLE
        state.rejectLoanOfferStatus = RequestStatus.IDLE
        state.rejectOfferExtensionStatus = RequestStatus.IDLE
      })
      .addCase(setDefaulted.pending, state => {
        state.setDefaultedStatus = RequestStatus.PENDING
      })
      .addCase(setDefaulted.fulfilled, state => {
        state.setDefaultedStatus = RequestStatus.FULFILLED
      })
      .addCase(setDefaulted.rejected, state => {
        state.setDefaultedStatus = RequestStatus.REJECTED
      })
      .addCase(resetDefaultedStatus.type, state => {
        state.setDefaultedStatus = RequestStatus.IDLE
      })
  },
})

export const loansStateSelector = (state: RootState) => state.loans

export default loansSlice.reducer
