import { FC } from 'react'

type BadgeProps = {
  className?: string
  position?: 'inline' | 'top'
  value?: string | number | null
}

export const Badge: FC<BadgeProps> = props => {
  const { className, position = 'top', value } = props

  const positionClass =
    position === 'top' ? `absolute right-0 top-0 translate-x-1/4 -translate-y-1/2` : ''

  return (
    <span
      className={`font-button-small inline-block min-h-[4px] min-w-[4px] rounded-full bg-error-60 text-center text-white ${positionClass} ${
        value !== undefined && value !== null ? 'px-1' : ''
      } ${className}`}
    >
      {value}
    </span>
  )
}
