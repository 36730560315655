import { FC } from 'react'

import * as AlertDialog from '@radix-ui/react-alert-dialog'

import { getButtonClasses } from 'components/buttons/Button'

import { ReactComponent as ExclamationCircleIcon } from 'assets/icons/exclamationCircle.svg'

type AlertProps = {
  isOpen: boolean
  message?: string
  onOpenChange: (open: boolean) => void
}

const buttonClasses = getButtonClasses('small', 'primary', 'default', true, false)

export const Alert: FC<AlertProps> = props => {
  const { isOpen, message, onOpenChange } = props

  return (
    <AlertDialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 z-40 backdrop-blur" />
        <AlertDialog.Content className="fixed left-2/4 top-2/4 z-50 min-w-[240px] max-w-[calc(100vw-32px)] -translate-x-2/4 -translate-y-2/4 rounded-lg border-2 border-third-70 bg-third-95 p-6">
          <AlertDialog.Title asChild className="mb-8 flex text-secondary-10">
            <div>
              <div className="font-header-h4 mr-3">Caution</div>
              <div className="mt-2 mr-3 h-7 w-7">
                <ExclamationCircleIcon className="stroke-error-60" />
              </div>
            </div>
          </AlertDialog.Title>
          <AlertDialog.Description className="font-body-4 mt-2 text-third-10">
            {message}
          </AlertDialog.Description>
          <div className="mt-2 flex items-center justify-end gap-2">
            <AlertDialog.Action className={buttonClasses}>OK</AlertDialog.Action>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
