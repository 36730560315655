import { FC } from 'react'

import { Terms } from 'models/Terms'

import { ButtonBack } from 'components'

import { OfferSummary } from './components/OfferSummary'
import { TermsForm } from './components/TermsForm'

interface MakeCollateralOfferViewProps {
  id: string | undefined
  onCancel: () => void
  onSendOffer: () => void
  originalTerms: Terms
  onTermsChange: (terms: Partial<Terms>) => void
  terms: Terms
}

export const MakeOfferView: FC<MakeCollateralOfferViewProps> = ({
  id,
  onCancel,
  onSendOffer,
  originalTerms,
  onTermsChange,
  terms,
}) => (
  <div className="container py-10 lg:max-w-[1056px]">
    <ButtonBack text="Back to current collateral" path={`/collaterals/${id}`} />
    <div className="mt-10 grid gap-x-4 gap-y-0 lg:grid-cols-detailsContainer">
      <TermsForm isMakeCollateralOffer onTermsChange={onTermsChange} terms={terms} />
      <OfferSummary
        offerTerms={terms}
        onSendOffer={onSendOffer}
        onCancel={onCancel}
        originalTerms={originalTerms}
      />
    </div>
  </div>
)
