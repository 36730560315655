import { FC } from 'react'

import { Modal, ModalProps } from 'components'

export type SimpleModalProps = ModalProps & {
  message?: string | JSX.Element
}

export const SimpleModal: FC<SimpleModalProps> = props => {
  const { icon, isOpen, message, onOpenChange, renderBottomBar, title } = props
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      icon={icon}
      title={title}
      renderBottomBar={renderBottomBar}
    >
      {message}
    </Modal>
  )
}
