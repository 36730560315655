import { createAction } from '@reduxjs/toolkit'

import { LoanDetails } from 'models/Loan'
import { MakeOfferVars, RejectOfferData, RequestTermsUpdateVars } from 'models/Offer'
import { createAppAsyncThunk } from 'store/helpers'
import * as loansApi from 'utils/api/loans'
import {
  ConfirmLoanTermsOfferVars,
  GetLoanDetailsVars,
  GetLoansListResponse,
  GetLoansListVars,
  SetDefaultedVars,
  SetPayBackStatusVars,
} from 'utils/api/loans'
import * as nftApi from 'utils/api/nft'
import { GetPriceValuationVarsItem } from 'utils/api/nft'
import { mergeNFTWithPrices } from 'utils/nft'

export const getLoansList = createAppAsyncThunk<GetLoansListResponse, GetLoansListVars>(
  'loans/getLoansList',
  async (variables, thunkAPI) => {
    const extendedVariables: GetLoansListVars = { ...variables, signal: thunkAPI.signal }
    return await loansApi.getLoansList(extendedVariables)
  }
)

export const getLoanDetails = createAppAsyncThunk<LoanDetails, GetLoanDetailsVars>(
  'loans/getLoanDetails',
  async variables => {
    const loanDetails = await loansApi.getLoanDetails(variables)

    const pricesVariables: GetPriceValuationVarsItem[] = loanDetails.nfts.map(item => ({
      address: item.address,
      token_id: item.tokenId,
    }))
    const nftPriceValuation = await nftApi.getPriceValuation(pricesVariables)

    loanDetails.nfts = mergeNFTWithPrices({
      ...nftPriceValuation,
      nfts: loanDetails.nfts,
    })

    return loanDetails
  }
)

export const makeLoanOffer = createAppAsyncThunk<number, MakeOfferVars>(
  'loans/makeOffer',
  async variables => {
    const { id } = await loansApi.makeLoanOffer(variables)
    return id
  }
)

export const requestTermsUpdate = createAppAsyncThunk<number, RequestTermsUpdateVars>(
  'loans/requestTermsUpdate',
  async variables => {
    const { id } = await loansApi.requestTermsUpdate(variables)
    return id
  }
)

export const setPayBackStatus = createAppAsyncThunk<void, SetPayBackStatusVars>(
  'loans/setPayBackStatus',
  async variables => {
    await loansApi.setPayBackStatus(variables)
  }
)

export const setDefaulted = createAppAsyncThunk<void, SetDefaultedVars>(
  'loans/setDefaultedStatus',
  async variables => {
    await loansApi.setDefaulted(variables)
  }
)

export const acceptLoanOffer = createAppAsyncThunk<number, number>(
  'loans/acceptLoanOffer',
  async id => {
    const { draftId } = await loansApi.acceptLoanTermsOffer(id)
    return draftId
  }
)

export const confirmLoanTermsOffer = createAppAsyncThunk<number, ConfirmLoanTermsOfferVars>(
  'loans/confirmAcceptLoanOffer',
  async variables => {
    const { draftId, offerId } = variables
    const { loanId } = await loansApi.confirmLoanTermsOffer(draftId, offerId)
    return loanId
  }
)

export const rejectLoanOffer = createAppAsyncThunk<void, RejectOfferData>(
  'loans/rejectLoanOffer',
  async variables => {
    const { id, message } = variables
    await loansApi.rejectLoanTermsOffer(id, message)
  }
)

export const cancelLoanOffer = createAppAsyncThunk<void, number>(
  'loans/cancelLoanOffer',
  async id => {
    await loansApi.cancelLoanTermsOffer(id)
  }
)

export const acceptLoanExtension = createAppAsyncThunk<void, number>(
  'loans/acceptLoanExtension',
  async id => {
    await loansApi.acceptLoanExtension(id)
  }
)

export const rejectLoanExtension = createAppAsyncThunk<void, RejectOfferData>(
  'loans/rejectLoanExtension',
  async variables => {
    const { id, message } = variables
    await loansApi.rejectLoanExtension(id, message)
  }
)

export const cancelLoanExtension = createAppAsyncThunk<void, number>(
  'loans/cancelLoanExtension',
  async id => {
    await loansApi.cancelLoanExtension(id)
  }
)

export const resetLoansList = createAction('loans/resetLoansList')
export const resetLoanOfferId = createAction('loans/resetLoanOfferId')
export const resetAcceptLoanOfferDraftId = createAction('loans/resetAcceptLoanOfferDraftId')
export const resetDefaultedStatus = createAction('loans/resetDefaultedStatus')
