import { forwardRef } from 'react'

import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'hooks/store.hooks'
import { Collateral } from 'models/Collateral'
import { CollateralsFilter } from 'models/Filter'
import { RequestStatus } from 'store/helpers'
import { collateralStateSelector } from 'store/slices/collaterals/collaterals.slice'

import { ActivityIndicator, Button, Link, Tile } from 'components'

import { CollateralsFilters } from './components/CollateralsFilters'

import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg'

interface CollateralsViewProps {
  collaterals?: Collateral[]
  onFiltersChange: (value: CollateralsFilter) => void
}

export const CollateralsView = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CollateralsViewProps>
>(({ collaterals, onFiltersChange }, scrollRef) => {
  const { getCollateralsListStatus } = useAppSelector(collateralStateSelector)
  const navigate = useNavigate()

  const handleBorrowMoneyClick = () => {
    navigate('/collaterals/new')
  }

  return (
    <div className="container py-20">
      <div className="flex flex-col items-end justify-between gap-6 lg:flex-row">
        <div>
          <h2 className="mb-4 text-third-10">Collaterals</h2>
          <h6 className="text-third-10 lg:max-w-[820px]">
            Welcome to the marketplace! As a lender, this is where you can browse all collateral and
            make offers to collaterals that you are interested in. As a borrower, you may create new
            collateral with customized loan terms here by clicking “create a new collateral.”
          </h6>
        </div>
        <Button icon={<ArrowRight />} onClick={handleBorrowMoneyClick}>
          Create a new collateral
        </Button>
      </div>
      <div className="mt-12 mb-[44px]">
        <CollateralsFilters onChange={onFiltersChange} />
      </div>
      <div className="flex flex-wrap justify-center gap-x-12 gap-y-14 lg:justify-start">
        {getCollateralsListStatus === RequestStatus.PENDING && (
          <div className="flex h-[385px] w-full items-center justify-center">
            <ActivityIndicator />
          </div>
        )}
        {getCollateralsListStatus === RequestStatus.FULFILLED &&
          collaterals?.map(({ name, id, nfts, apr, currency, principal }, index) => {
            const key = `${id}_${index}`
            return (
              <Link to={`/collaterals/${id}`} internal key={key}>
                <Tile
                  apr={apr}
                  currency={currency}
                  nfts={nfts}
                  principal={principal}
                  title={name}
                  type="collateral"
                />
              </Link>
            )
          })}
      </div>
      {collaterals && (
        <div className="end-of-list invisible relative h-[0px]">
          <div
            ref={scrollRef}
            className="virtual absolute -top-[400px] h-[420px] w-full bg-cyan-200"
          />
        </div>
      )}
    </div>
  )
})
