import { FC, useMemo } from 'react'

import Avatar, { jsNumberForAddress } from 'react-jazzicon'

import { useWallet } from 'hooks/useWallet'
import { shortAddress } from 'utils/address'

import { Button } from 'components'

type NavigationProfileProps = {
  className?: string
  onClick: () => void
}

export const NavigationProfile: FC<NavigationProfileProps> = props => {
  const { className, onClick } = props

  const { address, isLoading, isConnected } = useWallet()

  const addressShort = useMemo(() => shortAddress(address), [address])

  return (
    <div className={className}>
      {isConnected && !isLoading && (
        <button
          type="button"
          onClick={onClick}
          className="font-body-3 flex items-center justify-between gap-2 rounded-xl border border-third-20 p-2 text-third-20"
        >
          <Avatar diameter={20} seed={jsNumberForAddress(address as string)} />
          {addressShort}
        </button>
      )}

      {(!isConnected || isLoading) && (
        <Button onClick={onClick} variant="secondary" className={className} disabled={isLoading}>
          {isLoading ? '...' : 'Connect wallet'}
        </Button>
      )}
    </div>
  )
}
