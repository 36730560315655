import { FC, useEffect, useState } from 'react'

import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'

import { MinMax } from 'models/Form'
import { formatOnlyFloat } from 'utils/format'

import { getButtonClasses } from 'components/buttons'
import { Dropdown } from 'components/form/dropdown'
import { TextInput } from 'components/form/text-input/TextInput'

interface FilterPrincipalProps {
  onChange: (value: MinMax) => void
  defaultValue: MinMax
  value?: MinMax
}

type FilterPrincipalValue = {
  min: number | string
  max: number | string
}

const primaryButtonClasses = getButtonClasses('small', 'primary', 'default', true, false)
const secondaryButtonClasses = getButtonClasses('small', 'secondary', 'default', true, false)

export const FilterPrincipal: FC<FilterPrincipalProps> = ({ onChange, defaultValue, value }) => {
  const [filterValue, setFilterValue] = useState<FilterPrincipalValue>(value || defaultValue)

  useEffect(() => {
    setFilterValue(value || defaultValue)
  }, [value])

  const handleClear = () => {
    setFilterValue(defaultValue)
    onChange(defaultValue)
  }

  const handleApprove = () => {
    onChange({ min: +filterValue.min, max: +filterValue.max })
  }

  return (
    <Dropdown title={`${+filterValue.min} - ${+filterValue.max}`} label="Principal">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-6">
          <div className="w-[72px]">
            <TextInput
              value={filterValue.min}
              onValueChange={newValue =>
                setFilterValue({ ...filterValue, min: newValue.value ?? defaultValue.min })
              }
              min={0}
              placeholder="0"
              format={formatOnlyFloat}
            />
          </div>
          <span className="font-body-4 font-semibold">to</span>
          <div className="w-[72px]">
            <TextInput
              value={filterValue.max}
              onValueChange={newValue =>
                setFilterValue({ ...filterValue, max: newValue.value ?? defaultValue.max })
              }
              min={0}
              placeholder="0"
              format={formatOnlyFloat}
            />
          </div>
        </div>
        <div className="flex gap-4 self-end">
          <DropdownMenuItem onClick={handleClear} className={secondaryButtonClasses}>
            Clear
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleApprove} className={primaryButtonClasses}>
            Apply
          </DropdownMenuItem>
        </div>
      </div>
    </Dropdown>
  )
}
