// TODO: Split this type into two separate: Currencies with the list of currencies and
//  SupportedCurrencies with the list of currencies supported by our product. This is needed in
//  a situations like in getCurrencyIcon where we would like to display icon for ETH that is not
//  supported by system in general but some data displayed to the user are in that currency.
export enum Currencies {
  ALL = 'ALL',
  WETH = 'WETH',
  USDC = 'USDC',
  USDT = 'USDT',
}
