import { FC, ReactNode } from 'react'

import { Address } from 'wagmi'

import { ProfileOffersFilter } from 'models/Filter'
import { UserOffer } from 'models/Offer'
import { ColumnDefinition } from 'models/Table'
import { OffersTypes } from 'screens/profile-offers/ProfileOffersScreen'

import { Button, Table } from 'components/index'
import { renderTableCell } from 'components/table/components/TableRows'

import { CancelOfferModal } from './components/CancelOfferModal'
import { DetailsModal } from './components/DetailsModal'
import { OfferConfirmationModal } from './components/OfferConfirmationModal'
import { ProfileOffersFilters } from './components/ProfileOffersFilters'
import { RejectOfferModal } from './components/RejectOfferModal'
import { offerHeaders } from './helpers'

import nftThumb from 'assets/images/nft.png'
import nftThumb1 from 'assets/images/nft1.png'

interface ProfileOffersViewProps {
  isAcceptOfferConfirmationModalOpen: boolean
  isDetailsModalOpen: boolean
  isRejectOfferModalOpen: boolean
  isRejectOfferConfirmationModalOpen: boolean
  isCancelOfferConfirmationModalOpen: boolean
  isCancelOfferModalOpen: boolean
  offers: UserOffer[]
  offersType: OffersTypes
  onDetailsModalOpenChange: (open: boolean) => void
  onFiltersChange: (value: ProfileOffersFilter) => void
  onItemSelect: (item: UserOffer) => void
  onOfferAccept: () => Promise<void>
  onOfferCancel: () => void
  onOfferReject: (id: number, message: string) => void
  onRejectModalOpenChange: (open: boolean) => void
  onAcceptOfferConfirmationModalChange: (open: boolean) => void
  onRejectOfferConfirmationModalChange: (open: boolean) => void
  onCancelOfferConfirmationModalChange: (open: boolean) => void
  onCancelModalOpenChange: (open: boolean) => void
  selectedOffer?: UserOffer
}

export const ProfileOffersView: FC<ProfileOffersViewProps> = ({
  isAcceptOfferConfirmationModalOpen,
  isDetailsModalOpen,
  isRejectOfferModalOpen,
  isRejectOfferConfirmationModalOpen,
  isCancelOfferConfirmationModalOpen,
  isCancelOfferModalOpen,
  offers,
  offersType,
  onDetailsModalOpenChange,
  onFiltersChange,
  onItemSelect,
  onOfferAccept,
  onOfferReject,
  onOfferCancel,
  onRejectModalOpenChange,
  onAcceptOfferConfirmationModalChange,
  onRejectOfferConfirmationModalChange,
  onCancelOfferConfirmationModalChange,
  onCancelModalOpenChange,
  selectedOffer,
}) => {
  if (!offersType) return null

  const renderCell = (column: ColumnDefinition<UserOffer>, item: UserOffer): ReactNode => {
    if (column.type === 'button' && item) {
      return (
        <Button variant="secondary" onClick={() => onItemSelect(item)}>
          Details
        </Button>
      )
    }

    if (column.type === 'image' && item) {
      return item.nftCount === 1 ? (
        <img src={item.image} className="h-[56px] w-[56px]" alt="" />
      ) : (
        <div className="relative h-[56px] w-[56px]">
          <img src={item.image} className="absolute z-[2]" alt="" />
          <img src={nftThumb} className="absolute left-[2px] top-[2px] z-[1]" alt="" />
          <img src={nftThumb1} className="absolute left-[4px] top-[4px] z-[0]" alt="" />
        </div>
      )
    }

    return renderTableCell(column, item)
  }

  return (
    <div className="container mt-[-8px] max-w-[1240px] rounded bg-third-90 pl-[57px] pt-[24px] pr-[42px] pb-[50px]">
      <ProfileOffersFilters onChange={onFiltersChange} />
      <Table columns={offerHeaders[offersType]} data={offers} renderCell={renderCell} />
      {selectedOffer && (
        <DetailsModal
          isOpen={isDetailsModalOpen}
          offersType={offersType}
          onOfferAccept={onOfferAccept}
          onOfferReject={() => onRejectModalOpenChange(true)}
          onOfferCancel={() => onCancelModalOpenChange(true)}
          onOpenChange={onDetailsModalOpenChange}
        />
      )}
      <RejectOfferModal
        isOpen={isRejectOfferModalOpen}
        onCancel={() => onRejectModalOpenChange(false)}
        onOpenChange={onRejectModalOpenChange}
        onReject={(message: string) => onOfferReject(selectedOffer?.id as number, message)}
      />
      <CancelOfferModal
        isOpen={isCancelOfferModalOpen}
        onClose={() => onCancelModalOpenChange(false)}
        onOpenChange={onCancelModalOpenChange}
        onCancel={onOfferCancel}
      />
      <OfferConfirmationModal
        type="reject"
        isOpen={isRejectOfferConfirmationModalOpen}
        onOpenChange={onRejectOfferConfirmationModalChange}
        senderAddress={(selectedOffer?.lender || selectedOffer?.borrower) as Address}
      />
      <OfferConfirmationModal
        type="accept"
        isOpen={isAcceptOfferConfirmationModalOpen}
        onOpenChange={onAcceptOfferConfirmationModalChange}
        senderAddress={(selectedOffer?.lender || selectedOffer?.borrower) as Address}
      />
      <OfferConfirmationModal
        type="cancel"
        isOpen={isCancelOfferConfirmationModalOpen}
        onOpenChange={onCancelOfferConfirmationModalChange}
        senderAddress={(selectedOffer?.lender || selectedOffer?.borrower) as Address}
      />
    </div>
  )
}
