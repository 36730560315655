import { FC, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { StoreModalType } from 'models/Comm'
import { LoanDetails } from 'models/Loan'
import { RequestStatus } from 'store/helpers'
import { pushModal } from 'store/slices/comm/comm.actions'
import { resetDefaultedStatus, setDefaulted } from 'store/slices/loans/loans.actions'
import { loansStateSelector } from 'store/slices/loans/loans.slice'
import { setLoadingIdle, setLoadingPending } from 'store/slices/profile/profile.actions'
import { profileSelector } from 'store/slices/profile/profile.slice'
import { loanDefaulted } from 'utils/api/blockchain'
import { ADDRESSES } from 'utils/constants'

import { ActivityIndicator, Button, Modal, SuccessModal } from 'components'

import { ClaimNFTItem } from './ClaimNFTItem'

type ClaimNFTsModalProps = {
  isOpen: boolean
  loanDetails: LoanDetails
  onOpenChange: (open: boolean) => void
}

export const ClaimNFTsModal: FC<ClaimNFTsModalProps> = props => {
  const { isOpen, loanDetails, onOpenChange } = props
  const { loadingState } = useAppSelector(profileSelector)
  const { setDefaultedStatus } = useAppSelector(loansStateSelector)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isOpen) {
      dispatch(resetDefaultedStatus())
    }
  }, [isOpen])

  useEffect(() => {
    if (setDefaultedStatus === RequestStatus.REJECTED) {
      onOpenChange(false)
      dispatch(setLoadingIdle())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
    }
  }, [setDefaultedStatus])

  if (!loanDetails) return null

  const handleClaim = async () => {
    dispatch(setLoadingPending())
    if (!loanDetails || !ADDRESSES.ZEXUS_LENDER) return

    const loanDefaultedResult = await loanDefaulted(loanDetails.collateralId)
    const loanDefaultedTransactionReceipt = await loanDefaultedResult.wait()
    if (loanDefaultedTransactionReceipt.status !== 1) {
      dispatch(setLoadingIdle())
      dispatch(
        pushModal({
          type: StoreModalType.GENERAL_ERROR,
        })
      )
      return
    }

    dispatch(setDefaulted({ id: Number(loanDetails.id) }))
  }

  const handleSuccessModalOpenChange = (open: boolean) => {
    dispatch(setLoadingIdle())
    onOpenChange(open)
    if (!open) navigate('/profile/loans')
  }

  const renderBottomBar = () => (
    <>
      <Button onClick={() => onOpenChange(false)} variant="secondary">
        Close
      </Button>
      <Button
        onClick={() => handleClaim().catch(() => dispatch(setLoadingIdle()))}
        variant="primary"
      >
        {loadingState === RequestStatus.PENDING ? <ActivityIndicator whiteColor /> : 'Claim'}
      </Button>
    </>
  )

  if (setDefaultedStatus === RequestStatus.FULFILLED) {
    dispatch(setLoadingIdle())
    const loanName = loanDetails.name ?? `Loan #${loanDetails.id}`
    const successMessage = (
      <div>
        <div className="font-body-3">You have successfully defaulted {loanName}.</div>
        <div className="font-body-5 mt-5">We have transferred NFTs to your wallet.</div>
      </div>
    )
    return (
      <SuccessModal
        isOpen={isOpen}
        onOpenChange={handleSuccessModalOpenChange}
        message={successMessage}
      />
    )
  }

  return (
    <Modal
      title="Claim NFTs"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    >
      <div className="font-body-3 text-third-10">You are about to claim those NFTs.</div>
      {loanDetails.nfts.map(nft => (
        <ClaimNFTItem key={`${nft.address}/${nft.tokenId}`} nft={nft} />
      ))}
    </Modal>
  )
}
