import { FC, useState } from 'react'

import { useClickOutside } from 'hooks/useClickOutside'

import { TextInput } from 'components/form/text-input/TextInput'

import { ReactComponent as PencilSquare } from 'assets/icons/pencilSquare.svg'

interface EditableNameProps {
  onNameChange: (name: string) => void
}

export const EditableName: FC<EditableNameProps> = ({ onNameChange }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [name, setName] = useState('Collateral')
  const { elementRef } = useClickOutside(() => setIsEditMode(false))

  const handleNameChange = (value: string) => {
    setName(value)
    onNameChange(value)
  }

  return (
    <div className="flex w-full items-center justify-between" ref={elementRef}>
      {!isEditMode && <h4 className="my-[6px]">{name}</h4>}
      {isEditMode && (
        <div className="flex flex-col">
          <span className="mb-1.5">Collateral Name</span>
          <TextInput
            className="-mb-[16px]"
            value={name}
            onValueChange={({ value }) => handleNameChange(value || '')}
          />
        </div>
      )}

      <button
        type="button"
        onClick={() => {
          setIsEditMode(true)
        }}
      >
        <PencilSquare />
      </button>
    </div>
  )
}
