import { useEffect, useRef, useState } from 'react'

import { Address } from '@wagmi/core'
import { BigNumber } from 'ethers'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useWallet } from 'hooks/useWallet'
import { StoreModalType } from 'models/Comm'
import { ProfileOffersFilter } from 'models/Filter'
import { CollateralOffer, LoanForBlockchain } from 'models/Loan'
import { UserOffer } from 'models/Offer'
import {
  acceptCollateralOffer,
  cancelCollateralOffer,
  rejectCollateralOffer,
  resetCollateralAcceptRejectOffer,
} from 'store/slices/collaterals/collaterals.actions'
import { pushModal } from 'store/slices/comm/comm.actions'
import {
  acceptLoanExtension,
  acceptLoanOffer,
  cancelLoanExtension,
  cancelLoanOffer,
  confirmLoanTermsOffer,
  rejectLoanExtension,
  rejectLoanOffer,
  resetAcceptLoanOfferDraftId,
} from 'store/slices/loans/loans.actions'
import { loansStateSelector } from 'store/slices/loans/loans.slice'
import {
  getOffers,
  getSingleOffer,
  resetOffers,
  resetSingleOffer,
  setLoadingIdle,
  setLoadingPending,
  setSelectedOffer,
} from 'store/slices/profile/profile.actions'
import { profileSelector } from 'store/slices/profile/profile.slice'
import {
  acceptOfferForCollateral,
  acceptOfferForLoan,
  acceptOfferForLoanExtension,
  erc20Approve,
  getBalanceBN,
  getPrincipalFee,
  getRepaymentAmount,
} from 'utils/api/blockchain'
import { ADDRESSES } from 'utils/constants'
import { convertFromBigNumber, convertToBigNumber } from 'utils/form'

import { ProfileOffersView } from './ProfileOffersView'

export type OffersTypes = 'made' | 'received' | null

export const ProfileOffersScreen = () => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  const [isRejectOfferModalOpen, setIsRejectOfferModalOpen] = useState(false)
  const [isCancelOfferModalOpen, setIsCancelOfferModalOpen] = useState(false)
  const [isAcceptOfferConfirmationModalOpen, setIsAcceptOfferConfirmationModalOpen] =
    useState(false)
  const [isRejectOfferConfirmationModalOpen, setIsRejectOfferConfirmationModalOpen] =
    useState(false)
  const [isCancelOfferConfirmationModalOpen, setIsCancelOfferConfirmationModalOpen] =
    useState(false)
  const filters = useRef<ProfileOffersFilter>()

  const { pathname } = useLocation()
  const { address } = useWallet()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { offers, singleOffer, selectedOffer } = useAppSelector(profileSelector)
  const { loanTermsOfferDraftId } = useAppSelector(loansStateSelector)

  const path = pathname.replace('/profile/', '')
  useEffect(() => {
    if (!selectedOffer) return
    setIsDetailsModalOpen(true)
    dispatch(
      getSingleOffer({
        type: selectedOffer.offerType === 'OFFER' ? 'collateral' : 'loan',
        id: `${selectedOffer.loan}`,
      })
    )
  }, [selectedOffer])

  let offersType: OffersTypes
  switch (path) {
    case 'offers-made':
      offersType = 'made'
      break
    case 'offers-received':
      offersType = 'received'
      break
    default:
      offersType = null
      break
  }

  const handleGetOffers = (type: OffersTypes, walletAddress: string | undefined) => {
    if (!type || !walletAddress) return

    dispatch(
      getOffers({
        type,
        address: walletAddress,
        filters: filters.current,
      })
    )
  }

  const handleFiltersChange = (value: ProfileOffersFilter) => {
    filters.current = value
    dispatch(resetOffers())
    handleGetOffers(offersType, address)
  }

  useEffect(() => {
    filters.current = undefined
    dispatch(resetOffers())
    handleGetOffers(offersType, address)
  }, [offersType, address])

  const resetSelectedOffer = (goToProfile?: boolean) => {
    dispatch(resetSingleOffer())
    dispatch(resetAcceptLoanOfferDraftId())
    dispatch(resetCollateralAcceptRejectOffer())
    if (goToProfile) {
      navigate('/profile/loans')
    } else {
      dispatch(resetOffers())
      handleGetOffers(offersType, address)
    }
  }

  // this function is used only when we close the details modal without accepting / rejecting
  const handleDetailsModalOpenChange = (open: boolean) => {
    if (!open) {
      resetSelectedOffer()
    }
    setIsDetailsModalOpen(open)
  }

  const handleRejectModalOpenChange = (open: boolean) => {
    if (open) {
      setIsDetailsModalOpen(false)
    } else {
      resetSelectedOffer()
    }
    setIsRejectOfferModalOpen(open)
  }

  const handleCancelModalOpenChange = (open: boolean) => {
    if (open) {
      setIsDetailsModalOpen(false)
    } else {
      resetSelectedOffer()
    }
    setIsCancelOfferModalOpen(open)
  }

  const handleOfferReject = (id: number, message: string) => {
    const offerId = selectedOffer?.id
    if (!offerId || !singleOffer) return
    if (selectedOffer.offerType === 'OFFER') {
      dispatch(rejectCollateralOffer({ id: offerId, message }))
      setIsRejectOfferConfirmationModalOpen(true)
    } else if (
      selectedOffer.offerType === 'ACTIVE' &&
      selectedOffer.sendTo === singleOffer.lender
    ) {
      dispatch(rejectLoanExtension({ id: offerId, message }))
      setIsRejectOfferConfirmationModalOpen(true)
    } else {
      dispatch(rejectLoanOffer({ id: offerId, message }))
      setIsRejectOfferConfirmationModalOpen(true)
    }
  }

  const handleOfferCancel = () => {
    const offerId = selectedOffer?.id
    if (!offerId || !singleOffer) return
    if (selectedOffer.offerType === 'OFFER') {
      dispatch(cancelCollateralOffer(offerId))
      setIsCancelOfferConfirmationModalOpen(true)
    } else if (
      selectedOffer.offerType === 'ACTIVE' &&
      selectedOffer.sendTo === singleOffer.lender
    ) {
      dispatch(cancelLoanExtension(offerId))
      setIsCancelOfferConfirmationModalOpen(true)
    } else {
      dispatch(cancelLoanOffer(offerId))
      setIsCancelOfferConfirmationModalOpen(true)
    }

    handleGetOffers(offersType, address)
  }

  const handleAcceptOfferConfirmationModalChange = (open: boolean) => {
    dispatch(setLoadingIdle())
    setIsAcceptOfferConfirmationModalOpen(open)
    if (!open) {
      resetSelectedOffer(true)
    }
  }

  const handleRejectOfferConfirmationModalChange = (open: boolean) => {
    dispatch(setLoadingIdle())
    setIsRejectOfferConfirmationModalOpen(open)
    if (!open) {
      resetSelectedOffer(true)
    }
  }

  const handleCancelOfferConfirmationModalChange = (open: boolean) => {
    dispatch(setLoadingIdle())
    setIsCancelOfferConfirmationModalOpen(open)
    if (!open) {
      resetSelectedOffer(true)
    }
  }

  const hasLenderBalance = async (offer: UserOffer, loanValue: BigNumber) => {
    const lenderBalance = await getBalanceBN(offer.lender, offer.currency)

    if (lenderBalance.lt(loanValue)) {
      dispatch(
        pushModal({
          type: StoreModalType.ERROR_MESSAGE,
          message:
            'Lender has insufficient amount of balance. The transaction cannot be proceeded.',
        })
      )
      setIsDetailsModalOpen(false)
      return false
    }
    return true
  }

  const acceptOfferLoan = async (): Promise<void> => {
    if (!selectedOffer || !address || !singleOffer) return
    const selectedOfferPrincipalBN: BigNumber = BigNumber.from(String(selectedOffer.principal))
    const singleOfferPrincipalBN: BigNumber = BigNumber.from(String(singleOffer.principal))
    const singleOfferRepaymentBN: BigNumber = BigNumber.from(String(singleOffer.repayment))

    const loan: LoanForBlockchain = {
      value: selectedOfferPrincipalBN,
      interestRate: selectedOffer.apr,
      duration: selectedOffer.duration,
      currency: ADDRESSES[selectedOffer.currency] as Address,
      creator: selectedOffer.lender,
      collateralId: singleOffer?.collateralId,
      deadline: BigNumber.from(selectedOffer.deadline),
      nonce: BigNumber.from(selectedOffer.nonce),
    }

    const isLenderBalanceEnough = await hasLenderBalance(selectedOffer, loan.value)
    if (!isLenderBalanceEnough) {
      return
    }

    const fee = await getPrincipalFee(
      1,
      selectedOffer.apr,
      selectedOffer.duration,
      selectedOfferPrincipalBN
    )
    // LOAN EXT DIFFERENT LENDER
    const valueToApprove = singleOfferRepaymentBN.sub(selectedOfferPrincipalBN).add(fee)

    if (selectedOfferPrincipalBN.lte(singleOfferPrincipalBN)) {
      const approveResult = await erc20Approve(
        valueToApprove,
        ADDRESSES[selectedOffer.currency] as Address,
        ADDRESSES.ZEXUS_BORROWER as Address
      )

      const approveTransactionReceipt = await approveResult.wait()
      if (approveTransactionReceipt.status !== 1) {
        dispatch(setLoadingIdle())
        dispatch(
          pushModal({
            type: StoreModalType.GENERAL_ERROR,
          })
        )
        return
      }
    }

    const acceptOfferForLoanResult = await acceptOfferForLoan(
      loan,
      selectedOffer.signature,
      singleOffer.collateralId
    )

    const acceptOfferForLoanReceipt = await acceptOfferForLoanResult.wait()
    if (acceptOfferForLoanReceipt.status === 1) {
      dispatch(
        confirmLoanTermsOffer({
          draftId: loanTermsOfferDraftId as number,
          offerId: selectedOffer.id,
        })
      )
      setIsAcceptOfferConfirmationModalOpen(true)
      setSelectedOffer(undefined)
    }
  }

  const acceptOfferCollateral = async () => {
    if (!selectedOffer || !singleOffer) return

    const selectedOfferPrincipalNumber = convertFromBigNumber(
      selectedOffer.principal,
      selectedOffer.currency
    )

    const loan: LoanForBlockchain = {
      value: convertToBigNumber(
        selectedOfferPrincipalNumber,
        selectedOffer.currency,
        false
      ) as BigNumber,
      interestRate: selectedOffer.apr,
      duration: selectedOffer.duration,
      currency: ADDRESSES[selectedOffer.currency] as Address,
      creator: selectedOffer.lender,
      collateralId: singleOffer?.collateralId,
      deadline: BigNumber.from(selectedOffer.deadline),
      nonce: BigNumber.from(selectedOffer.nonce),
    }

    const isLenderBalanceEnough = await hasLenderBalance(selectedOffer, loan.value)
    if (!isLenderBalanceEnough) {
      return
    }

    const acceptOfferForCollateralResult = await acceptOfferForCollateral(
      loan,
      selectedOffer.signature
    )
    if (acceptOfferForCollateralResult === undefined) return
    const acceptOfferForLoanReceipt = await acceptOfferForCollateralResult.wait()

    if (acceptOfferForLoanReceipt.status === 1) {
      dispatch(acceptCollateralOffer(selectedOffer.id)).then(() => {
        dispatch(resetSingleOffer())
      })
      setIsAcceptOfferConfirmationModalOpen(true)
    }
  }

  const acceptOfferLoanExtension = async () => {
    if (!selectedOffer || !singleOffer) return
    const selectedOfferPrincipalNumber = convertFromBigNumber(
      selectedOffer.principal,
      selectedOffer.currency
    )
    const selectedOfferPrincipalBN = convertToBigNumber(
      selectedOfferPrincipalNumber,
      selectedOffer.currency,
      false
    ) as BigNumber

    const singleOfferPrincipalNumber = convertFromBigNumber(
      singleOffer.principal,
      singleOffer.currency
    )
    const singleOfferPrincipalBN = convertToBigNumber(
      singleOfferPrincipalNumber,
      singleOffer.currency,
      false
    ) as BigNumber
    const loan: CollateralOffer = {
      idCollateral: singleOffer.collateralId,
      value: selectedOfferPrincipalBN,
      interestRate: selectedOffer.apr,
      duration: selectedOffer.duration,
      currency: ADDRESSES[selectedOffer.currency] as Address,
      loaner: address,
      deadline: BigNumber.from(selectedOffer.deadline),
      nonce: BigNumber.from(selectedOffer.nonce),
    }

    const repaymentAmountBN = await getRepaymentAmount(
      singleOffer.apr,
      singleOffer.duration,
      singleOfferPrincipalBN as BigNumber
    )

    // LOAN EXTENSION - GT
    if (selectedOfferPrincipalBN.gt(repaymentAmountBN)) {
      const valueToApprove = selectedOfferPrincipalBN.sub(singleOfferPrincipalBN)
      const approveResult = await erc20Approve(
        valueToApprove,
        ADDRESSES[selectedOffer.currency] as Address,
        ADDRESSES.ZEXUS_LENDER as Address
      )

      const approveTransactionReceipt = await approveResult.wait()
      if (approveTransactionReceipt.status !== 1) {
        dispatch(setLoadingIdle())
        dispatch(
          pushModal({
            type: StoreModalType.GENERAL_ERROR,
          })
        )
      }
    }

    const acceptOfferForLoanResult = await acceptOfferForLoanExtension(
      loan,
      selectedOffer.signature
    )
    const acceptOfferForLoanReceipt = await acceptOfferForLoanResult.wait()
    if (acceptOfferForLoanReceipt.status === 1) {
      dispatch(acceptLoanExtension(selectedOffer.id))
      setIsAcceptOfferConfirmationModalOpen(true)
      setSelectedOffer(undefined)
    }
  }

  useEffect(() => {
    if (!loanTermsOfferDraftId) return
    acceptOfferLoan()
  }, [loanTermsOfferDraftId])

  const handleOfferAccept = async (): Promise<void> => {
    dispatch(setLoadingPending())
    if (!selectedOffer || !singleOffer) return
    if (selectedOffer.offerType === 'OFFER') {
      acceptOfferCollateral().catch(() => dispatch(setLoadingIdle()))
    } else if (selectedOffer.sendTo === singleOffer.lender) {
      acceptOfferLoanExtension().catch(() => dispatch(setLoadingIdle()))
    } else {
      dispatch(acceptLoanOffer(selectedOffer.id)).catch(() => dispatch(setLoadingIdle()))
    }
  }

  return (
    <ProfileOffersView
      isAcceptOfferConfirmationModalOpen={isAcceptOfferConfirmationModalOpen}
      isDetailsModalOpen={isDetailsModalOpen}
      isRejectOfferModalOpen={isRejectOfferModalOpen}
      isRejectOfferConfirmationModalOpen={isRejectOfferConfirmationModalOpen}
      isCancelOfferConfirmationModalOpen={isCancelOfferConfirmationModalOpen}
      isCancelOfferModalOpen={isCancelOfferModalOpen}
      offers={offers}
      offersType={offersType}
      onFiltersChange={handleFiltersChange}
      onItemSelect={item => dispatch(setSelectedOffer(item))}
      onOfferAccept={handleOfferAccept}
      onOfferReject={handleOfferReject}
      onOfferCancel={handleOfferCancel}
      onDetailsModalOpenChange={handleDetailsModalOpenChange}
      onRejectModalOpenChange={handleRejectModalOpenChange}
      onCancelModalOpenChange={handleCancelModalOpenChange}
      selectedOffer={selectedOffer}
      onAcceptOfferConfirmationModalChange={handleAcceptOfferConfirmationModalChange}
      onRejectOfferConfirmationModalChange={handleRejectOfferConfirmationModalChange}
      onCancelOfferConfirmationModalChange={handleCancelOfferConfirmationModalChange}
    />
  )
}
