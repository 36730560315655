import { FC } from 'react'

import { NFT } from 'models/Collateral'
import { Currencies } from 'models/Currency'
import { Status, TileType } from 'models/Style'
import { UserType } from 'models/User'

import { TileFooter } from './components/TileFooter'
import { TileHeader } from './components/TileHeader'
import { TileImages } from './components/TileImages'

interface TileProps {
  alert?: boolean
  apr?: number
  className?: string
  currency?: string
  disabled?: boolean
  id?: number
  isSelected?: boolean
  nfts: NFT[]
  principal?: string
  status?: Status
  subtitle?: string
  title?: string
  type: TileType
  userType?: UserType
  homepage?: number
}

export const Tile: FC<TileProps> = ({
  alert,
  apr,
  className,
  currency,
  disabled,
  id,
  isSelected,
  nfts,
  principal,
  status,
  subtitle,
  title,
  type,
  userType,
  homepage,
}) => {
  let tileClasses = 'flex border border-2 w-[274px] flex-col rounded pt-2 pb-0.5 px-2'
  if (homepage === 2 || homepage === 1)
    tileClasses += 'max-[1199px]:w-[274px] pr-2 pl-2 min-[1200px]:pl-0 w-[260px]'
  if (homepage! > 2)
    tileClasses += 'max-[1199px]:w-[274px] pr-2 pl-2 min-[1200px]:pr-[72px] pl-0 w-[260px]'
  if (type === 'collateral') {
    tileClasses += ' bg-primary-95 border-primary-95'
  } else if (type === 'nft') {
    tileClasses += ' bg-box-background border-third-70'
  } else {
    tileClasses += ' bg-third-95 border-third-80'
  }

  if (disabled) {
    tileClasses += ' opacity-60'
  }

  let collectionName = ''
  if (type === 'collateral') {
    const uniqueCollectionNames = Array.from(
      new Set(nfts.map(item => item.collectionName).filter(item => item !== undefined))
    )
    collectionName = `${uniqueCollectionNames.slice(0, 2).join(', ')}${
      uniqueCollectionNames.length > 2 ? '... (+2 more)' : ''
    }`
  }

  const getTileTitle = (): string => {
    if (type === 'nft') {
      return nfts[0].name || nfts[0].collectionName
    }

    if (type === 'loan') {
      return `Loan #${id}`
    }

    if (title) return title

    if (type === 'collateral') {
      return `Collateral #${id}`
    }

    return ''
  }

  return (
    <div className={`${tileClasses} ${className}`}>
      <TileHeader
        alert={alert}
        disabled={disabled}
        isSelected={isSelected}
        subtitle={subtitle}
        status={status}
        tileType={type}
        title={getTileTitle()}
        collectionName={collectionName}
        userType={userType}
      />
      <div className={`${type === 'loan' ? 'mt-3' : 'mt-2'} mb-4 h-[257px]`}>
        <TileImages nfts={nfts} disabled={disabled} />
      </div>
      <TileFooter
        apr={apr}
        principal={principal}
        currency={type === 'nft' ? Currencies.ALL : currency}
        projectFloor={type === 'nft' ? nfts[0].projectFloor : undefined}
        valuation={type === 'nft' ? nfts[0].valuation : undefined}
      />
    </div>
  )
}
