import { Currencies } from 'models/Currency'

import { ReactComponent as EthIcon } from 'assets/icons/currencies/ethIcon.svg'
import UsdcIcon from 'assets/icons/currencies/usdcIcon.png'
import { ReactComponent as UsdtIcon } from 'assets/icons/currencies/usdtIcon.svg'
import { ReactComponent as WethIcon } from 'assets/icons/currencies/wethIcon.svg'

export const getCurrencyIcon = (currency: string): JSX.Element | null => {
  if (currency === Currencies.WETH) return <WethIcon />
  if (currency === Currencies.USDT) return <UsdtIcon />
  if (currency === Currencies.USDC) return <img src={UsdcIcon} alt={Currencies.USDC} />
  return <EthIcon />
}
