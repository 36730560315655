import { UserOffer } from 'models/Offer'
import { ColumnDefinition } from 'models/Table'

type Headers = {
  made: ColumnDefinition<UserOffer>[]
  received: ColumnDefinition<UserOffer>[]
}

export const offerHeaders: Headers = {
  made: [
    {
      header: 'Name',
      key: 'image',
      type: 'image',
      className: 'w-[72px] pr-4',
    },
    {
      header: '',
      key: 'id',
      type: 'id',
    },
    {
      header: 'Type',
      key: 'offerType',
      type: 'offerType',
    },
    {
      header: 'Principal',
      key: 'principal',
      type: 'WETH',
    },
    {
      header: 'Repayment',
      key: 'repayment',
      type: 'WETH',
    },
    {
      header: 'APR',
      key: 'apr',
      type: 'percent',
    },
    {
      header: 'Duration',
      key: 'duration',
      type: 'days',
    },
    {
      header: 'Expires at',
      key: 'expiryDate',
      type: 'countdown',
    },
    {
      header: 'Sent to',
      key: 'sendTo',
      type: 'address',
    },
    {
      header: '',
      key: 'lender',
      type: 'button',
    },
  ],
  received: [
    {
      header: 'Name',
      key: 'image',
      type: 'image',
      className: 'w-[72px] pr-4',
    },
    {
      header: '',
      key: 'id',
      type: 'id',
    },
    {
      header: 'Type',
      key: 'offerType',
      type: 'offerType',
    },
    {
      header: 'Principal',
      key: 'principal',
      type: 'WETH',
    },
    {
      header: 'Repayment',
      key: 'repayment',
      type: 'WETH',
    },
    {
      header: 'APR',
      key: 'apr',
      type: 'percent',
    },
    {
      header: 'Duration',
      key: 'duration',
      type: 'days',
    },
    {
      header: 'Expires at',
      key: 'expiryDate',
      type: 'countdown',
    },
    {
      header: 'From',
      key: 'lender/borrower',
      type: 'address',
    },
    {
      header: '',
      key: 'lender',
      type: 'button',
    },
  ],
}
