import { FC } from 'react'

import { BorrowerInformation as BorrowerInformationType } from 'models/Collateral'
import { Currencies } from 'models/Currency'
import { PerCurrencyTable, Table as TableType } from 'models/Table'
import { convertFromBigNumber } from 'utils/form'

import { Collapsible, Table } from 'components/index'

interface BorrowerInformationProps {
  borrowerInfo: BorrowerInformationType
}

export const BorrowerInformation: FC<BorrowerInformationProps> = ({ borrowerInfo }) => {
  if (!borrowerInfo) return null

  const boldTableHeadCols = [0]
  const boldTableBodyCols = [0]

  const borrowTable: TableType<PerCurrencyTable> = {
    headers: [
      {
        key: 'name',
        header: 'Borrow',
      },
      {
        key: Currencies.WETH,
        header: 'WETH',
      },
      {
        key: Currencies.USDT,
        header: 'USDT',
      },
      {
        key: Currencies.USDC,
        header: 'USDC',
      },
    ],
    data: [
      {
        name: 'Total borrowed',
        [Currencies.WETH]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.WETH.borrowInfo.borrowedTotal,
              Currencies.WETH,
              false
            ) as number
          ).toFixed(4)
        ),
        [Currencies.USDC]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.USDC.borrowInfo.borrowedTotal,
              Currencies.USDC,
              false
            ) as number
          ).toFixed(4)
        ),
        [Currencies.USDT]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.USDT.borrowInfo.borrowedTotal,
              Currencies.USDT,
              false
            ) as number
          ).toFixed(4)
        ),
      },
      {
        name: 'Borrowed times',
        [Currencies.WETH]: borrowerInfo.WETH.borrowInfo.borrowedTimes,
        [Currencies.USDC]: borrowerInfo.USDC.borrowInfo.borrowedTimes,
        [Currencies.USDT]: borrowerInfo.USDT.borrowInfo.borrowedTimes,
      },
      {
        name: 'Repaid times',
        [Currencies.WETH]: borrowerInfo.WETH.borrowInfo.repaidTimes,
        [Currencies.USDC]: borrowerInfo.USDC.borrowInfo.repaidTimes,
        [Currencies.USDT]: borrowerInfo.USDT.borrowInfo.repaidTimes,
      },
      {
        name: 'Average borrowed',
        [Currencies.WETH]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.WETH.borrowInfo.borrowedAverage,
              Currencies.WETH,
              false
            ) as number
          ).toFixed(4)
        ),
        [Currencies.USDC]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.USDC.borrowInfo.borrowedAverage,
              Currencies.USDC,
              false
            ) as number
          ).toFixed(4)
        ),
        [Currencies.USDT]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.USDT.borrowInfo.borrowedAverage,
              Currencies.USDT,
              false
            ) as number
          ).toFixed(4)
        ),
      },
      {
        name: 'Default rate (%)',
        [Currencies.WETH]: Number(borrowerInfo.WETH.borrowInfo.defaultedRate.toFixed(2)),
        [Currencies.USDC]: Number(borrowerInfo.USDC.borrowInfo.defaultedRate.toFixed(2)),
        [Currencies.USDT]: Number(borrowerInfo.USDT.borrowInfo.defaultedRate.toFixed(2)),
      },
    ],
  }

  const lendTable: TableType<PerCurrencyTable> = {
    headers: [
      {
        key: 'name',
        header: 'Lend',
      },
      {
        key: Currencies.WETH,
        header: 'WETH',
      },
      {
        key: Currencies.USDT,
        header: 'USDT',
      },
      {
        key: Currencies.USDC,
        header: 'USDC',
      },
    ],
    data: [
      {
        name: 'Total lent',
        [Currencies.WETH]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.WETH.lendInfo.lendedTotal,
              Currencies.WETH,
              false
            ) as number
          ).toFixed(4)
        ),
        [Currencies.USDC]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.USDC.lendInfo.lendedTotal,
              Currencies.USDC,
              false
            ) as number
          ).toFixed(4)
        ),
        [Currencies.USDT]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.USDT.lendInfo.lendedTotal,
              Currencies.USDT,
              false
            ) as number
          ).toFixed(4)
        ),
      },
      {
        name: 'Lent times',
        [Currencies.WETH]: borrowerInfo.WETH.lendInfo.lendedTimes,
        [Currencies.USDC]: borrowerInfo.USDC.lendInfo.lendedTimes,
        [Currencies.USDT]: borrowerInfo.USDT.lendInfo.lendedTimes,
      },
      {
        name: 'Average lent',
        [Currencies.WETH]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.WETH.lendInfo.lendedAverage,
              Currencies.WETH,
              false
            ) as number
          ).toFixed(4)
        ),
        [Currencies.USDC]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.USDC.lendInfo.lendedAverage,
              Currencies.USDC,
              false
            ) as number
          ).toFixed(4)
        ),
        [Currencies.USDT]: Number(
          (
            convertFromBigNumber(
              borrowerInfo.USDT.lendInfo.lendedAverage,
              Currencies.USDT,
              false
            ) as number
          ).toFixed(4)
        ),
      },
    ],
  }

  return (
    <div>
      <Collapsible title="Borrower information">
        <Table
          columns={borrowTable.headers}
          data={borrowTable.data}
          headerBoldColsIndexes={boldTableHeadCols}
          bodyBoldColsIndexes={boldTableBodyCols}
        />
        <hr className="my-4 h-px border-primary-30" />
        <Table
          columns={lendTable.headers}
          data={lendTable.data}
          headerBoldColsIndexes={boldTableHeadCols}
          bodyBoldColsIndexes={boldTableBodyCols}
        />
      </Collapsible>
    </div>
  )
}
