import { FC } from 'react'

import { getCurrencyIcon } from 'utils/getCurrencyIcon'
import { roundDigits } from 'utils/number'

interface LoanTermsProps {
  principal: string
  repayment: string
  apr: number
  duration: number
  currency: string
}

const loanTermBoxClasses = 'flex flex-col gap-2 rounded-md bg-third-90 p-3'
const textHeadingClasses = 'font-overline text-third-50'
const textValueClasses = 'font-body-3 font-semibold'

export const LoanTerms: FC<LoanTermsProps> = ({
  principal,
  repayment,
  apr,
  duration,
  currency,
}) => (
  <div>
    <h5>Loan Terms</h5>
    <div className="mt-4 grid grid-cols-2 gap-x-6 gap-y-4 text-third-10">
      <div className={loanTermBoxClasses}>
        <div className={textHeadingClasses}>PRINCIPAL</div>
        <div className="flex items-center" title={principal}>
          {getCurrencyIcon(currency)}
          <div className={`${textValueClasses} ml-1 truncate`}>
            {roundDigits(Number(principal), 4)}
          </div>
        </div>
      </div>
      <div className={loanTermBoxClasses}>
        <div className={textHeadingClasses}>REPAYMENT</div>
        <div className="flex items-center" title={repayment}>
          {getCurrencyIcon(currency)}
          <div className={`${textValueClasses} ml-1 truncate`}>
            {roundDigits(Number(repayment), 4)}
          </div>
        </div>
      </div>
      <div className={loanTermBoxClasses}>
        <div className={textHeadingClasses}>APR</div>
        <div className={textValueClasses}>{apr}%</div>
      </div>
      <div className={loanTermBoxClasses}>
        <div className={textHeadingClasses}>DURATION</div>
        <div className={textValueClasses}>{duration} days</div>
      </div>
    </div>
  </div>
)
