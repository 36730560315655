import {
  ChangeEvent,
  FC,
  FocusEvent,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import * as yup from 'yup'

import { FormInput } from 'models/Form'
import { Position } from 'models/Style'

import { Hint } from '../hint/Hint'
import { Label } from '../label/Label'

type TextInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChangeText' | 'placeholderTextColor' | 'value'
> & {
  format?: (value: string) => string
  hint?: string
  icon?: ReactNode
  iconPosition?: Position
  label?: string
  onValueChange: (input: FormInput) => void
  validationSchema?: yup.BaseSchema
  value: string | number | undefined
}

const getTextInputClasses = (
  isDisabled: boolean,
  isValid: boolean,
  iconPosition: 'left' | 'right'
) => {
  let inputContainerClasses =
    'flex items-center overflow-hidden rounded-lg border focus-within:border-primary-60'
  let iconClasses = 'flex px-3 h-full w-12 items-center justify-center'

  if (isDisabled) {
    inputContainerClasses += ' border-third-80 bg-third-80'
    iconClasses += ' text-third-50'
  } else if (!isValid) {
    inputContainerClasses += ' border-red-500'
  } else {
    inputContainerClasses += ' border-third-70'
  }

  if (iconPosition === 'left') {
    inputContainerClasses += ' flex-row-reverse	'
  }

  return { inputContainerClasses, iconClasses }
}

export const TextInput: FC<TextInputProps> = ({
  value,
  className,
  disabled,
  format,
  hint = ' ',
  icon,
  iconPosition = 'right',
  label,
  onValueChange,
  placeholder,
  type = 'text',
  validationSchema,
  onBlur,
  ...props
}) => {
  const [isValid, setIsValid] = useState<boolean>(true)
  const [hintMessage, setHintMessage] = useState<string>(hint)
  const internalValue = useRef<string>()

  const { inputContainerClasses, iconClasses } = useMemo(
    () => getTextInputClasses(!!disabled, isValid, iconPosition),
    [disabled, isValid]
  )

  const setValue = useCallback(
    (text: string) => {
      const newInternalValue = format ? format(text) : text
      // save new internal value so we could use it later
      internalValue.current = newInternalValue
      return newInternalValue
    },
    [format, validationSchema]
  )

  useEffect(() => {
    const strValue = value?.toString(10)
    if (strValue === undefined) {
      internalValue.current = undefined
      setIsValid(true)
      setHintMessage(hint)
      return
    }

    if (strValue !== internalValue.current) {
      const newInternalValue = setValue(strValue)
      if (validationSchema && internalValue.current !== undefined) {
        validationSchema
          .validate(newInternalValue)
          .then(() => {
            setIsValid(true)
            onValueChange?.({ isValid: true, value: newInternalValue })
          })
          .catch(err => {
            setHintMessage(err.errors[0])
            setIsValid(false)
            onValueChange?.({ isValid: false, value: newInternalValue })
          })
      } else {
        setIsValid(true)
        onValueChange?.({ isValid: true, value: newInternalValue })
      }
    }
  }, [value, setValue, validationSchema, onValueChange])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value
    if (newValue !== internalValue.current) {
      const newInternalValue = setValue(newValue)
      onValueChange({ value: newInternalValue, isValid: true })
    }
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
    if (validationSchema) {
      const newInternalValue = internalValue.current
      validationSchema
        .validate(newInternalValue)
        .then(() => {
          onValueChange({ value: newInternalValue, isValid: true })
          setHintMessage(hint)
          setIsValid(true)
        })
        .catch(err => {
          onValueChange({ value: newInternalValue, isValid: false })
          setHintMessage(err.errors[0])
          setIsValid(false)
        })
    }
    onBlur?.(e)
  }

  return (
    <div className={`flex flex-col ${className}`}>
      {label && <Label text={label} isError={!isValid} classNames="font-body-3" />}
      <div className={inputContainerClasses}>
        <input
          value={internalValue.current ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          className="h-full w-full bg-transparent p-3 text-third-40 outline-0 placeholder:text-neutral-400 disabled:bg-third-80 disabled:placeholder:text-third-50"
          {...props}
        />
        {icon && <div className={iconClasses}>{icon}</div>}
      </div>
      <Hint text={hintMessage ?? hint} isError={!isValid} isDisabled={disabled} />
    </div>
  )
}
