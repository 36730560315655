import { signMessage } from '@wagmi/core'
import axios from 'axios'

import { getStorageItem, removeStorageItem, setStorageItem } from 'hooks/useStorage'

import { API_URLS } from '../constants'

const API = axios.create()
API.defaults.headers.common['Content-Type'] = 'application/json'

API.interceptors.request.use(config => {
  const token = getStorageItem('token')
  if (token) {
    // TODO: check token expiration date and refresh token before making appropriate request
    config.headers = config.headers ?? {}
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

API.interceptors.response.use(
  response => response,
  async error => {
    const request = error.config
    const isLogout = request?.url.indexOf('auth-token') > -1 && request?.url.indexOf('destroy')

    if (error.response?.status === 403 && isLogout) {
      // when login out and token already expired simply continue logout flow
      return error.response.data
    }

    if (error.response?.status === 403 && !request.retry) {
      request.retry = true
      const storageToken = getStorageItem('token')
      // If there is a stored token it is expired, and we should refresh it
      // if there is no token user is either full logged out (and needs to logg in through UI)
      // or we are during the refresh token process
      if (storageToken) {
        removeStorageItem('token')
        const address = getStorageItem('token.address')

        if (address) {
          const {
            data: { message },
          } = await API.get(`${API_URLS.ZEXUS_API}/users/auth-message/${address}/`)

          if (message) {
            const signature = await signMessage({
              message,
            })

            if (signature) {
              const {
                data: { token },
              } = await API.post(`${API_URLS.ZEXUS_API}/users/auth-token/${address}/`, {
                signature,
              })
              if (token) {
                setStorageItem('token', token)
                return await API(request)
              }
            }
          }
        }
      } else {
        return await API(request)
      }
    }
    throw error
  }
)

export default API
