import dayjs from 'dayjs'

import { Loan, LoanDetails } from 'models/Loan'

export const mapLoanResult = (item: Loan): Loan => {
  let { status } = item
  if (status === 'ACTIVE' && dayjs().isAfter(dayjs(item.dueDate))) {
    status = 'OVERDUE'
  }

  return {
    ...item,
    status,
  }
}

export const mapLoanDetailsResult = (item: LoanDetails): LoanDetails => {
  let { status, principal, repayment } = item
  if (status === 'ACTIVE' && dayjs().isAfter(dayjs(item.dueDate))) {
    status = 'OVERDUE'
  }

  principal = String(principal)
  repayment = String(repayment)

  return {
    ...item,
    principal,
    repayment,
    status,
  }
}
