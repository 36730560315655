import { FC, useState } from 'react'

import { Close } from '@radix-ui/react-dialog'

import { getButtonClasses, Modal } from 'components'

interface RejectOfferModalProps {
  isOpen: boolean
  onCancel: () => void
  onOpenChange: (open: boolean) => void
  onReject: (message: string) => void
}

const primaryButtonClasses = getButtonClasses('medium', 'primary', 'default', true, false)
const secondaryButtonClasses = getButtonClasses('medium', 'secondary', 'default', true, false)

export const RejectOfferModal: FC<RejectOfferModalProps> = ({
  isOpen,
  onCancel,
  onOpenChange,
  onReject,
}) => {
  const [comment, setComment] = useState('')

  const renderBottomBar = () => (
    <>
      <Close onClick={onCancel} className={secondaryButtonClasses}>
        No, cancel
      </Close>
      <Close onClick={() => onReject(comment)} className={primaryButtonClasses}>
        Reject offer
      </Close>
    </>
  )

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title="Reject offer"
      renderBottomBar={renderBottomBar}
    >
      <div className="font-body-3">
        Are you sure you want to reject offer? You can add comment to your decision.
      </div>
      <div className="mt-8 flex flex-col gap-1">
        <div>
          Comment <span className="font-body-4 text-third-50">(optional)</span>
        </div>
        <textarea
          className="rounded border border-third-70 bg-transparent px-4 py-3"
          onChange={e => setComment(e.target.value)}
          placeholder="e.g. “Repayment is too high for me”"
          rows={5}
          value={comment}
          maxLength={120}
        />
        <div className="font-body-4 ml-auto text-third-40">{comment.length}/120</div>
      </div>
    </Modal>
  )
}
