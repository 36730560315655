import { createAction } from '@reduxjs/toolkit'

import { StoreAlert, StoreModal, StoreToast } from 'models/Comm'

export const pushModal = createAction<StoreModal>('notification/pushModal')
export const shiftModal = createAction('notification/shiftModal')
export const pushToast = createAction<StoreToast>('notification/pushToast')
export const shiftToast = createAction('notification/shiftToast')
export const pushAlert = createAction<StoreAlert>('notification/pushAlert')
export const shiftAlert = createAction('notification/shiftAlert')
