import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useWallet } from 'hooks/useWallet'
import { getProfileLoans } from 'store/slices/profile/profile.actions'
import { profileSelector } from 'store/slices/profile/profile.slice'

import { ProfileLoansFilter } from '../../models/Filter'
import { ProfileLoansView } from './ProfileLoansView'

export const ProfileLoansScreen = () => {
  const { loans } = useAppSelector(profileSelector)
  const dispatch = useAppDispatch()

  const { address } = useWallet()

  const handleFilterChange = (filter: ProfileLoansFilter) => {
    dispatch(
      getProfileLoans({
        address,
        filter,
      })
    )
  }

  return (
    <ProfileLoansView loans={loans} onFilterChange={handleFilterChange} userAddress={address} />
  )
}
