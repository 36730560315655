import { FC, useEffect, useState } from 'react'

import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'

import { SortItem, SortOptions } from 'models/Sort'

import { Dropdown } from 'components/form/dropdown'

import { ReactComponent as Check } from 'assets/icons/check.svg'

interface SortProps {
  items: Array<SortItem>
  onChange: (value: SortItem) => void
  defaultValue: SortItem
  value?: SortItem
}

export const Sort: FC<SortProps> = ({ items, onChange, defaultValue, value }) => {
  const [sortMethod, setSortMethod] = useState(value || defaultValue)

  useEffect(() => {
    setSortMethod(value || defaultValue)
  }, [value])

  const onSelect = (method: SortItem) => {
    onChange(method)
    setSortMethod(method)
  }

  return (
    <Dropdown title={SortOptions[sortMethod]} label="Sort by">
      <div className="flex flex-col">
        {items.map(item => (
          <DropdownMenuItem key={item} asChild>
            <button className="w-full" type="button" onClick={() => onSelect(item)}>
              <div className="group flex w-full cursor-pointer items-center justify-between">
                <div className="font-body-3 py-2.5 pr-5">{SortOptions[item as SortItem]}</div>
                <Check className={`${sortMethod !== item && 'invisible'} group-hover:visible`} />
              </div>
            </button>
          </DropdownMenuItem>
        ))}
      </div>
    </Dropdown>
  )
}
