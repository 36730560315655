import { FC } from 'react'

import * as Dialog from '@radix-ui/react-dialog'

import { getButtonClasses, Modal } from 'components'

const primaryButtonClasses = getButtonClasses('small', 'primary', 'default', true, false)
const secondaryButtonClasses = getButtonClasses('small', 'secondary', 'default', true, false)

type DeleteCollateralModalProps = {
  isOpen: boolean
  onConfirm: () => void
  onOpenChange: (open: boolean) => void
}

export const DeleteCollateralModal: FC<DeleteCollateralModalProps> = props => {
  const { isOpen, onConfirm, onOpenChange } = props
  const renderBottomBar = () => (
    <>
      <Dialog.Close className={secondaryButtonClasses}>No, cancel</Dialog.Close>
      <Dialog.Close onClick={onConfirm} className={primaryButtonClasses}>
        Pay gas fee
      </Dialog.Close>
    </>
  )

  return (
    <Modal
      title="Delete collateral"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      renderBottomBar={renderBottomBar}
    >
      <div className="max-w-md">
        Are you sure you want to delete the collateral? You will need to pay gas fee to proceed with
        this operation.
      </div>
    </Modal>
  )
}
